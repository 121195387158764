import React from 'react'
import "./scss/Verificationmodal.css"
import { Typography,Grid } from "@mui/material";
import Button from "@mui/material/Button";
export default function VerificationModel(props) {
    
    const submit=()=>{
        window.location.href=props.page;
    }
  return (
    
    <Grid container className="modal">
      <Grid item  xs={10} lg={5}  >
      <div className='verification-modal'>
      <Typography varient="h1" component="h1" sx={{color:props.color,fontWeight:700}}>{props.header}</Typography>
      <h4>{props.sub}</h4>

      <Button variant="contained"   className="btn-primary" sx={{display:props.type==""?"none":"block",mt:3}} onClick={submit}>{props.type}</Button>
      </div>
      </Grid>
    </Grid>
  )
}
