import React from "react";
import Grid from "@mui/material/Grid";
import { IconButton, Typography } from "@mui/material";
import "./scss/Footer.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PinterestIcon from "@mui/icons-material/Pinterest";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

export default function Footer(props) {
  const { currentPage } = props;
  return (
    <Grid
      container
      display="flex"
      flexDirection="column"
      bgcolor="#0A172A"
      height={{ xs: "100vh", md: "auto", lg: "auto" }}
      padding={{ xs: "5vh 5vw 0", lg: "15vh 10vw 0" }}
    >
      <Grid
        item
        container
        display="flex"
        justifyContent="space-between"
        marginBottom={{ xs: "5vh", md: "5vh", lg: "15vh" }}
      >
        <Grid item xs={12} md={5} lg={3}>
          <Box
            sx={{ padding: { xs: "0", md: "0 30px 0 0", lg: "0 20px 0 0" } }}
          >
            <Link to="/">
              <img src="/assets/images/logo.svg" width="100%" />
            </Link>
          </Box>
        </Grid>

        <Grid
          item
          container
          xs={12}
          md={4}
          lg={4}
          marginTop={{ xs: "5vh", md: "0", lg: "0" }}
        >
          <Grid item xs={6}>
            <Typography color="#ffffff" fontSize="12px" fontWeight="bold">
              THE COMPANY
            </Typography>
            <Link to="/about-us">
              <Typography color="#E4E4E4" fontSize="14px" marginTop="15px">
                About us
              </Typography>
            </Link>
            {/* <Link to="/contact-us">
              <Typography color="#E4E4E4" fontSize="14px" marginTop="15px">
                Contact us
              </Typography>
            </Link> */}
            {
              currentPage == "landing-page" ||
              currentPage == "privacy" ||
              currentPage == "cookies" ||
              currentPage == "contact-us" ||
              currentPage == "about-us"
                ? ""
                : ""
              // <Typography color="#E4E4E4" fontSize="14px" marginTop="15px">
              //   Site map
              // </Typography>
            }
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography color="#ffffff" fontSize="12px" fontWeight="bold">
              EXPLORE
            </Typography>
            <Link to="/explore">
              <Typography
                color="#E4E4E4"
                fontSize="14px"
                marginTop="15px"
                display="flex"
                alignItems="center"
              >
                Visit our blog <ChevronRightIcon fontSize="small" />
              </Typography>
            </Link>
          </Grid>
        </Grid>

        <Grid
          item
          display="flex"
          justifyContent={{ xs: "start", md: "end" }}
          xs={12}
          md={3}
          marginTop={{ xs: "10vh", md: "0" }}
        >
          <Box>
            <Typography color="#ffffff" fontSize="12px" fontWeight="bold">
              FOLLOW US
            </Typography>
            <Grid item display="flex" marginTop="15px" gap={{xs:3, md:3, lg:4}}>
              <IconButton
                href="https://www.instagram.com/catchuthere/"
                sx={{ padding: "0" }}
              >
                <InstagramIcon sx={{ color: "#ffffff" }} />
              </IconButton>
              <IconButton
                href="https://www.facebook.com/people/catchuthere/100082875535223/"
                sx={{ padding: "0" }}
              >
                <FacebookRoundedIcon sx={{ color: "#ffffff" }} />
              </IconButton>
              <IconButton
                href="https://www.linkedin.com/company/catch-u-there"
                sx={{ padding: "0" }}
              >
                <LinkedInIcon sx={{ color: "#ffffff" }} />
              </IconButton>
              <IconButton
                href="https://www.pinterest.com.au/catchuthere/"
                sx={{ padding: "0" }}
              >
                <PinterestIcon sx={{ color: "#ffffff" }} />
              </IconButton>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid item container borderTop="1px solid white">
        <Grid
          xs={12}
          md={9}
          item
          padding="20px 0"
          display="flex"
          flexDirection={{ xs: "column", md: "row", lg: "row" }}
          gap={{ xs: 2, md: 5, lg: 5 }}
          alignItems="center"
        >
          <Typography color="#E4E4E4" fontSize="14px">
            &copy; {new Date().getFullYear()} Catch U There Pty Ltd.
          </Typography>
          <Link to="/privacy">
            <Typography color="#E4E4E4" fontSize="14px">
              Privacy Policy
            </Typography>
          </Link>
          <Link to="/cookie">
            <Typography color="#E4E4E4" fontSize="14px">
              Cookie Policy
            </Typography>
          </Link>
          {
            currentPage == "landing-page" ||
            currentPage == "privacy" ||
            currentPage == "cookies" ||
            currentPage == "contact-us" ||
            currentPage == "about-us"
              ? ""
              : ""
            // <Typography color="#E4E4E4" fontSize="14px">
            //   Terms & Conditions
            // </Typography>
          }
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          display="flex"
          alignItems="center"
          justifyContent="right"
          flexDirection={{ xs: "column", md: "row", lg: "row" }}
        >
          <Typography color="#E4E4E4" fontSize="14px" display="flex">
            <MailOutlineOutlinedIcon fontSize="small" sx={{ color: "white" }} />{" "}
            &nbsp; hello@catchuthere.com
          </Typography>
        </Grid>
      </Grid>

      {/*<Grid container className="footer" sx={{marginTop:10, bgcolor:'#0A172A', display:{xs:"none",sm:"none",lg:"flex"}}}>
  <Grid item lg={1}></Grid>
  <Grid item lg={10}>
    <Grid container >
      <Grid item lg={4}>
        <Typography className="footer-heading1">Need Help?</Typography>
        <Typography className="footer-heading2" sx={{margin:"4.25% 0"}}>Contact Us</Typography>
        <Grid container>
          <Grid item lg={5} sx={{borderRight:"1px solid black"}}>
          <Typography className="primary-text">Customer Service</Typography>
          </Grid>
          <Grid item lg={6} sx={{ml:1}}><Typography className="primary-text">Help Center</Typography></Grid>
        </Grid>
      </Grid>

      <Grid item lg={3}>
      <Typography className="footer-heading1">Subscribe</Typography>
      <Typography className="footer-heading2 heading-small" sx={{fontSize:24}}>Wanna receive email from us?</Typography>
      <Typography><span className="primary-text"> Sign up</span> to get the latest deals</Typography>
      </Grid>
      <Grid item lg={1}></Grid>
      <Grid item lg={3}>
      <Typography className="footer-heading1">Connect with us</Typography>
      <Grid container>
      <Grid item lg={2}>
        <InstagramIcon/>
          <img src="assets/images/icons/twitter.png" alt="" className='footer-icon'/>
          </Grid>
          <Grid item lg={2}>
            <FacebookRoundedIcon/>
          <img src="assets/images/icons/facebook.png" alt="" className='footer-icon'/>
          </Grid>
          <Grid item lg={2}>
            <LinkedInIcon/>
          <img src="assets/images/icons/instagram.png" alt="" className='footer-icon'/>
          </Grid>
          <Grid item lg={2}>
            <PinterestIcon/>
          <img src="assets/images/icons/youtube.png" alt="" className='footer-icon'/>
          </Grid>
          <Grid item lg={2}>
          <img src="assets/images/icons/linkedin.png" alt="" className='footer-icon'/>
          </Grid>
          <Grid item lg={2}>
          <img src="assets/images/icons/pintrest.png" alt="" className='footer-icon'/>
          </Grid>
         

      </Grid>
      </Grid>
    </Grid>
  </Grid>
  <Grid item lg={1}></Grid>

  <Grid container sx={{marginTop:10}}>
    <Grid item lg={1}></Grid>
    <Grid item lg={10}>
      <Grid container>
          <Grid item lg={3}>
<Typography className="primary-text sub-heading">Discover</Typography>
<Link to="" ><Typography  >Catch ya Blog</Typography></Link>
<Link to="" ><Typography  >Cities/regions</Typography></Link>
<Link to="" ><Typography  >Countries</Typography></Link>
<Link to="" ><Typography  >Popular Itineraries</Typography></Link>
<Link to="" ><Typography  >Travel guides</Typography></Link>
<Link to="" ><Typography  >Catch ya Blog</Typography></Link>



          </Grid>

          <Grid item lg={3}>
          <Typography className="primary-text sub-heading">More</Typography>
          <Link to="" ><Typography  >Trip planner</Typography></Link>
          <Link to="" ><Typography  >Flights</Typography></Link>
          <Link to="" ><Typography  >Accommodation</Typography></Link>
          <Link to="" ><Typography  >Car Hire</Typography></Link>
          <Link to="" ><Typography  >Airlines</Typography></Link>
          <Link to="" ><Typography  >Airports</Typography></Link>
          <Link to="" ><Typography  >Travel insurance</Typography></Link>
          <Link to="" ><Typography  >Coronavirus travel advice</Typography></Link>

          </Grid>
          <Grid item lg={3}>
          <Typography className="primary-text sub-heading">Company</Typography>
          <Link to="" ><Typography>About us</Typography></Link>
          <Link to="" ><Typography>Company details</Typography></Link>
          <Link to="" ><Typography>Why Catch U There</Typography></Link>
          <Link to="" ><Typography>News and Media</Typography></Link>
          <Link to="" ><Typography>Cookie policy</Typography></Link>
          <Link to="" ><Typography>Privacy Policy</Typography></Link>
          <Link to="" ><Typography>Terms and conditions</Typography></Link>

          </Grid>
          <Grid item lg={3}>
          <Typography className="primary-text sub-heading">Help</Typography>

          <Link to="" ><Typography>Help/FAQ</Typography></Link>
          <Link to="" ><Typography>Privacy setings</Typography></Link>
          <Link to="" ><Typography>Security</Typography></Link>
          </Grid>

      </Grid>

    </Grid>
    <Grid item lg={1}></Grid>

  </Grid>

  <Grid container className="copy-right" sx={{marginTop:10}}>
    <Grid item lg={1}></Grid>
    <Grid item lg={10}>
      <Grid container>
        <Grid item lg={8.5}>
        <Typography className="">© 2022 Catch U There Pty Ltd All Rights Reserved. Use of the Catch U There websites and our
Company Information constitutes acceptance of our <u className='primary-text'>Terms and Conditions</u> and <u className='primary-text'>Privacy Policy</u></Typography>
        </Grid>
        <Grid item lg={3.5}>
          <img src="./assets/images/logofiled.png" alt="" />
        </Grid>
      </Grid>
    </Grid>
    <Grid item lg={1}></Grid>
  </Grid>
  </Grid>*/}
    </Grid>
  );
}
