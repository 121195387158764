import React, { useState } from 'react'
import Navbar from '../../components/ui-components/Navbar'
import { Grid, Typography } from '@mui/material'
import BookingModel from '../../components/ui-components/BookingModel'
import ContentSection from '../../components/ui-components/ContentSection'
import Footer from '../../components/ui-components/Footer'

export default function Flights() {
  const [departureCity, setDepartureCity] = useState();
  const [arrivalCity, setArrivalCity] = useState();

  const handleDepartureCityName = (event) => {
    setDepartureCity(event);
  };
  const handleArrivalCityName = (event) => {
    setArrivalCity(event);
  };
  return (
    <>
      <Grid
        container
        height={{ xs: "100vh", md: "70vh", lg: "100vh" }}
        sx={{
          backgroundImage: `url(assets/images/flight-background.png)`,
          backgroundSize: { xs: "cover" },
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid item xs={12}>
          <Navbar />
        </Grid>

        <Grid
          item
          container
          padding={{ xs: "0 10vw", md: "0 10vw", lg: "0 5vw" }}
          display="flex"
          justifyContent="center"
          alignContent="flex-start"
        >

          <Grid item lg={6} md={12} xs={12}>
            <Typography
              color="white"
              fontWeight="700"
              fontSize={{ xs: "28px", md: "32px", lg: "50px" }}
              lineHeight="140%"
              width={{ xs: "auto", md: 'auto', lg: '40vw' }}
            >
              The Ultimate Trip Planning Solution
            </Typography>
            <Typography
              paddingTop={{ xs: "2vh", lg: "1vh" }}
              paddingBottom={{ xs: '2vh', lg: '0' }}
              marginBottom={{ xs: "2vh" }}
              color="#fff"
              fontWeight="600"
              fontSize={{ xs: "16px", md: "16px", lg: "18px" }}
              fontFamily="Inter"
              width={{ xs: "auto", md: "auto", lg: "30vw" }}
              textAlign="justify"
            >
              "Welcome to Catch U There - your passport to hassle-free travel
              planning. Ditch the chaos of multiple sources and complicated
              planning. We simplify the journey, offering you quick and
              cost-effective solutions. Unleash the simplicity, and explore the
              world effortlessly with us."
            </Typography>
          </Grid>
          <Grid item lg={6} md={12} xs={12}>
            <BookingModel 
            getDepartureCityName={handleDepartureCityName}
            getArrivalCityName={handleArrivalCityName}
            currentPage="flights" />
            {/* <Booking/> */}
          </Grid>
        </Grid>
      </Grid>
      <ContentSection />
      <Footer />
    </>
  )
}
