import React, { createContext, useContext, useEffect, useState } from "react";
import Navbar from "../../components/ui-components/Navbar";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Rating,
  Select,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import HotelsComponent from "./HotelsComponent";
import BookingModel from "../../components/ui-components/BookingModel";

import HotelIcon from "@mui/icons-material/Hotel";
import ErrorIcon from "@mui/icons-material/Error";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Resturantcard from "../../components/ui-components/Resturantcard";
import StarIcon from "@mui/icons-material/Star";
import { iitineraryContext } from "../IitineraryContext";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Drawer from "@mui/material/Drawer";
import LanguageIcon from "@mui/icons-material/Language";
import CloseIcon from "@mui/icons-material/Close";
import LocalParkingRoundedIcon from "@mui/icons-material/LocalParkingRounded";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import WifiIcon from "@mui/icons-material/Wifi";
import HotTubIcon from "@mui/icons-material/HotTub";
import LocalBarIcon from "@mui/icons-material/LocalBar";
import EuroIcon from "@mui/icons-material/Euro";
import CallMadeIcon from "@mui/icons-material/CallMade";
import http from "../../utils/http";
import Carousel from "react-material-ui-carousel";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import HotelFilters from "./HotelFilters";
import { useDataContext } from "../../vendors/Contexts";
import { format } from "date-fns";

export const CityContext = createContext();
// const useStyles = makeStyles((theme) => ({
//   drawerContent: {
//     height: '100vh',
//     overflowY: 'auto',
//   },
// }));
export default function HotelsList() {
  // side drawer code
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState("");
  const [displayCount, setDisplayCount] = useState(5);
  const [arrivalCity, setArrivalCity] = useState();
  const { updatedCityName1, updatedCityName2, date, cityCode } =
    useDataContext();
  const toggleDrawer = (hotelCard) => {
    setIsDrawerOpen(!isDrawerOpen);
    setDrawerData(hotelCard);
  };
  // handle City code
  const handleCityName = (value) => {
    setCityName(value);
  };
  const handleArrivalCityName = (event) => {
    setArrivalCity(event);
  };

  let [cityName, setCityName] = useState("");
  let handleCitySearch = (value) => {
    setCityName(value);
  };
  const [price, setPrice] = React.useState([0, 1000]);
  const handlePriceChange = (event) => {
    setPrice(event);
  };

  const [guestRating, setRating] = React.useState([1, 100]);

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
  };

  // handle sort
  const [originalHotelsData, setOriginalHotelsData] = useState([]);
  const [sortValue, setSortValue] = useState("best");

  const handleSortChange = (event) => {
    const newSortValue = event.target.value;
    setSortValue(newSortValue);

    if (newSortValue === "price") {
      const sortedData = [...hotelsData].sort((a, b) => a.price - b.price);
      setHotelsData(sortedData);
    }
    if (newSortValue === "top-reviewed") {
      const sortedData = [...hotelsData].sort(
        (a, b) => b.numberOfReviews - a.numberOfReviews
      );
      setHotelsData(sortedData);
    } else if (newSortValue === "best") {
      setHotelsData(originalHotelsData);
    }
  };

  // Applying filters

  // const base_url = process.env.REACT_APP_BASE_URL
  // const [boj, setObj] = useState({})
  // const [sort, setSort] = useState({sort:"rating", order:"desc"})
  // const [filterGenre, setFilterGenre] = useState(1)
  // const [search, setSearch] = useState("")

  const [hotelsData, setHotelsData] = useState([]);
  useEffect(() => {
    // if(hotelsData.length<1){
    http
      .get(
        `/VioSearchHotel?checkIn=${format(
          date[0].startDate,
          "yyyy-MM-dd"
        )}&checkOut=2024-06-02&query.place.name=London&rooms=1&language=en&currency=EUR`
      )
      .then((res) => {
        console.log(res, "data of hotels");
        setHotelsData(res.data.data.data);
        setOriginalHotelsData(res.data.data.data);
      });
    //}
  }, [cityName, updatedCityName1, updatedCityName2]);

  //////////////////////////////////////////////////////// trying my own filters//////////////////////////////////////////////////

  let [subFilters, setSubFilters] = useState([]);

  let addFilter = (e) => {
    let filterType = e.target.name;
    let filterValue = e.target.value;
    let prevSubFilters = subFilters;
    if (subFilters.includes(filterValue)) {
      let filterData = subFilters.filter((number) => number !== filterValue);
      setSubFilters(filterData);
    } else {
      setSubFilters([...prevSubFilters, filterValue]);
    }
  };
  let applySelectedFilter = () => {
    let filterUrl;
    if (subFilters.length == 0) {
      filterUrl = "";
    } else {
      filterUrl = `&HotelRating=${subFilters.join("|")}`;
    }

    http
      .get(
        `/VioSearchHotel?CityName=${updatedCityName2}&CheckInDate=${date.startDate}&CheckOutDate=${date.endDate}&NumberOfAdults=2&NumberOfRooms=2${filterUrl}&minPrice=${price[0]}&maxPrice=${price[1]}`
      )
      .then((res) => {
        res.data.data.data.map((item) => {
          return <HotelsComponent item={item} />;
        });
        setHotelsData(res.data.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const loadMore = () => {
    setDisplayCount(displayCount + 10); // Increase the count by 10 when "Load More" is clicked
  };
  const cards = hotelsData.slice(0, displayCount).map((item, index) => {
    return (
      <HotelsComponent
        toggleDrawer={toggleDrawer}
        hotelsData={hotelsData}
        displayCount={displayCount}
        loadMore={loadMore}
        item={item}
      />
    );
  });

  useEffect(() => {
    applySelectedFilter();
  }, [subFilters, price]);
  // Drawer Read more
  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <Typography>
        {isReadMore ? text.slice(0, 150) : text}
        <span onClick={toggleReadMore}>
          {isReadMore ? "...read more" : " show less"}
        </span>
      </Typography>
    );
  };

  //  const clickedCheckboxValue = (e) => {
  //   let makingQueryParam;
  //   if (e.target.checked) {
  //     if(fil.length >= 1){

  //       makingQueryParam = `|${e.target.value}`;
  //       setFil([...fil, makingQueryParam]);
  //       console.log(...fil, "fil is here")
  //     }
  //     else{
  //       makingQueryParam = `${e.target.name}=${e.target.value}`;

  //       setFil([...fil, makingQueryParam]);
  //       console.log(...fil, "fil is here")
  //     }

  //   }
  //   else{
  //       makingQueryParam = `${e.target.name}=${e.target.value}`;
  //     const filterUnchecked=fil.filter((item)=>{
  //      return item!=makingQueryParam

  //     })
  //     setFil([...filterUnchecked]);
  //   }

  // };

  // const applySelectedFilter = () => {
  //   let nnn = "";
  //   let i=0;
  //   fil?.map((item) => {
  //     i++;
  //     if(i==1){
  //       nnn = `${nnn}${item}`;
  //     }
  //     else{
  //       nnn = `${nnn}&${item}`;
  //     }

  //   });
  //   console.log(`/SearchHotel/search?cityName=Matlacha&${nnn}`,"here is url");
  //   console.log(nnn,"what is nnn here")

  // http.get(`/SearchHotel?cityName=Matlacha&${nnn}`).then((res) => {
  //   // res.data.data.data.map((item) => {
  //   //   return <HotelsComponent item={item} />;
  //   // });
  //   setHotelsData(res.data.data.data);
  // });
  // };

  // const classes = useStyles();
  return (
    <>
      <Grid
        xs={12}
        sx={{
          backgroundImage: `url(assets/images/litineraryback.png)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Navbar />
      </Grid>
      <Grid
        container
        padding={{ xs: "0", md: "0 7vw", lg: "0" }}
        sx={{
          backgroundColor: "#F3F6FC",
        }}
      >
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={toggleDrawer}
        >
          {/* <div className={classes.drawerContent}> */}
          <Box
            sx={{
              padding: "1vh 1vw",
              maxWidth: { lg: "440px", md: "50vw", xs: "98vw" },
              bgcolor: "#F6F9FD",
            }}
          >
            <Card
              sx={{
                marginTop: "0px !important",
                bgcolor: "#E44E4E",
                borderRadius: "10px",
                padding: "20px 0 0 0",
              }}
            >
              <Box bgcolor="white" padding="20px 15px">
                <Grid container>
                  <Grid item xs={8}>
                    <Typography
                      fontSize="18px"
                      fontWeight="700"
                      fontFamily="Inter"
                      pb="10px"
                    >
                      {drawerData?.requestedName}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    display="flex"
                    justifyContent="end"
                    height="35px"
                  >
                    <IconButton onClick={toggleDrawer}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      fontSize="14px"
                      fontWeight="600"
                      fontFamily="Inter"
                      lineHeight="24px"
                      pb="10px"
                    >
                      {drawerData?.address}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} display="flex" justifyContent="center">
                    <Carousel
                      width="200px"
                      showThumbs={false}
                      sx={{ width: "400px" }}
                    >
                      <div>
                        <img
                          src={
                            drawerData?.images
                              ? drawerData?.images[0]
                              : "./assets/images/no-hotel-found-mobile.png"
                          }
                          width="100%"
                          height="150px"
                        />
                      </div>
                    </Carousel>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    display="flex"
                    alignItems="flex-start"
                    padding="1vh 0"
                  >
                    <a href={drawerData?.url} target="blank">
                      <Typography
                        fontSize="18px"
                        fontWeight="700"
                        display="flex"
                        alignItems="center"
                        gap={1}
                        color="#000"
                      >
                        <img
                          src="/assets/images/Tripadvisor.png"
                          height="20px"
                        />
                        Tripadvisor
                      </Typography>
                    </a>
                  </Grid>
                  <Grid item xs={4} display="flex" padding="1vh 0">
                    <Box display="flex" flexDirection="column">
                      <Typography
                        textAlign="right"
                        fontSize="23px"
                        fontWeight="700"
                      >
                        <AttachMoneyIcon fontSize="small" />
                        {Math.floor(
                          drawerData?.anchorBase +
                            drawerData?.anchorTaxes +
                            drawerData?.anchorhotelFees
                        )}
                      </Typography>
                      <Typography
                        textAlign="right"
                        fontSize="12px"
                        fontWeight="400px"
                        color="#808080"
                      >
                        Nightly rate per room
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <ChevronRightIcon />
                    </Box>
                  </Grid>
                  <Grid item xs={12} bgcolor="#EBF8FF">
                    <Tooltip
                      title="Check the cancellation policy details on our partner's site before booking. Policies and potential fees may vary."
                      arrow
                    >
                      <Typography
                        fontSize="16px"
                        fontFamily="Inter"
                        fontWeight="700"
                        padding="16px 14px"
                        display="flex"
                        alignItems="center"
                      >
                        Free cancellation may be available &nbsp;
                        <ErrorIcon sx={{ color: "#6373CF" }} />
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    display="flex"
                    alignItems="center"
                    padding="1vh 0"
                  >
                    <Grid item xs={2.5}>
                      <Typography fontSize="44px" fontWeight="700">
                        {drawerData?.starRating}.0
                      </Typography>
                    </Grid>
                    <Grid item xs={9.5}>
                      <Typography
                        fontSize="14px"
                        fontWeight="700"
                        paddingLeft="5px"
                      >
                        {drawerData?.starRating >= 4
                          ? "Excellent"
                          : drawerData?.starRating >= 2 &&
                            drawerData?.starRating <= 3
                          ? "Good"
                          : "Poor"}
                      </Typography>

                      <Rating
                        value={Number(drawerData?.starRating)}
                        icon={
                          <FiberManualRecordIcon sx={{ color: "#00AA6C" }} />
                        }
                        emptyIcon={
                          <FiberManualRecordOutlinedIcon
                            sx={{ color: "#00AA6C" }}
                            fontSize="inherit"
                          />
                        }
                        readOnly
                      />
                    </Grid>
                  </Grid>
                  {/* <Grid item>
                    <Typography fontSize="12px" padding="1vh 0">
                      #9 of 501 hotels in Madrid
                    </Typography>
                    <Typography
                      fontSize="12px"
                      display="flex"
                      alignItems="center"
                      gap={2}
                    >
                      <Rating
                        value={Number(drawerData?.rating)}
                        icon={
                          <FiberManualRecordIcon sx={{ color: "#00AA6C" }} />
                        }
                        emptyIcon={
                          <FiberManualRecordOutlinedIcon
                            sx={{ color: "#00AA6C" }}
                            fontSize="inherit"
                          />
                        }
                        readOnly
                      />
                      Location
                    </Typography>
                    <Typography
                      fontSize="12px"
                      display="flex"
                      alignItems="center"
                      gap={2}
                    >
                      <Rating
                        value={Number(drawerData?.rating)}
                        icon={
                          <FiberManualRecordIcon sx={{ color: "#00AA6C" }} />
                        }
                        emptyIcon={
                          <FiberManualRecordOutlinedIcon
                            sx={{ color: "#00AA6C" }}
                            fontSize="inherit"
                          />
                        }
                        readOnly
                      />
                      Cleanliness
                    </Typography>
                    <Typography
                      fontSize="12px"
                      display="flex"
                      alignItems="center"
                      gap={2}
                    >
                      <Rating
                        value={Number(drawerData?.rating)}
                        icon={
                          <FiberManualRecordIcon sx={{ color: "#00AA6C" }} />
                        }
                        emptyIcon={
                          <FiberManualRecordOutlinedIcon
                            sx={{ color: "#00AA6C" }}
                            fontSize="inherit"
                          />
                        }
                        readOnly
                      />
                      Service
                    </Typography>
                    <Typography
                      fontSize="12px"
                      display="flex"
                      alignItems="center"
                      gap={2}
                    >
                      <Rating
                        value={Number(drawerData?.rating)}
                        icon={
                          <FiberManualRecordIcon sx={{ color: "#00AA6C" }} />
                        }
                        emptyIcon={
                          <FiberManualRecordOutlinedIcon
                            sx={{ color: "#00AA6C" }}
                            fontSize="inherit"
                          />
                        }
                        readOnly
                      />
                      Value
                    </Typography> 
                  </Grid>*/}
                  <Grid item xs={12} padding="1vh 0">
                    <Typography fontSize="14px" fontWeight="700">
                      Property amenities
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} padding="1vh 0">
                    <Grid
                      item
                      xs={6}
                      display="flex"
                      alignItems="flex-start"
                      gap={1}
                      padding="1vh 0"
                    >
                      <LocalParkingRoundedIcon />
                      <Typography fontSize="14px" color="#333333">
                        Paid private parking nearby
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      display="flex"
                      alignItems="flex-start"
                      gap={1}
                      padding="1vh 0"
                    >
                      <WifiIcon />
                      <Typography fontSize="14px" color="#333333">
                        Free High Speed Internet (WiFi)
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      display="flex"
                      alignItems="flex-start"
                      gap={1}
                      padding="1vh 0"
                    >
                      <HotTubIcon />
                      <Typography fontSize="14px" color="#333333">
                        Hot bath
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      display="flex"
                      alignItems="flex-start"
                      gap={1}
                      padding="1vh 0"
                    >
                      <LocalBarIcon />
                      <Typography fontSize="14px" color="#333333">
                        Bar/lounge
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} padding="1vh 0">
                    <Typography
                      fontSize="14px"
                      fontWeight="700"
                      padding="1vh 0"
                    >
                      Room features
                    </Typography>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid
                      item
                      xs={6}
                      display="flex"
                      alignItems="flex-start"
                      gap={1}
                      padding="1vh 0"
                    >
                      <WifiIcon />
                      <Typography fontSize="14px" color="#333333">
                        Bathrobes
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      display="flex"
                      alignItems="flex-start"
                      gap={1}
                      padding="1vh 0"
                    >
                      <HotTubIcon />
                      <Typography fontSize="14px" color="#333333">
                        Air conditioning
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      display="flex"
                      alignItems="flex-start"
                      gap={1}
                      padding="1vh 0"
                    >
                      <LocalBarIcon />
                      <Typography fontSize="14px" color="#333333">
                        Desk
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      display="flex"
                      alignItems="flex-start"
                      gap={1}
                      padding="1vh 0"
                    >
                      <LocalParkingRoundedIcon />
                      <Typography fontSize="14px" color="#333333">
                        Housekeeping
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} bgcolor="#F2F4F3" my="2vh">
                  <Typography
                    fontSize="16px"
                    fontFamily="Inter"
                    fontWeight="700"
                    padding="16px 14px"
                    display="flex"
                    alignItems="center"
                  >
                    Compared to other sites
                  </Typography>
                </Grid>
                {drawerData?.offers?.map((offer) => {
                  return (
                    <a href={offer.url} target="blank">
                      <Grid
                        container
                        borderBottom="1px solid #C5C5C5"
                        padding="32px 20px"
                      >
                        <Grid item xs={7}>
                          <img src={offer.logoUrl} width="50%" />
                          <Typography
                            fontWeight="700"
                            fontSize="14px"
                            color="#000"
                          >
                            {offer.roomName}
                          </Typography>
                        </Grid>
                        <Grid item xs={5} display="flex" alignItems="center">
                          <Box display="flex" flexDirection="column">
                            <Typography
                              fontWeight="700"
                              fontSize="24px"
                              textAlign="end"
                              color="#000"
                            >
                              {offer.currency +
                                " " +
                                Math.floor(offer.basePrice + offer.taxes)}
                            </Typography>
                            <Typography
                              textAlign="right"
                              fontSize="12px"
                              fontWeight="400px"
                              color="#808080"
                            >
                              Nightly rate per room
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <ChevronRightIcon sx={{color:'#000'}} />
                          </Box>
                        </Grid>
                      </Grid>
                    </a>
                  );
                })}
                <a href={drawerData?.vioUrl} target="_blank">
                  <Grid
                    container
                    display="flex"
                    alignItems="center"
                    padding="10px 20px"
                    bgcolor="#EBF8FF"
                    margin="10px 0 50px"
                  >
                    <Grid
                      item
                      xs={8}
                      color="#000"
                      fontSize="16px"
                      fontWeight="700"
                      fontFamily="Inter"
                    >
                      Discover hidden offers with
                    </Grid>
                    <Grid item xs={3}>
                      <img
                        src="/assets/images/vio.com.png"
                        width="100%"
                        height="40px"
                      />
                    </Grid>
                    <Grid item xs={1} display="flex" justifyContent="flex-end">
                      <img src="/assets/images/cursor.png" height="30px" />
                    </Grid>
                  </Grid>
                </a>
              </Box>
            </Card>
          </Box>
          {/* </div> */}
        </Drawer>

        {/* drawer code end */}

        <Grid
          item
          md={3}
          display={{ xs: "none", lg: "block" }}
          padding="20px 30px"
        >
          <Typography
            component={Button}
            padding="0.4em 1.5em"
            fontWeight="bold"
            fontSize="0.8em"
            textTransform="none"
            color="#4C4C4C"
            bgcolor="#E3E6F7"
          >
            <HotelIcon /> &nbsp; Search results
          </Typography>
          {/* <Typography
            component={Button}
            padding="0.4em 1.5em"
            margin="0 1.5em"
            fontWeight="bold"
            fontSize="0.8em"
            textTransform="none"
            color="#4C4C4C"
            bgcolor="#FFFFFF"
          >
            <FavoriteIcon /> &nbsp; Favourites
          </Typography> */}
        </Grid>
        <Grid
          item
          margin={{ xs: "0 2vw", md: "0 0 2vh", lg: "0 0 2vh" }}
          justifyContent="center"
          display={{ lg: "flex" }}
          xs={12}
          md={12}
          lg={7}
        >
          <CityContext.Provider value={{ handleCityName }}>
            <BookingModel
              getCity={handleCitySearch}
              getArrivalCityName={handleArrivalCityName}
              currentPage="hotels-list"
            />
          </CityContext.Provider>
        </Grid>
        <Grid item xs={0} md={0} lg={2}></Grid>

        <Grid
          item
          xs={12}
          md={5}
          lg={3}
          padding={{ xs: "0 30px 20px", md: "0 5vw 0 0", lg: "0 30px 20px" }}
        >
          {/* <Box display={{ xs: "block", md: "none", lg: "block" }}>
            <CityContext.Provider value={{ handleCityName }}>
              <BookingModel getCity={handleCitySearch} />
              
            </CityContext.Provider>
          </Box> */}
          <Card
            sx={{
              bgcolor: "white",
              display: { xs: "none", md: "block" },
              borderRadius: "12px",
              margin: { xs: "1.5em 0", md: "0", lg: "0" },
              padding: "1.5em",
              position: "sticky",
              top: "3vh",
              boxShadow: "none",
            }}
          >
            {/* itinerary code start */}

            <Grid item lg={12} className="categorySection">
              <HotelFilters
                getFilters={addFilter}
                getPrice={handlePriceChange}
                applyFilters={applySelectedFilter}
              />
            </Grid>
            {/* 
              <Typography variant="body1" sx={{ fontWeight: 700, mt: 2, mb: 1 }}>
              Guest Rating
               </Typography>
               <Typography variant="body2" sx={{ mb: 1 }}>
                  {guestRating[0]} - {guestRating[1]} +
                </Typography>

                <Slider
                 value={guestRating}
                 onChange={handleRatingChange}
                 valueLabelDisplay="auto"
                 min={1}
                 max={10}
                  /> */}
            {/* itinerary code end */}
          </Card>
        </Grid>
        <Grid
          item
          lg={7}
          md={7}
          xs={12}
          bgcolor="white"
          borderRadius="12px"
          padding="0 1.5em"
        >
          {/* itinerary code start */}

          <Grid item xs={12}>
            <Box
              display={{ xs: "none", md: "flex", lg: "flex" }}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Typography variant="body1" color="primary" fontWeight="700">
                Sort :
              </Typography>
              <Select
                sx={{ m: 1, minWidth: 120 }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                value={sortValue}
                onChange={handleSortChange}
              >
                <MenuItem value="best">Best</MenuItem>
                {/* <MenuItem value={20}>Distance from city center</MenuItem> */}
                <MenuItem value="price">Price (lowest first)</MenuItem>
                <MenuItem value="top-reviewed">Top reviewed</MenuItem>
                {/* <MenuItem value={50}>Property rating and price</MenuItem> */}
              </Select>
            </Box>
            <Grid
              item
              xs={12}
              display={{ xs: "flex", md: "none" }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              padding="0 0 15px 0"
            >
              <HotelFilters handleSortChange={handleSortChange} />
            </Grid>
          </Grid>
          {/* itinerary code end */}

          {cards}
          {displayCount < hotelsData.length && (
            <Button onClick={loadMore}>Load More</Button>
          )}
        </Grid>
        {/* <Grid item lg={2} xs={0} padding="0 1.5em">
          <img src="./assets/images/addBanner.png" width="100%" />
        </Grid> */}
      </Grid>
    </>
  );
}
