import { React, useState, useEffect, createContext } from "react";
import Grid from "@mui/material/Grid";
import Navbar from "../../components/ui-components/Navbar";
import { OutlinedInput, Paper, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DateRange } from "react-date-range";
import { format } from "date-fns";
import PersonIcon from "@mui/icons-material/Person";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./scss/HomePage.css";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Slider from "@mui/material/Slider";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import axios from "axios";
import Link from "@mui/material/Link";
import { NavLink } from "react-router-dom";
import Footer from "../../components/ui-components/Footer";
import { session } from "../Sessions";
import { useContext } from "react";
import Mobilefooter from "../../components/ui-components/Mobilefooter";
import Cookies from "universal-cookie";
import PaidIcon from "@mui/icons-material/Paid";

// trying

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import DestinationPicker from "../../components/ui-components/DestinationPicker";
import HotelIcon from "@mui/icons-material/Hotel";
import BookingModel from "../../components/ui-components/BookingModel";
import ContentSection from "../../components/ui-components/ContentSection";
import HotelsList from "../Hotels-list/HotelsList";
import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.production";

const api = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL,
  baseURL: "http://localhost:3001",

  headers: {
    Authorization: "Bearer " + window.sessionStorage.getItem("token"),
  },
});
export default function HomePage() {
    const [arrivalCity, setArrivalCity] = useState();
  // states for feild open and close

  // state opening and closing checks

  // const handleDateRange = (event, newValue) => {
  //   setDaysRange(newValue);
  // };
  const handleArrivalCityName = (event) => {
    setArrivalCity(event);
  };
  const addnewdestination = () => {
    // let destinations = [...destinationsExpands];
    // if (destinationsExpands[0] == true) {
    //   destinations[0] = false;
    //   setdestinationExpand(destinations);
    // } else {
    //   destinations[0] = true;
    //   setdestinationExpand(destinations);
    // }
    // let length = [...alldestinations].length;
    // if (length + 1 < 11) {
    //   setDestinations([
    //     ...alldestinations,
    //     { city: "", country: "", minStayDuration: 1, maxStayDuration: 2 },
    //   ]);
    //   if (length + 2 > 10) {
    //     setmaxDestination(false);
    //   }
    // } else {
    //   // here i will update value of maxdestination to true if its > 11 and will display it under add destination
    // }
  };

  return (
    <Grid container>
      <Grid
      item
        container
        height={{ xs: "100vh", md: "auto", lg: "100vh" }}
        sx={{
          backgroundImage: `url(assets/images/backgroundimg.png)`,
          backgroundSize: { xs: "cover" },
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid item xs={12}>
          <Navbar />
        </Grid>

        <Grid
          item
          container
          padding={{ xs: "0 10vw", md: "0 10vw", lg: "0 5vw" }}
          display="flex"
          justifyContent="center"
          alignContent="flex-start"
        >
          
          <Grid item lg={6} md={12} xs={12}>
          <Typography
          className="heading"
              color="white"
              fontWeight="700"
              fontSize={{ xs: "28px", md: "32px", lg: "50px" }}
              lineHeight="140%"
              width={{ xs: "auto", md:'auto', lg:'40vw'}}
            >
              The Ultimate Trip Planning Solution
            </Typography>
            {/* <Typography
              bgcolor="transparent"
              color="white"
              fontWeight="700"
              fontSize={{ xs: "28px", md: "48px", lg: "84px" }}
              lineHeight={{ xs: "normal", md: "normal", lg: "89px" }}
              letterSpacing={{ xs: "-1.6px", md: "-1.92px", lg: "-3.36px" }}
              width={{ xs: "auto", md: "auto", lg: "50vw" }}
              marginBottom={{ xs: "16px", md: "16px", lg: "32px" }}
            >
              The Ultimate Trip Planning Solution
            </Typography> */}
            <Typography
              paddingTop={{ xs: "2vh", lg: "1vh" }}
              paddingBottom={{xs:'2vh', lg:'0'}}
              marginBottom={{ xs: "2vh" }}
              lineHeight='30px'
              color="#fff"
              fontWeight="600"
              fontSize={{ xs: "16px", md: "16px", lg: "18px" }}
              fontFamily="Inter"
              width={{ xs: "auto", md: "auto", lg: "30vw" }}
              textAlign="justify"
            >
              <b>Welcome to Catch U There</b> - your passport to hassle-free travel
              planning. Ditch the chaos of multiple sources and complicated
              planning. We simplify the journey, offering you quick and
              cost-effective solutions. Unleash the simplicity, and explore the
              world effortlessly with us.
            </Typography>
            <HashLink smooth to="#subscribe">
            <Button variant="contained" sx={{ width:{xs:'100%', md:'auto', lg:'auto'}, bgcolor:"#6373CF", textTransform:'none', padding:'10px 30px', color:'white', fontFamily: 'Inter', fontSize: '18px', fontWeight: '600'}}>
              Subscribe
            </Button>
            </HashLink>
          </Grid>
          <Grid item lg={6} md={12} xs={12}>
            <BookingModel
            getArrivalCityName={handleArrivalCityName}
            currentPage="home1" />
            {/* <Booking/> */}
          </Grid>
        </Grid>
      </Grid>

      {/*Rest of Body */}
      <Grid item xs={12}>
      <ContentSection />
      </Grid>
      {/* Footer */}
      <Grid item xs={12}>
      <Footer />
      <Mobilefooter />
      </Grid>
    </Grid>
  );
}
