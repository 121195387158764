import React, { createContext, useState } from 'react'
import Itinerary from './Iitinerary/Iitinerary';

export const iitineraryContext = createContext()

const IitineraryContext = ({ children }) => {


  const [addItemopen, setaddItemOpen] = useState(false);
  const [displayPage, setdisplayPage] = useState("home");
  const [planId, setPlanId] = useState("");
  const [swapflightId, setSwapFlightId] = useState("");
  const [dataChange, setDataChange] = useState(false);


  const [passengers, setPassengers] = useState({
    adults: 0,
    children: 0,
    rooms: 0
  })

  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 7)),
      key: 'selection'
    }
  ]);

  const [travellerDetail, setTravellerData] = useState(null);
  const [destinations, setDestinations] = useState([{ city:"",country:"",minStayDuration:1,maxStayDuration:2}])
  const [expanded, setExpanded] = useState(false);
  const [changeinfotoggle, setChangeInfo] = useState(false);
  const [travellersDisable, setTravellerDisable] = useState(true);
  const [datesDisable, setDatesDisable] = useState(false);
  const [flightDetailDrawerState,setFlightDetailDrawerState]=useState(false);
const [flightDetails,setFlightDetails]=useState([]);
  const countries = [
    { code: 'Netherlands', label: 'Amsterdam' },
    { code: 'Islamabad', label: 'ISB' },
    {
      code: 'Andorra',
      label: 'Andorra la Vella',
    },
    { code: "Greece", label: "Athens (Athína)" },
    { code: "Serbia", label: "Belgrade (Beograd)" },
    { code: "Germany", label: "Berlin" },
    { code: "Switzerland", label: "Bern (Berne, Berna)" },
    { code: "Slovakia", label: "Bratislava" },
    { code: "	Ireland", label: "Dublin (Baile Átha Cliath)" },
    { code: "	Romania", label: "Bucharest (Bucuresti)" },
  ];

  const handleaddItemClick = () => {
    setaddItemOpen(!addItemopen);
  };


  const changePage = (page) => {
    setdisplayPage(page);
  }
  const swapBtnClick = (swapType) => {
    setaddItemOpen(!addItemopen);
    setdisplayPage(swapType);
  }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const changeDate = (item) => {
    setDatesDisable(false);
    setDates(item);
  }

  const addtopassenger = value => () => {
    setTravellerDisable(false);

    console.log("calling")
    if (value == "room") {

      let rooms = passengers.numberOfRooms + 1
      setPassengers({ ...passengers, numberOfRooms: rooms })
    }
    else if (value == "adult") {
      let adult = passengers.numberOfAdults + 1
      setPassengers({ ...passengers, numberOfAdults: adult })
    }
    else {
      let child = passengers.numberOfChildren + 1

      setPassengers({ ...passengers, numberOfChildren: child })
    }
  } 

  const minusfrompassenger = value => () => {
    setTravellerDisable(false);
    console.log("calling")
    if (value == "room") {

      let rooms = passengers.numberOfRooms - 1
      if (rooms >= 1) {
        setPassengers({ ...passengers, numberOfRooms: rooms })
      }
    }
    else if (value == "adult") {
      let adult = passengers.numberOfAdults - 1
      if (adult >= 1) {
        setPassengers({ ...passengers, numberOfAdults: adult })
      }
    }
    else {
      let child = passengers.numberOfChildren - 1
      if (child >= 1) {
        setPassengers({ ...passengers, numberOfChildren: child })
      }
    }
  }

  const closeChangeInfo = () => {
    setChangeInfo(false);
  }

  const toggleChangeInfo = (type) => {
    setChangeInfo(true);
    setdisplayPage(type);
  }

  const handledestinationcountry = (value, country, index) => {

    const values = [...destinations]
    values[index].city=value;
    values[index].country=country;
    values[index].minStayDuration=1;
    values[index].maxStayDuration=2;

    console.log(values[index]);


    // if(destinationError[index]==true)
    // {
    //   const error=[...destinationError]
    //   error[index]=false;
    //   setDestinationError(error);
    // }
    setDestinations(values)

    console.log(destinations);
  }

  const addnewdestination = () => {
    let length = [...destinations].length
    if (length + 1 < 11) {
      setDestinations([...destinations, { city:"",country:"",minStayDuration:0,maxStayDuration:0}])
    }
    else {

      // here i will update value of maxdestination to true if its > 11 and will display it under add destination
    }
  }

  const handleminStayDurationChange = (sign, index) => {
    const alldestinations = [...destinations];
    if (sign == "+") {
      alldestinations[index].minStayDuration = alldestinations[index].minStayDuration + 1;

    }
    else if (sign == "-") {
      if (alldestinations[index].minStayDuration - 1 > 0) {
        alldestinations[index].minStayDuration = alldestinations[index].minStayDuration - 1;
      }
    }

    setDestinations(alldestinations);
  }

  return <iitineraryContext.Provider value={{
    planId, setPlanId, changeinfotoggle, closeChangeInfo, toggleChangeInfo, travellerDetail, setTravellerData,
    addItemopen, setaddItemOpen, handleaddItemClick, displayPage, changePage, setdisplayPage,
    swapBtnClick, passengers, setPassengers, destinations, setDestinations, dates, setDates,
    expanded, setExpanded, handleChange, countries, addtopassenger, minusfrompassenger, handledestinationcountry,
    addnewdestination, handleminStayDurationChange, changeDate, travellersDisable, datesDisable, swapflightId, setSwapFlightId, dataChange, setDataChange,
    flightDetailDrawerState,setFlightDetailDrawerState,flightDetails,setFlightDetails
  }}>{children}</iitineraryContext.Provider>;
};

export default IitineraryContext