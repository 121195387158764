import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import { Grid} from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { Stack } from '@mui/system';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

export default function ExperienceItem(props) {
  return (
    <Grid item lg={4} xs={12}>
    <Card sx={{ maxWidth: 345 }} className="experienceItemCard">
    <CardMedia
      component="img"
      height="100"
      image="./assets/images/experience1.png"
      alt="green iguana"
    />
    <CardContent >
      <Grid container>
        <Grid item lg={8}>
          <Stack direction={"row"}>

          <Typography variant='body2' className='experienceType'>Sightseeing</Typography>
          <Typography variant='body2' className='ratings'>9.0</Typography>
          </Stack>
        </Grid>
        <Grid item lg={4}>
          <Stack direction={"row"} sx={{display:"flex",justifyContent:"flex-end"}}>
          {props.price==""?<Typography variant='h5' sx={{fontWeight:700,color:'transparent'}}>T</Typography>:""}
       
          {(props.price!="free")&&(props.price!="")?<><Typography variant='body2'>$</Typography><Typography variant='h5' sx={{fontWeight:700}}>{props.price}</Typography> </>:"" }

          {props.price=="free"?<Typography variant='h5' sx={{fontWeight:700}}>Free</Typography>:""}
       
          </Stack>
         </Grid>
      </Grid>
      <Typography gutterBottom variant="h6" component="div" className='experienceTitle'>
        Palacio real
      </Typography>
      <Typography variant="body2" color="text.secondary">
        13 Madrid Road,Madrid,20175,Spain
      </Typography>

      <Grid container>
        <Grid item lg={12}>
        <Stack direction={"row"}>
          <div className='experienceAttribute'>
         < DirectionsRunIcon/>
         <Typography variant='body2' className='attributeName'>Close to hotel</Typography>
          </div>
          <div className='experienceAttribute'>
         < AttachMoneyIcon/>
         <Typography variant='body2' className='attributeName'>Close to hotel</Typography>
          </div>
          </Stack>
        </Grid>
      </Grid>
    </CardContent>
    <CardActions className='buttons'>
      <Button size="small" variant='outlined'>View Details</Button>
      <Button size="small" className='primaryBtn'>Add Experience</Button>
    </CardActions>
  </Card>
  </Grid>
  )
}
