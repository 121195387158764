import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { iitineraryContext } from "../../pages/IitineraryContext";

import { Stack } from "@mui/system";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LockIcon from "@mui/icons-material/Lock";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import IconButton from "@mui/material/IconButton";
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: "Bearer " + window.sessionStorage.getItem("token"),
  },
});

export default function Flightcard(props) {
  const { swapBtnClick, planId, setDataChange, setaddItemOpen,setFlightDetails,setFlightDetailDrawerState } =
    useContext(iitineraryContext);
  const [flightId, setFlightId] = useState("");
  const [imgpath, setImgpath] = useState("");
  const [flightNameSize, setNameSize] = useState("20px");
  const [flightdetail, setflightdetail] = useState([]);

  useEffect(() => {
    setFlightId(props.id);
    setImgpath("./assets/images/airlineLogos/ETD.png");
    if (props.name.length > 17) {
      setNameSize("13px");
    }
    if (flightId) {
      console.log("fid", flightId);
      console.log("days", props.days);
      console.log(imgpath);
      setflightdetail(props.flightDetails)
    }
  }, []);

  const showFlightDetails=()=>{
    setFlightDetails(flightdetail);
    setFlightDetailDrawerState(true);
  }

  const selectNewFlight = () => {
    console.log("collection id", props.collectionId);
    console.log("flight id", flightId);

    let data = {
      collectionId: props.collectionId,
      offerId: flightId,
    };
    api
      .post("/api/plans/" + planId + "/swapflight", data)
      .then((res) => {
        console.log(res);
        console.log("flight changed");
        setaddItemOpen(false);
        setDataChange(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <Card className="flight card">
        <Grid container>
          <Grid item lg={8} xs={12}>
            <Grid container className="flight-info">
              <Grid
                item
                lg={4}
                xs={12}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <img src={imgpath} alt="" />
                <div className="display-col">
                  <Typography
                    variant="h5"
                    sx={{ fontSize: flightNameSize }}
                    component="h5"
                  >
                    {props.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "13px" }}
                    component="span"
                  >
                    {props.flightNumber}
                  </Typography>
                </div>
              </Grid>

              <Grid item lg={8} xs={12} className="timing">
                <div className="display-col">
                  <Typography variant="h5" component="h5">
                    {props.takeOffTime}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="span"
                    className="cityName"
                  >
                    {props.departure}
                  </Typography>
                </div>
                <Divider className="time">
                  <div className="display-col flightDuration">
                    <Typography variant="body2" component="h5">
                      {props.days > 0 ? props.days + "d " : ""}
                      {props.hours > 0 ? +props.hours + "h " : ""}
                    </Typography>
                    <Typography variant="body2" component="span">
                      {props.numberOfStops == 0
                        ? "Non stop"
                        : props.numberOfStops + " Stops Over"}
                    </Typography>
                  </div>
                </Divider>
                <div className="display-col">
                  <Typography variant="h5" component="h5">
                    {props.landingTime}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="span"
                    className="cityName"
                  >
                    {props.arrival}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={4} xs={12} className="pricing">
            <Grid container>
              <Grid item lg={12} xs={12}>
                <Grid container>
                  <Grid item lg={6} xs={6}>
                    <Typography variant="body2" sx={{ fontSize: 12 }}>
                      Class:
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: 12, fontWeight: 700 }}
                    >
                      {props.class}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <Typography
                      variant="h4"
                      sx={{ fontWeight: 700, display: "flex", float: "right" }}
                    >
                      {" "}
                      <Typography
                        variant="body1"
                        sx={{ fontSize: 20, fontWeight: 700, color: "#808080" }}
                      >
                        $
                      </Typography>
                      {props.price}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              sx={{
                mt: 3,
                pl: { lg: 0, sm: "7%" },
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item lg={6} sm={6} xs={6}>
                <Button
                  sx={{
                    fontSize: 10,
                    padding: "5px 7px",
                    borderRadius: "12px",
                  }}
                  variant="outlined"
                  className="viewBtns"
                  onClick={()=>showFlightDetails() }
                >
                  View details
                </Button>
              </Grid>
              <Grid
                item
                lg={6}
                sm={6}
                xs={5.5}
                className="adjustToEnd"
                sx={{ textAlign: "center" }}
              >
                {props.purpose !== "swap" ? (
                  <Button
                    sx={{ fontSize: 10, fontWeight: 700 }}
                    onClick={() => swapBtnClick("swapflight")}
                    className="textBtns"
                  >
                    Swap flight
                  </Button>
                ) : (
                  <Button
                    sx={{ fontSize: 10 }}
                    className="selectBtn"
                    onClick={() => selectNewFlight()}
                  >
                    Select Flight
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <Stack
        className="cardsSide"
        sx={{
          right: { xs: "-16px !important", sm: "-6px !important", lg: "0" },
        }}
      >
        <IconButton aria-label="favorite" className="sideIconBtn">
          <DeleteForeverIcon />
        </IconButton>
      </Stack>
    </>
  );
}
