import React,{useContext} from 'react'
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Button from '@mui/material/Button';
import AirlineSeatLegroomNormalIcon from '@mui/icons-material/AirlineSeatLegroomNormal';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { Stack } from '@mui/system';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LockIcon from '@mui/icons-material/Lock';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import { iitineraryContext } from '../../pages/IitineraryContext';

export default function Pickupcard() {
  const{swapBtnClick}=useContext(iitineraryContext)

  return (
    <>
    <Card className="pickup card">
      <Grid container>
        <Grid item lg={2.3}>
            <img src="./assets/images/Car.png" alt="" />
        </Grid>

        <Grid item lg={5.5} xs={12}>
            <Typography variant="h5" sx={{mb:{xs:0.3,lg:2}}}>Car Hire Pickup</Typography>
            <Typography variant="body2" sx={{mb:{xs:0.3,lg:2}}}>Pickup: 15 Terrase Road, Madrid, 20175, Spain</Typography>
            <Grid container sx={{pt:0.5}}>
                <Grid item lg={3}>
                <Button className="card-icons" variant="text" startIcon={<AirlineSeatLegroomNormalIcon />}>
        5 Seats
      </Button>
                </Grid>
                <Grid item lg={3}>
                <Button className="card-icons" variant="text" startIcon={<AcUnitIcon />}>
                Air-con
      </Button>
                </Grid>

                <Grid item lg={3}>
                <Button className="card-icons" variant="text" startIcon={<DirectionsCarIcon />}>
                4 Doors
      </Button>
                </Grid>

                <Grid item lg={3}>
                <Button className="card-icons" variant="text" startIcon={<DirectionsCarIcon />}>
                Automatic
      </Button>
                </Grid>
            </Grid>
        </Grid>

        <Grid item lg={4} xs={12} >
            <Grid container className="pricing">
            <Grid item lg={6} xs={6}>
                  <Typography variant="body2" sx={{fontSize:12}}>Vehicle type:</Typography>
                  <Typography variant="body1" sx={{fontSize:12,fontWeight:700}}>Compact</Typography>
                </Grid>
                <Grid item lg={6} xs={6} sx={{display:"flex",flexDirection:"column",textAlign:"right"}}>
                <Typography variant="h4" sx={{fontWeight:700,display:"flex",justifyContent:"flex-end"}}> <Typography variant="body1" sx={{fontSize:20,fontWeight:700,color:"#808080"}}>$</Typography>60</Typography>
                <Typography variant="body1" sx={{fontSize:12,color:"#808080",float:"right"}}>for 4 days</Typography>
                </Grid>
          
            </Grid>
            
            <Grid container sx={{mt:{xs:1,lg:3},pl:"2%",display:"flex",justifyContent:"space-between"}}>
           
                <Grid item lg={5.7} sm={6} xs={6}>
                <Button sx={{padding:"5px 7px",borderRadius:"12px"}}variant="outlined" className="viewBtns">View details</Button>
                </Grid>
                
                <Grid item lg={6} sm={6} xs={6} className="adjustToEnd" sx={{textAlign:"center"}}>
                <Button sx={{fontWeight:700}}variant="text" className="textBtns">Swap transport</Button>
                </Grid>
            </Grid>
        </Grid>
      </Grid>
    </Card>
  

    <Stack className="cardsSide" sx={{right:{xs:"-16px !important",sm:"-6px !important",lg:"0"}}}>
      <IconButton aria-label="favorite" className='sideIconBtn'>
        <DeleteForeverIcon />
      </IconButton>
    </Stack>
{/* <Button className="delBtn">Dele</Button> */}
   
    </>
  )
}
