import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Rating,
  Typography,
  useMediaQuery,
} from "@mui/material";

import PoolIcon from "@mui/icons-material/Pool";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import WifiIcon from "@mui/icons-material/Wifi";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import VerifiedIcon from "@mui/icons-material/Verified";

export default function HotelsComponent({ item, toggleDrawer, hotelsData }) {
  let isLargeScreen = useMediaQuery("(min-width:600px)");
  let sendDrawerData = () => {
    toggleDrawer(item);
  };
  return (
    <>
      <Card
        key={item.id}
        sx={{
          marginBottom: {xs:'2vh', md:'2vh', lg:"3vh"},
          borderRadius: "1em",
          // bgcolor:'#E44E4E',
          bgcolor:'#6373CF',
          border:'1px solid #E7E5EB',
          padding: "0 0 0 1em",
        }}
        variant="outlined"
      >
        <Grid container bgcolor="white">
          <Grid
            item
            xs={12}
            md={12}
            lg={3}
            padding="0"
            sx={{
              height: { xs: "126px", md:'113px', lg: "auto" },
              backgroundImage: `url(${
                item.images
                  ? item.images[0]
                  : isLargeScreen
                  ? "./assets/images/no-hotel-found.png"
                  : "./assets/images/no-hotel-found-mobile.png"
              })`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            {/* {item.image ?
            <img src={item.image} height="250px" width="100%"/>
            :
            <img src="./assets/images/no-hotel-found.png" height="100%" width='100%'/>
            } */}
          </Grid>
          <Grid item xs={12} md={12} lg={9} bgcolor="white" padding="15px">
            <Grid container>
              <Grid item xs={8} display="flex" flexDirection="column" gap={1}>
                <Typography fontSize="16px" color="#191919" fontWeight="bold">
                  {item.requestedName}
                </Typography>
                <Box
                  display={{ xs: "none", lg: "flex" }}
                  flexDirection="row"
                  alignItems="center"
                  gap="15px"
                >
                  <Rating
                    value={Number(item.starRating).toFixed(1)}
                    icon={<FiberManualRecordIcon sx={{ color: "#00AA6C" }} />}
                    emptyIcon={
                      <FiberManualRecordOutlinedIcon
                        sx={{ color: "#00AA6C" }}
                        fontSize="inherit"
                      />
                    }
                    readOnly
                  />
                  <Typography
                    bgcolor="#00AA6C"
                    borderRadius="4px"
                    padding="4px 8px"
                    fontWeight="bold"
                    fontSize="10px"
                    display="inline"
                  >
                    {Number(item.starRating).toFixed(1)}
                  </Typography>
                  <Typography fontWeight="600" fontSize="11px">
                    Excellent
                  </Typography>
                </Box>
                <Typography fontSize="10px" fontWeight="400">
                  {item.locationString}
                </Typography>
                {/* <Typography
                      display={{xs:'flex', lg:'none'}}
                      alignItems="center"
                      gap={1}
                      color="#4D4D4D"
                      fontWeight="700"
                      fontSize={{xs:'12px', lg:'14px'}}
                    >
                      <VerifiedIcon fontSize="small" /> Free cancellation{" "}
                    </Typography> */}
                <Typography
                  margin="1em 0"
                  display={{ xs: "none", lg: "flex" }}
                  flexWrap="wrap"
                  gap={3}
                >
                  <span key={item.id}>
                    {item.amenities?.map((amenity, index) => (
                      <Typography
                        display="flex"
                        gap={1}
                        fontSize="12px"
                        fontWeight="800"
                        key={index}
                      >
                        {amenity == "Wifi" ? (
                          <>
                            <WifiIcon fontSize="small" /> Wifi
                          </>
                        ) : (
                          ""
                        )}
                      </Typography>
                    ))}
                  </span>

                  <span key={item?.id}>
                    {item.amenities?.map((amenity, index) => (
                      <Typography
                        display="flex"
                        gap={1}
                        fontSize="12px"
                        fontWeight="800"
                        key={index}
                      >
                        {amenity == "Paid public parking nearby" ? (
                          <>
                            <DriveEtaIcon fontSize="small" /> Parking
                          </>
                        ) : (
                          ""
                        )}
                      </Typography>
                    ))}
                  </span>

                  <span key={item.id}>
                    {item.amenities?.map((amenity, index) => (
                      <span key={index}>
                        {amenity == "Gym" ? (
                          <Typography
                            display="flex"
                            gap={1}
                            fontSize="12px"
                            fontWeight="800"
                          >
                            <FitnessCenterIcon fontSize="small" /> Gym
                          </Typography>
                        ) : (
                          ""
                        )}
                      </span>
                    ))}
                  </span>

                  <span key={item.id}>
                    {item.amenities?.map((amenity, index) => (
                      <Typography
                        display="flex"
                        gap={1}
                        fontSize="12px"
                        fontWeight="800"
                        key={index}
                      >
                        {amenity == "Restaurant" ? (
                          <>
                            <RestaurantIcon fontSize="small" /> Restaurant
                          </>
                        ) : (
                          ""
                        )}
                      </Typography>
                    ))}
                  </span>

                  <span key={item.id}>
                    {item.amenities?.map((amenity, index) => (
                      <Typography
                        display="flex"
                        gap={1}
                        fontSize="12px"
                        fontWeight="800"
                        key={index}
                      >
                        {amenity == "Air conditioning" ? (
                          <>
                            <AcUnitIcon fontSize="small" /> Air-Con
                          </>
                        ) : (
                          ""
                        )}
                      </Typography>
                    ))}
                  </span>

                  <span key={item.id}>
                    {item.amenities?.map((amenity, index) => (
                      <Typography
                        display="flex"
                        gap={1}
                        fontSize="12px"
                        fontWeight="800"
                        key={index}
                      >
                        {amenity == "Pool" ? (
                          <>
                            <PoolIcon fontSize="small" /> Pool
                          </>
                        ) : (
                          ""
                        )}
                      </Typography>
                    ))}
                  </span>
                  {/* <Typography
                    display="flex"
                    gap={1}
                    fontSize="12px"
                    fontWeight="800"
                  >
                    <FitnessCenterIcon fontSize="small" /> Gym
                  </Typography>
                  <Typography
                    display="flex"
                    gap={1}
                    fontSize="12px"
                    fontWeight="800"
                  >
                    <RestaurantIcon fontSize="small" /> Restaurant
                  </Typography>
                  <Typography
                    display="flex"
                    gap={1}
                    fontSize="12px"
                    fontWeight="800"
                  >
                    <DriveEtaIcon fontSize="small" /> Parking
                  </Typography>
                  <Typography
                    display="flex"
                    gap={1}
                    fontSize="12px"
                    fontWeight="800"
                  >
                    <WifiIcon fontSize="small" /> Wifi
                  </Typography>
                  <Typography
                    display="flex"
                    gap={1}
                    fontSize="12px"
                    fontWeight="800"
                  >
                    <AcUnitIcon fontSize="small" /> Air-Con
                  </Typography> */}
                </Typography>
              </Grid>
              <Grid item xs={4} display="flex" direction="column">
                <Box
                  textAlign="right"
                  marginBottom="20px"
                  display={{ xs: "block", lg: "none" }}
                >
                  <img src="./assets/images/Tripadvisor.png" width="34px" />
                </Box>
                <Typography
                  textAlign="right"
                  fontSize="26px"
                  fontWeight="bold"
                  color="#333333"
                >
                  <AttachMoneyIcon fontSize="small" />
                  {Math.floor(item.anchorBase+item.anchorTaxes+item.anchorhotelFees)}
                </Typography>
                <Typography
                  marginBottom="22px"
                  textAlign="right"
                  fontSize="12px"
                  color="#808080"
                >
                  Nightly rate per room
                </Typography>
              </Grid>
            </Grid>

            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              gap={2}
            >
              {/* <Typography
                display={{xs:'none', lg:'flex'}}
                alignItems="center"
                gap={1}
                color="#4D4D4D"
                fontWeight="700"
                fontSize={{xs:'10px', lg:'14px'}}
              >
                <VerifiedIcon /> Free cancellation{" "}
              </Typography> */}
              <Box textAlign="right" display={{ xs: "none", lg: "flex" }}>
                <img src="./assets/images/Tripadvisor.png" width="34px" />
              </Box>
              <Button
                onClick={sendDrawerData}
                variant="outlined"
                sx={{
                  textTransform: "none",
                  bgcolor: "white",
                  color: "#73839C",
                  borderColor: "#DEE5EF",
                  borderRadius: "12px",
                  fontSize: "12px",
                  padding: "7px 10px",
                }}
              >
                View Details
              </Button>
              <Button
                variant="contained"
                href={item.bookingUrl}
                target="_blank"
                sx={{
                  textTransform: "none",
                  bgcolor: "#3C50C3",
                  color: "white",
                  borderRadius: "12px",
                  fontSize: "12px",
                  padding: "7px 10px",
                }}
              >
                Check Availability
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
