import {
  Box,
  Button,
  Card,
  Drawer,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AddIcon from "@mui/icons-material/Add";
import LuggageIcon from "@mui/icons-material/Luggage";
import { useDataContext } from "../../vendors/Contexts";

export default function FlightsCard({ item, toggleDrawer }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const sendDrawerData = () => {
    toggleDrawer(item);
  };
  const return0Routes = item.routes.filter((route) => route.return === 0);
  // Get the first and last items from return0Routes
  const firstReturn0 = return0Routes[0];
  const lastReturn0 = return0Routes[return0Routes.length - 1];
  // Filter routes where return is 1
  const return1Routes = item.routes.filter((route) => route.return === 1);

  // Get the first and last items from return1Routes
  const firstReturn1 = return1Routes[0];
  const lastReturn1 = return1Routes[return1Routes.length - 1];

  // time converstion
  const oneWayArrivalTime = new Date(lastReturn0.arrival);
  const oneWayDepartureTime = new Date(firstReturn0.departure);

  // Calculate the time difference in milliseconds
  const oneWayTimeDifference = oneWayArrivalTime - oneWayDepartureTime;

  // Convert milliseconds to hours and minutes
  const oneWayHours = Math.floor(oneWayTimeDifference / (1000 * 60 * 60));
  const oneWayDays = Math.floor(oneWayHours / 24);
  const oneWayMinutes = Math.floor(
    (oneWayDepartureTime % (1000 * 60 * 60)) / (1000 * 60)
  );

  const returnArrivalTime = new Date(lastReturn0.arrival);
  const returnDepartureTime = new Date(firstReturn0.departure);

  // Calculate the time difference in milliseconds
  const returnTimeDifference = returnArrivalTime - returnDepartureTime;

  // Convert milliseconds to hours and minutes
  const returnHours = Math.floor(returnTimeDifference / (1000 * 60 * 60));
  const returnDays = Math.floor(returnHours / 24);
  const returnMinutes = Math.floor(
    (returnTimeDifference % (1000 * 60 * 60)) / (1000 * 60)
  );

  const { flightType } = useDataContext();
  return (
    <>
      <Card
        key={item.id}
        sx={{
          marginBottom: { xs: "2vh", md: "2vh", lg: "3vh" },
          borderRadius: "1em",
          bgcolor: "#6373CF",
          border: "1px solid #E7E5EB",
          padding: "0 0 0 1em",
        }}
        variant="outlined"
      >
        <Grid container bgcolor="white">
          <Grid
            item
            container
            xs={12}
            md={12}
            lg={8}
            display="flex"
            alignItems="center"
            borderRight="1px dotted #E7E5EB"
          >
            <Grid
              item
              xs={12}
              md={12}
              lg={3}
              padding={{ xs: "2vh 5vw", md: "2vh 2vw", lg: "1vh 2vw" }}
            >
              <img
                src={`https://images.kiwi.com/airlines/32x32/${firstReturn0.airlineName}.png?default=airline.png`}
              />
              {return0Routes?.length > 1 ? (
                <Tooltip
                  placement="right"
                  PopperProps={{
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        backgroundColor: "#FFFFFF !important",
                        boxShadow: "0 3px 5px #787276",
                        padding: "10px 20px !important",
                        display: "flex",
                        alignItems: "center",
                      }
                    }
                  }}
                  title={
                    <div>
                      {return0Routes?.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "5px",
                          }}
                        >
                          <img
                            src={`https://images.kiwi.com/airlines/32x32/${item?.airlineName}.png?default=airline.png`}
                            alt={item.airlineName}
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "5px",
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  }
                >
                  <Typography
                    pt="5px"
                    color="#7F7C88"
                    fontSize="14px"
                    display="flex"
                    alignItems="center "
                  >
                    &#43; Others
                  </Typography>
                </Tooltip>
              ) : ""}
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={9}
              padding="2vh 5vw"
              display="flex"
              alignItems="center"
              justifyContent={{
                xs: "space-between",
                md: "space-between",
                lg: "center",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-ent"
              >
                <Typography
                  color="#201834"
                  textAlign="right"
                  fontFamily="inter"
                  fontSize="26px"
                  fontWeight="600"
                  lineHeight="120%"
                  letterSpacing="-0.26px"
                >
                  {new Date(firstReturn0.departure).toLocaleTimeString([], {
                    hour12: false,
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </Typography>
                <Typography
                  color="#7F7C88"
                  textAlign="right"
                  fontFamily="inter"
                  fontSize="14px"
                  fontWeight="600"
                  lineHeight="140%"
                  letterSpacing="-0.26%"
                >
                  {firstReturn0.flyFrom}
                </Typography>
              </Box>
              <Box padding="0 1vw">
                <Typography
                  textAlign="center"
                  color="#7F7C88"
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="130%"
                >
                  <span style={{ borderBottom: "1px solid #B9B7BD" }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>{" "}
                  {oneWayHours}h{oneWayMinutes}m
                  <span style={{ borderBottom: "1px solid #B9B7BD" }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </Typography>
                <Typography
                  textAlign="center"
                  color="#7F7C88"
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="130%"
                >
                  {return0Routes.length < 1 ? (
                    <span style={{ color: "#34E0A1", fontWeight: 600 }}>
                      Direct
                    </span>
                  ) : (
                    return0Routes.map((item) => {
                      return (
                        <span
                          style={{ color: "#6373CF", fontWeight: "600" }}
                          title={item.toAirport}
                        >
                          {item.flyTo}
                        </span>
                      );
                    })
                  )}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
              >
                <Typography
                  color="#201834"
                  fontFamily="inter"
                  fontSize="26px"
                  fontWeight="600"
                  display="flex"
                  alignItems="flex-start"
                >
                  {new Date(lastReturn0.arrival).toLocaleTimeString([], {
                    hour12: false,
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                  {oneWayDays > 0 ? (
                    <span
                      style={{
                        color: "#201834",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      &#43;{oneWayDays}
                    </span>
                  ) : (
                    ""
                  )}
                </Typography>
                <Typography
                  color="#7F7C88"
                  textAlign="left"
                  fontFamily="inter"
                  fontSize="14px"
                  fontWeight="600"
                  lineHeight="140%"
                  letterSpacing="-0.26%"
                >
                  {lastReturn0.flyTo}
                </Typography>
              </Box>
            </Grid>
            {flightType == true ? (
              ""
            ) : (
              <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={3}
                  padding={{ xs: "2vh 5vw", md: "2vh 2vw", lg: "1vh 2vw" }}
                >
                  <img
                    src={`https://images.kiwi.com/airlines/32x32/${firstReturn1?.airlineName}.png?default=airline.png`}
                  />
                  {return1Routes?.length > 1 ?  (
                    <Tooltip
                      sx={{
                        "& .tooltip": { backgroundColor: "#000 !important" },
                      }}
                      placement="right"
                      arrow
                      title={
                        <div>
                          {return1Routes?.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "5px",
                              }}
                            >
                              <img
                                src={`https://images.kiwi.com/airlines/32x32/${item?.airlineName}.png?default=airline.png`}
                                alt={item.airlineName}
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "5px",
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      }
                    >
                      <Typography
                        pt="5px"
                        color="#7F7C88"
                        fontSize="14px"
                        display="flex"
                        alignItems="center "
                      >
                        &#43; Others
                      </Typography>
                    </Tooltip>
                  ) : ""}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={9}
                  padding="2vh 5vw"
                  display="flex"
                  alignItems="center"
                  justifyContent={{
                    xs: "space-between",
                    md: "space-between",
                    lg: "center",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-ent"
                  >
                    <Typography
                      color="#201834"
                      textAlign="right"
                      fontFamily="inter"
                      fontSize="26px"
                      fontWeight="600"
                      lineHeight="120%"
                      letterSpacing="-0.26px"
                    >
                      {new Date(firstReturn1?.departure).toLocaleTimeString(
                        [],
                        {
                          hour12: false,
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                    </Typography>

                    <Typography
                      color="#7F7C88"
                      textAlign="right"
                      fontFamily="inter"
                      fontSize="14px"
                      fontWeight="600"
                      lineHeight="140%"
                      letterSpacing="-0.26%"
                    >
                      {firstReturn1?.flyFrom}
                    </Typography>
                  </Box>
                  <Box padding="0 1vw">
                    <Typography
                      textAlign="center"
                      color="#7F7C88"
                      fontWeight="400"
                      fontSize="12px"
                      lineHeight="130%"
                    >
                      <span style={{ borderBottom: "1px solid #B9B7BD" }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>{" "}
                      {returnHours}h{returnMinutes}m
                      <span style={{ borderBottom: "1px solid #B9B7BD" }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </Typography>
                    <Typography
                      textAlign="center"
                      color="#7F7C88"
                      fontWeight="400"
                      fontSize="12px"
                      lineHeight="130%"
                    >
                      {return1Routes.length < 1 ? (
                        <span style={{ color: "#34E0A1", fontWeight: 600 }}>
                          Direct
                        </span>
                      ) : (
                        return1Routes.map((item) => {
                          return (
                            <span
                              style={{ color: "#6373CF", fontWeight: "600" }}
                              title={item.toAirport}
                            >
                              {item.flyTo}
                            </span>
                          );
                        })
                      )}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-ent"
                  >
                    <Typography
                      color="#201834"
                      textAlign="right"
                      fontFamily="inter"
                      fontSize="26px"
                      fontWeight="600"
                      lineHeight="120%"
                      letterSpacing="-0.26px"
                    >
                      {new Date(lastReturn1?.arrival).toLocaleTimeString([], {
                        hour12: false,
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </Typography>
                    <Typography
                      color="#7F7C88"
                      fontFamily="inter"
                      fontSize="14px"
                      fontWeight="600"
                      lineHeight="140%"
                      letterSpacing="-0.26%"
                    >
                      {lastReturn1?.flyTo}
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            padding={{ xs: "2vh 5vw", md: "2vh 2vw", lg: "2vh 1vw" }}
          >
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Box>
                <Typography
                  color="#333"
                  fontSize="26px"
                  fontFamily="inter"
                  fontWeight="600"
                  letterSpacing="-0.26px"
                >
                  <AttachMoneyIcon fontSize="small" sx={{ color: "#808080" }} />
                  {item.price}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              mt={{ xs: "40px", md: "40px", lg: "120px" }}
            >
              <Button
                onClick={sendDrawerData}
                variant="outlined"
                sx={{
                  padding: "8px 12px",
                  color: "#73839C",
                  borderRadius: "12px",
                  textTransform: "none",
                  fontWeight: "600",
                }}
              >
                View Details
              </Button>
              <Button
                href={item.deeplink}
                target="_blank"
                variant="contained"
                sx={{
                  textAlign: "center",
                  marginLeft: "10px",
                  padding: "8px 12px",
                  borderRadius: "12px",
                  textTransform: "none",
                  fontWeight: "600",
                  backgroundColor: "#3C50C3",
                }}
              >
                Check Availability
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
