import React, { useState, useContext, useEffect } from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import Flightcard from "./ui-components/Flightcard";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import Pickupcard from "./ui-components/Pickupcard";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LocalHotelIcon from "@mui/icons-material/LocalHotel";
import Resturantcard from "./ui-components/Resturantcard";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddItemModel from "./ui-components/AddItemModel";
import { iitineraryContext } from "../pages/IitineraryContext";
import Cookies from "universal-cookie";
import axios from "axios";

import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import Button from '@mui/material/Button';
import CuisineCard from "./ui-components/CuisineCard";
import FlightDetailDrawer from "./ui-components/FlightDetailDrawer";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: "Bearer " + window.sessionStorage.getItem("token"),
  },
});

export default function Countryactivity() {
  const {
    swapBtnClick,
    planId,
    setPlanId,
    dataChange,
    setDataChange,
    setTravellerData,
    setDestinations,
    flightDetailDrawerState,
    setFlightDetailDrawerState
    ,flightDetails
    ,setFlightDetils
  } = useContext(iitineraryContext);
  const [activityExpand, setExpanded] = useState("panel1");
  const [destinationName, setDestinationName] = useState("");
  const [allFlights, setFlights] = useState("");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


    


  let data = null;

  function getPlanData() {
    api
      .get("/api/plans/" + planId)
      .then((res) => {
        console.log(res.data.data);
        data = res.data.data;
        console.log("data", data);
        setTravellerData(data.tripData);
        setDestinations(data.tripData.destinations);
        setDestinationName(data.selectedFlights[0].name.split("-")[1]);
        setFlights(data.selectedFlights);
      })
      .catch((error) => {
        console.log("error:", error);
      });
  }

  useEffect(() => {
    setPlanId(JSON.parse(localStorage.getItem("planId")));
    if (planId) {
      getPlanData();
    }
  }, [planId]);

  useEffect(() => {
    if (dataChange == true) {
      getPlanData();
      setDataChange(false);
    }
  }, [dataChange]);


  return (
    <>
  <FlightDetailDrawer/>
      {allFlights ? (
        <>
          <Card
            sx={{ padding: 1.3, marginBottom: "1%" }}
            className="countryActivityCard"
          >
            <Accordion
              className="borderless-drop"
              onChange={handleChange("panel2")}
              expanded={activityExpand === "panel2"}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Grid container>
                  <Grid
                    item
                    lg={2}
                    xs={6}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderRight: { xs: 0, lg: "2px solid #D3D3D3" },
                      paddingLeft: 2,
                    }}
                  >
                    <img src="./assets/images/countryflag.png" alt="" />
                    <Typography variant="h5" sx={{ fontWeight: 600, ml: 2 }}>
                      {destinationName}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={10}
                    xs={6}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      paddingLeft: 2,
                    }}
                  >
                    <Typography variant="body1" sx={{ mr: 1, fontWeight: 700 }}>
                      {activityExpand == "panel2" ? "Collapse" : "Expand"}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <hr className="cardDivider" />
              <AccordionDetails sx={{ padding: { xs: 0, lg: "8px 16px" } }}>
                <Timeline position="" sx={{ padding: 0 }}>
                  {allFlights.map((flight) => (
                    <>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{
                            m: "1% 0",
                            flex: 0.11,
                            display: { xs: "none", lg: "block" },
                          }}
                          align="right"
                          variant="body2"
                        >
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            {flight.offer.summary.arrivaleDate.substring(0, 5)}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ fontSize: 12, fontWeight: 600 }}
                          >
                            {" "}
                            {flight.offer.summary.arrivaleDate
                              .substring(
                                11,
                                flight.offer.summary.arrivaleDate.length
                              )
                              .substring(0, 5)}{" "}
                            PDT
                          </Typography>
                          <Typography variant="body2" sx={{ color: "#333333" }}>
                            Friday
                          </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot
                            sx={{
                              backgroundColor: "transparent",
                              padding: 1,
                              mt: 2,
                            }}
                          >
                            <FlightTakeoffIcon
                              sx={{ color: "#6373CF" }}
                              className="timeLineIcon"
                            />
                          </TimelineDot>
                          <div className="mobileTimeContent">
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 600 }}
                            >
                              10/9
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontSize: 12, fontWeight: 600 }}
                            >
                              {" "}
                              {flight.offer.summary.arrivaleDate
                                .substring(
                                  11,
                                  flight.offer.summary.arrivaleDate.length
                                )
                                .substring(0, 5)}{" "}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{ color: "#333333" }}
                            >
                              Friday
                            </Typography>
                          </div>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: 0, px: 2 }}>
                          <Flightcard
                            id={flight.offer.id}
                            name={flight.offer.summary.carrierName}
                            flightNumber={
                              flight.offer.details.segments[0].carrierCode +
                              flight.offer.details.segments[0].number
                            }
                            takeOffTime={flight.offer.summary.arrivaleDate
                              .substring(
                                11,
                                flight.offer.summary.arrivaleDate.length
                              )
                              .substring(0, 5)}
                            landingTime={flight.offer.summary.departureDate
                              .substring(
                                11,
                                flight.offer.summary.departureDate.length
                              )
                              .substring(0, 5)}
                            flightDuration="23h20m"
                            class={flight.offer.summary.cabin}
                            price={flight.offer.summary.grandTotalPrice}
                            departure={flight.offer.summary.departureCity}
                            arrival={flight.offer.summary.arrivalCity}
                            numberOfStops={
                              flight.offer.summary.numberOfStops - 2
                            }
                            flightDetails={flight.segments}
                          />
                        </TimelineContent>
                      </TimelineItem>
                    </>
                  ))}
                </Timeline>
              </AccordionDetails>
            </Accordion>
          </Card>
        </>
      ) : (
        ""
      )}

      <Card
        sx={{ padding: 1.3, marginBottom: "1%" }}
        className="countryActivityCard"
      >
        <Accordion
          className="borderless-drop"
          onChange={handleChange("panel1")}
          expanded={activityExpand === "panel1"}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container>
              <Grid
                item
                lg={2}
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRight: { xs: 0, lg: "2px solid #D3D3D3" },
                  paddingLeft: 2,
                }}
              >
                <img src="./assets/images/countryflag.png" alt="" />
                <Typography variant="h5" sx={{ fontWeight: 600, ml: 2 }}>
                  Madrid
                </Typography>
              </Grid>
              <Grid
                item
                lg={10}
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  paddingLeft: 2,
                }}
              >
                <Typography variant="body1" sx={{ mr: 1, fontWeight: 700 }}>
                  {activityExpand == "panel1" ? "Collapse" : "Expand"}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <hr className="cardDivider" />
          <AccordionDetails sx={{ padding: { xs: 0, lg: "8px 16px" } }}>
            <Timeline position="" sx={{ padding: 0 }}>
              <TimelineItem>
                <TimelineOppositeContent
                  sx={{
                    m: "1% 0",
                    flex: 0.11,
                    display: { xs: "none", lg: "block" },
                  }}
                  align="right"
                  variant="body2"
                >
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    10/9
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: 12, fontWeight: 600 }}
                  >
                    {" "}
                    11:30AM PDT
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#333333" }}>
                    Friday
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{ backgroundColor: "transparent", padding: 1, mt: 2 }}
                  >
                    <FlightTakeoffIcon
                      sx={{ color: "#6373CF" }}
                      className="timeLineIcon"
                    />
                  </TimelineDot>
                  <div className="mobileTimeContent">
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      10/9
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: 12, fontWeight: 600 }}
                    >
                      {" "}
                      11:30AM{" "}
                    </Typography>
                    <Typography variant="caption" sx={{ color: "#333333" }}>
                      Friday
                    </Typography>
                  </div>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: 0, px: 2 }}>
                  <Flightcard
                    id="1"
                    name="JetStar"
                    flightNumber="JT2656"
                    takeOffTime="11:30"
                    landingTime="2:30"
                    flightDuration="23h20m"
                    class="Economy"
                    price="1080"
                    numberOfStops="1"
                    departure="SYD"
                    arrival="ISB"
                  />
                </TimelineContent>
              </TimelineItem>

              <TimelineItem sx={{ mt: 0 }}>
                <TimelineOppositeContent
                  sx={{
                    m: "1% 0",
                    flex: 0.11,
                    display: { xs: "none", lg: "block" },
                  }}
                  align="right"
                  variant="body2"
                >
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    11/9
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: 12, fontWeight: 600 }}
                  >
                    {" "}
                    11:30AM PDT
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#333333" }}>
                    Saturday
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{ backgroundColor: "transparent", padding: 1, mt: 2 }}
                  >
                    <DirectionsCarIcon
                      sx={{ color: "#F2A818" }}
                      className="timeLineIcon"
                    />
                  </TimelineDot>
                  <div className="mobileTimeContent">
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      11/9
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: 12, fontWeight: 600 }}
                    >
                      {" "}
                      11:30AM
                    </Typography>
                    <Typography variant="caption" sx={{ color: "#333333" }}>
                      Saturday
                    </Typography>
                  </div>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: 0, px: 2 }}>
                  <Pickupcard />
                  <Divider className="addItemBar">
                    <IconButton
                      aria-label="delete"
                      onClick={() => swapBtnClick("home")}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Divider>
                </TimelineContent>
              </TimelineItem>

              <TimelineItem sx={{ mt: 0 }}>
                <TimelineOppositeContent
                  sx={{
                    m: "2% 0",
                    flex: 0.11,
                    display: { xs: "none", lg: "block" },
                  }}
                  align="right"
                  variant="body2"
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: 12, fontWeight: 600 }}
                  >
                    {" "}
                    11:30AM PDT
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{ backgroundColor: "transparent", padding: 1, mt: 2 }}
                  >
                    <LocalHotelIcon
                      sx={{ color: "#E44E4E" }}
                      className="timeLineIcon"
                    />
                  </TimelineDot>
                  <div className="mobileTimeContent">
                    <Typography variant="caption" sx={{ fontWeight: 600 }}>
                      {" "}
                      11:30AM{" "}
                    </Typography>
                  </div>

                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: 0, px: 2 }}>
                  <Resturantcard />
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineOppositeContent
                  sx={{
                    m: "auto 0",
                    flex: 0.11,
                    display: { xs: "none", lg: "block" },
                  }}
                  align="right"
                  variant="body2"
                >
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    18-25/9
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ fontWeight: 600, color: "red" }}
                  >
                    2 empty days
                  </Typography>
                </TimelineOppositeContent>

                <TimelineSeparator>
                  <TimelineConnector />

                  <TimelineDot
                    sx={{ backgroundColor: "transparent", padding: 1 }}
                  >
                    <LocationOnIcon
                      sx={{ color: "#C4C4C4" }}
                      className="timeLineIcon"
                    />
                  </TimelineDot>
                  <div className="mobileTimeContent">
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      18-25/9
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ fontWeight: 600, color: "red" }}
                    >
                      2 empty days
                    </Typography>
                  </div>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: 0, px: 2, width: "90%" }}>
                  <Grid container>
                    <Grid
                      item
                      lg={12}
                      xs={12}
                      sx={{ padding: { xs: "10% 0", sm: "6% 0" } }}
                    >
                      <Divider>
                        <IconButton
                          aria-label="delete"
                          onClick={() => swapBtnClick("home")}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </Divider>
                    </Grid>
                  </Grid>
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineOppositeContent
                  sx={{
                    m: "1% 0",
                    flex: 0.11,
                    display: { xs: "none", lg: "block" },
                  }}
                  align="right"
                  variant="body2"
                >
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    10/9
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: 12, fontWeight: 600 }}
                  >
                    {" "}
                    11:30AM PDT
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#333333" }}>
                    Friday
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{ backgroundColor: "transparent", padding: 1, mt: 2 }}
                  >
                    <RestaurantIcon
                      sx={{ color: "#6373CF" }}
                      className="timeLineIcon"
                    />
                  </TimelineDot>
                  <div className="mobileTimeContent">
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      10/9
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: 12, fontWeight: 600 }}
                    >
                      {" "}
                      11:30AM{" "}
                    </Typography>
                    <Typography variant="caption" sx={{ color: "#333333" }}>
                      Friday
                    </Typography>
                  </div>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: 0, px: 2 }}>
                  <CuisineCard />
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </AccordionDetails>
        </Accordion>
      </Card>
    </>
  );
}
