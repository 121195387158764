import React, {
  useEffect,
  useState,
  useRef
} from 'react'
import axios from 'axios';
import VerificationModel from '../../components/ui-components/VerificationModel';

import {
  useLocation
} from "react-router-dom";


export default function VerifyEmail() {
  const [verified, setVerification] = useState("");
  const effectRun=useRef(false)

  const queryString = useLocation().search;
  useEffect(() => {
    if(effectRun.current===false){
    let token = new URLSearchParams(queryString).get('token');
    token = token.replaceAll(" ", "+");
    const urlParams = new URLSearchParams(queryString);
    const email = urlParams.get('email');
    console.log(email);
    console.log(token);
    let data = {
      email: email,
      token: token,
    }
    console.log(data);

    axios.post("https://localhost:7195/api/Account/VerifyConfirmEmail", data).then((res) => {
      if (res.status == 200) {
        setVerification("success");

      }

    }).catch(error => {

      console.log(error.response.data)
      setVerification("error")
    })
  
    return ()=>{
      effectRun.current=true;
    }
  }
  }, []);


  console.log("ver", verified)
  return ( <> {
      verified == "success" ?
      <
      VerificationModel header = "Success"
      color = "green"
      page = "/signin"
      sub = "Your email has been confirmed successfully...!!"
      type = "Sign In" / >
      :
        verified == "error" ? < VerificationModel header = "Invalid Token"
      color = "red"
      page = "/confirmemail"
      sub = "Your token for email confirmation has expired..!!"
      type = "Confirm Email" / >
      : ""
    } </>

  )
}