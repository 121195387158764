import axios from "axios";

const http = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL + "/api",
    // baseURL: "http://localhost:3001",
  
    headers: {
      Authorization: "Bearer " + window.sessionStorage.getItem("token"),
    },
  });
  export default http;