import React, { createContext,useState } from 'react'

export const auths=createContext()

const AuthsContext = ({children}) => {
    const [email,setEmail]=useState('');
  const [password,setPassword]=useState('');
  const [firstname,setFirstName]=useState('');
  const [lastname,setLastName]=useState('');
  const [username,setUserName]=useState('');
  const [confirmpassword,setConfirmPassword]=useState('');
  const [emailborder,setEmailborder]=useState('#98989E')
  const [passwordborder,setPasswordborder]=useState('#98989E')
  const [emailerror,setemailerror]=useState(false);
  const [passerror,setpasserror]=useState(false);
  const [loading,setloading]=useState(false);
  const [responseerror,setResponseError]=useState('');


  return <auths.Provider value={{email,emailerror,passerror,responseerror,firstname,lastname,confirmpassword,username,password,emailborder,passwordborder,loading,setEmail,setPassword,setEmailborder,setPasswordborder,setFirstName,setLastName,setUserName
  ,setemailerror,setpasserror,setResponseError,setConfirmPassword,setloading}}>{children}</auths.Provider>;
};

export default AuthsContext
