import React,{useState,useContext} from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import AddItemOptions from './AddItemOptions';
import AddExperience from './AddExperience';
import "../../pages/Iitinerary/scss/Iitinerary.css";
import SwapAccomodation from './SwapAccomodation';
import SwapFlight from './SwapFlight';
import AddAccomodation from "./AddAccomodation";
import { iitineraryContext } from '../../pages/IitineraryContext';
import AccomodationDetail from './AccomodationDetail';
import ChangeDate from './ChangeDate';
import ChangeTravling from './ChangeTravling';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function ChangeInfoModel() {
  
  const{changeinfotoggle,closeChangeInfo,displayPage,changePage,setdisplayPage}=useContext(iitineraryContext)


  return (
    <>
       
       <Dialog
        open={changeinfotoggle}
        TransitionComponent={Transition}
        onBackdropClick={()=>closeChangeInfo()}
        keepMounted
       className='changeInfoModel'
        aria-describedby="alert-dialog-slide-description"
      >

        <DialogContent >
          {displayPage=="changedate"?<ChangeDate/>:""}
          {displayPage=="changetraveling"?<ChangeTravling/>:""}
         
          {/* <AccomodationDetail/> */}

        </DialogContent>
      
      </Dialog>
    </>
  )
}
