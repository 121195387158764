import React, { useState, useContext, useEffect } from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import { iitineraryContext } from "../../pages/IitineraryContext";

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';

export default function FlightDetailDrawer() {
  const [anchorType,setAnchorType]=useState("right");
  const {
      flightDetailDrawerState,
      setFlightDetailDrawerState
      ,flightDetails
      ,setFlightDetils
     
    } = useContext(iitineraryContext);


  window.onresize = reportWindowSize;
 
  useEffect(()=>{
    if(window.innerWidth<=760){
      setAnchorType("bottom")
    }
  
  })
 
  function reportWindowSize() {
   console.log( window.innerHeight);
   console.log( window.innerWidth);
    if(window.innerWidth<=760){
      setAnchorType("bottom")

    }
    else{
      setAnchorType("right")

    }
  }




  return (
    <>
    <SwipeableDrawer
      anchor={anchorType}
      open={flightDetailDrawerState}
      className="flightDetailDrawer"
     
    >

<Card className="flightDetailCard">

<Grid container>
  <Grid item lg={11} xs={10} className="flightHeading">
    <Typography variant="h6" >Flight Details</Typography>
  </Grid>
  <Grid item lg={1} xs={2} className="closeBtn">
  <IconButton aria-label="delete" onClick={()=>setFlightDetailDrawerState(!flightDetailDrawerState)}>
  <CloseIcon />
</IconButton>
</Grid>
</Grid>

<Grid container sx={{padding:"3%"}}>
  <Grid item lg={12} xs={12} sm={12}>

<Typography variant="button flightName">Sydney to Madrid</Typography>
<Typography variant="subtitle2" className="flightday">Thursday, Jan 12 2023</Typography>

<Timeline   sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.3,
        },
      }}
      className="timeLine"
      >
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          21:45
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot className="startDot"/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="body1 cityName">Sydney</Typography>
          
          <Typography variant="body2">Sydney International Airport</Typography>


        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent >
          <Typography variant="body2" className="grayText">14h 05m</Typography>
         
        </TimelineOppositeContent>
        <TimelineSeparator sx={{flex:0.085}}>
          
       
          <TimelineConnector />
          
        </TimelineSeparator>
        <TimelineContent>
          <Grid container spacing={1}>
            <Grid item lg={2.5} xs={3}>
              <img src="./assets/images/airlinelogos/AAA.png" class="img-fluid rounded-top" alt="" className="airlinePic"/>
            </Grid>
            <Grid item lg={9.5} xs={9}>
            <Typography variant="body2">JT2656 - Airbus A380-800</Typography>
          <Typography variant="caption"  className="grayText">Operated by Jetstar Airways</Typography>

            </Grid>
          </Grid>
         
        </TimelineContent>
      </TimelineItem>

      
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          05:15
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot className="endDot"/>
          
        </TimelineSeparator>
        <TimelineContent>
        <Typography variant="body1 cityName">Dubai</Typography>
          
          <Typography variant="body2">Dubai International Airport</Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
    </Grid>
</Grid>

<Grid container-fluid className="stay">
  <Grid item lg={12}>
    <Typography variant="body2">2 hrs transit in Dubai</Typography>
    <Typography variant="subtitle2 boldText">Dubai International Airport</Typography>
  </Grid>
</Grid>

<Grid container sx={{padding:"3%"}}>
  <Grid item lg={12} sm={12}>
<Typography variant="subtitle2" className="flightday">Thursday, Jan 12 2023</Typography>

<Timeline   sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.3,
        },
      }}
      className="timeLine"
      >
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          21:45
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot className="startDot"/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="body1 cityName">Sydney</Typography>
          
          <Typography variant="body2">Sydney International Airport</Typography>


        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent >
          <Typography variant="body2" className="grayText">14h 05m</Typography>
         
        </TimelineOppositeContent>
        <TimelineSeparator sx={{flex:0.085}}>
          
       
          <TimelineConnector />
          
        </TimelineSeparator>
        <TimelineContent>
          <Grid container spacing={1}>
            <Grid item lg={2.5} xs={3}>
              <img src="./assets/images/airlinelogos/AAA.png" class="img-fluid rounded-top" alt="" className="airlinePic"/>
            </Grid>
            <Grid item lg={9.5} xs={9}>
            <Typography variant="body2">JT2656 - Airbus A380-800</Typography>
          <Typography variant="caption"  className="grayText">Operated by Jetstar Airways</Typography>

            </Grid>
          </Grid>
         
        </TimelineContent>
      </TimelineItem>

      
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          05:15
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot className="endDot"/>
          
        </TimelineSeparator>
        <TimelineContent>
        <Typography variant="body1 cityName">Dubai</Typography>
          
          <Typography variant="body2">Dubai International Airport</Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
    </Grid>
</Grid>
</Card>
    </SwipeableDrawer>
      
    </>
  )
}
