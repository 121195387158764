import queryString from "query-string";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const useQueryParams = () => {
  const { search } = useLocation();
  const params = useMemo(() => {
    const parsedParams = queryString.parse(search);
    convertStringNumbersToNumbers(parsedParams);
    return parsedParams;
  }, [search]);
  return params;
};


const convertStringNumbersToNumbers = (obj) => {
  for (const key in obj) {
    if (typeof obj[key] === "string") {
      if (!isNaN(+obj[key]) && obj[key].trim() === obj[key]) {
        obj[key] = +obj[key];
      }
    } else if (typeof obj[key] === "object") {
      convertStringNumbersToNumbers(obj[key]);
    }
  }
};
export default useQueryParams;