import { React, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import "./scss/Signup.css";
import { useContext } from "react";
import { auths } from "./AuthsContext";
import axios from "axios";
import ErrorIcon from "@mui/icons-material/Error";
import VerificationModel from "../../components/ui-components/VerificationModel";
export default function Signup() {
  const {
    firstname,
    lastname,
    username,
    password,
    confirmpassword,
    email,
    emailborder,
    emailerror,
    passwordborder,
    responseerror,
    loading,
    setResponseError,
    setEmail,
    setloading,
    setPassword,
    setemailerror,
    setPasswordborder,
    setConfirmPassword,
    setFirstName,
    setLastName,
    setUserName,
  } = useContext(auths);

  const [fnerror, setfnerror] = useState(false);
  const [lnerror, setlnerror] = useState(false);
  const [unerror, setunerror] = useState(false);
  const [unerrormsg, setunerrormsg] = useState("Username is required");
  const [emerror, setemerror] = useState(false);
  const [emerrormsg, setemerrormsg] = useState("Please enter a Valid email");
  const [pserror, setpserror] = useState(false);
  const [cpserror, setcpserror] = useState(false);
  const [mterror, setmterror] = useState(false);

  const [validateerror, setvalidateerror] = useState(false);

  const [mailsent,setMailsent]=useState(false)

  const validateemail = (value) => {
    value.toLowerCase();
    let re = value.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    if (re != null) {
      setEmail(value);
      setemerror(false);
      setvalidateerror(false);
    } else {
      setvalidateerror(true);
      setemerrormsg("Please enter a valid Email");
    }
  };

  const error = (value) => () => {
    if (value == "email") {
     
      return "red !important";
    }
  };

  const handlefn = (val) => {
    if (val == "") {
      setfnerror(true);
      setFirstName(val);
    } else {
      setfnerror(false);
      setFirstName(val);
    }
  };

  const handleln = (val) => {
    if (val == "") {
      setlnerror(true);
      setLastName(val);
    } else {
      setlnerror(false);
      setLastName(val);
    }
  };

  const handleun = (val) => {
    if (val == "") {
      setunerror(true);
      setUserName(val);
      setunerrormsg("UserName is required");
    } else {
      setunerror(false);
      setUserName(val);
    }
  };


  const handleps = (val) => {
    checkpass(val);
    if (val == "") {
      setpserror(true);
      setPassword(val);
    } else if (checkpass(val)) {
      setpserror(true);
      setPassword(val);
    } else {
      setpserror(false);
      setPassword(val);
    }
  };

  const checkpass = (val) => {
    let r = val.match(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
    );
    if (r == null) {
      return true;
    } else {
      return false;
    }
  };
  
  const handlematch = () => {

    if (password != "" && password != confirmpassword) {
      setmterror(true);
    } else {
      setmterror(false);
    }
  };

  const validateInputs = () => {
    if (
      emerror ||
      pserror ||
      unerror ||
      fnerror ||
      lnerror ||
      cpserror ||
      mterror
    ) {

      return false;
    } else {
      return true;
    }
  };

  const submit = () => {
    let data = {
      firstName: firstname,
      lastName: lastname,
      userName: username,
      email: email,
      password: password,
      gender: "male",
      phoneNumber: "0123",
      dateOfBirth: "2022-08-25T19:35:33.232Z",
    };
    
    setloading(true);

    if (validateInputs()) {
      axios
        .post(`https://localhost:7195/api/Account/Register`, data)
        .then((res) => {
          if (res.status == 200) {
           
            setloading(false);
            setMailsent(true)
          }
        })
        .catch((error) => {
          setloading(false);
          
          if (error.response.data.Messages) {
            setResponseError("All fields are require");
          } else {
            setResponseError("");
          }
          if (error.response.data.message.includes("Passwords")) {
            setpserror(true);
          } else {
            setpserror(false);
          }
          if (error.response.data.message == "Email is already taken") {
            setemerror(true);
            setemerrormsg("Email is already taken");
          } else {
            setemerror(false);
          }
          if (error.response.data.message == "Username is already taken") {
            setunerror(true);
            setunerrormsg("Username is already taken");
          } else {
            setunerror(false);
          }
        });
    } else {
  
    }
  };

  return (
    <>
    {mailsent?<><VerificationModel header="Mail Sent" sub="We have sent a confirmation email.Please check your inbox..!!" color="green" type=""/></>:
    <>
      <div className="form">
        <Typography
          variant="h5"
          sx={{ fontWeight: 700, marginTop: 0.8, marginBottom: 1 }}
        >
          Sign up to get full access
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 500, color: "#98989E" }}>
          Make trip planning easier and enjoy all of our features.
        </Typography>

        <FormControl sx={{ mt: 3, mb: 1 }} variant="outlined">
          <label className="input-label">First Name</label>
          <OutlinedInput
            required
            className="input"
            id="filled-adornment-amount"
            startAdornment={
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            }
            placeholder="e.g.:Elon"
            sx={{ borderColor: passwordborder }}
            onChange={(e) => handlefn(e.target.value)}
            value={firstname}
          />
          {fnerror ? (
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: "red", mt: 1.5 }}
            >
              First Name is required.
            </Typography>
          ) : (
            ""
          )}
        </FormControl>

        <FormControl sx={{ mt: 1, mb: 1 }} variant="outlined">
          <label className="input-label">Last Name</label>
          <OutlinedInput
            required
            className="input"
            id="filled-adornment-amount"
            startAdornment={
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            }
            placeholder="e.g.: Musk"
            sx={{ borderColor: passwordborder }}
            onChange={(e) => handleln(e.target.value)}
            value={lastname}
          />
          {lnerror ? (
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: "red", mt: 1.5 }}
            >
              Last Name is required.
            </Typography>
          ) : (
            ""
          )}
        </FormControl>

        <FormControl sx={{ mt: 1, mb: 1 }} variant="outlined">
          <label className="input-label">User Name</label>
          <OutlinedInput
            className="input"
            id="filled-adornment-amount"
            startAdornment={
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            }
            placeholder="e.g.:Elon-Musk"
            sx={{ borderColor: passwordborder }}
            onChange={(e) => handleun(e.target.value)}
            value={username}
          />
          {unerror ? (
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: "red", mt: 1.5 }}
            >
              {unerrormsg}
            </Typography>
          ) : (
            ""
          )}
        </FormControl>

        <FormControl sx={{ mt: 3, mb: 1 }} variant="outlined">
          <label className="input-label">E-Mail</label>
          <OutlinedInput
            className="input"
            id="filled-adornment-amount"
            startAdornment={
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            }
            placeholder="e.g.: elonmusk@mars.com "
            sx={{ borderColor: validateerror ? error("email") : emailborder }}
            onChange={(e) => validateemail(e.target.value)}
          />
          {validateerror || emerror ? (
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: "red", mt: 1.5 }}
            >
              {emerrormsg}
            </Typography>
          ) : (
            ""
          )}
        </FormControl>

        <FormControl sx={{ mt: 1, mb: 1 }} variant="outlined">
          <label className="input-label">Password</label>
          <OutlinedInput
            className="input"
            id="filled-adornment-amount"
            startAdornment={
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            }
            placeholder="e.g.: X Æ A-12"
            sx={{ borderColor: passwordborder }}
            onChange={(e) => handleps(e.target.value)}
            onBlur={() => handlematch()}
            type="password"
          />
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, color: "red", mt: 1.5 }}
          >
            {pserror ? (
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, color: "red", mt: 1.5 }}
              >
                Passwords must be at least 8 characters.Passwords must have at
                least one non alphanumeric character.Passwords must have at
                least one digit ('0'-'9').Passwords must have at least one
                uppercase ('A'-'Z').
              </Typography>
            ) : (
              ""
            )}
          </Typography>
        </FormControl>

        <FormControl sx={{ mt: 1, mb: 1 }} variant="outlined">
          <label className="input-label">Confirm Password</label>
          <OutlinedInput
            className="input"
            id="filled-adornment-amount"
            startAdornment={
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            }
            placeholder="e.g.: X Æ A-12"
            sx={{ borderColor: passwordborder }}
            onChange={(e) => setConfirmPassword(e.target.value)}
            onBlur={() => handlematch()}
            value={confirmpassword}
            type="password"
          />
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, color: "red", mt: 1.5 }}
          >
            {cpserror || mterror ? (
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, color: "red", mt: 1.5 }}
              >
                {cpserror
                  ? "Confirm Password is required"
                  : "Confirm Password does not match."}
              </Typography>
            ) : (
              ""
            )}
          </Typography>
        </FormControl>

        <Button
          variant="contained"
          className="btn-primary"
          sx={{ mt: 3 }}
          onClick={submit}
          disabled={loading}
        >
          Sign Up
        </Button>
        <Typography
          variant="h6"
          sx={{ color: "red", mt: 2, display: "flex", alignItems: "center" }}
        >
          {responseerror != "" ? (
            <>
              <ErrorIcon sx={{ mr: 1 }} />
              <Typography variant="h6">{responseerror}</Typography>
            </>
          ) : (
            ""
          )}
        </Typography>

        <Grid container>
          <Grid
            item
            lg={12}
            sx={{ display: "flex", flexDirection: "row", mt: 2 }}
          >
            <Typography variant="body2" sx={{ color: "#98989E" }}>
              Already a member?{" "}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                ml: 1,
                color: " #212429",
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              Sign in Here <EastIcon sx={{ fontSize: "1rem", ml: 0.5 }} />
            </Typography>
          </Grid>
        </Grid>

        <Grid container sx={{ mt: "20%" }}>
          <Grid item lg={12}>
            <Typography variant="body1" className="catch-info">
              © Catch U There 2022
            </Typography>
            <Typography variant="body1" className="catch-info">
              1A/2 Resolution Drive, Caringbah NSW 2229
            </Typography>
            <Typography variant="body1" className="catch-info">
              Australia
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Grid container sx={{ mt: "10%", mb: "2%" }}>
        <Grid item lg={12}>
          <Typography variant="body1" className="catch-info">
            By signing in or creating an account, you agree with our{" "}
            <u>Terms & conditions</u> and <u>Privacy policy</u>
          </Typography>
        </Grid>
      </Grid>
    </>
            }
            </>
  );
}
