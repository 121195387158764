import React,{useContext, useEffect, useState} from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Button from '@mui/material/Button';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { iitineraryContext } from "../../pages/IitineraryContext";

import { Stack } from '@mui/system';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LockIcon from '@mui/icons-material/Lock';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import axios from "axios";
export default function CuisineCard() {
  return (
    <>
      <Card className="cuisineCard">
        <Grid container>
        <Grid item lg={2.3} xs={12}>
          <div className='cardImg'>
            <img src="./assets/images/resturant.png" alt="" />
            </div>
        </Grid>
        <Grid item lg={5.5} xs={12} sx={{ml:2}}className="card-body">
            <Grid container sx={{dislpay:"flex",mb:1}}>
                <Grid item xs={7} sx={{width:"auto",mr:1}}>
                <Typography variant="h6" sx={{fontWeight:600,display:"inline"}}>Roma Cappucia Hotel</Typography>
                </Grid>
                <Grid item xs={4} sx={{display:"flex",alignItems:"center"}}>
               
                    <div className='notify'>8.5</div>
                </Grid>
            </Grid>
           
            <Typography variant="body2" sx={{mb:2}}>13 Madrid Road, Madrid, 20175, Spain</Typography>
            <Grid container sx={{mb:1}}>
                <Grid item lg={4.5}>
                <Button className="card-icons" variant="text" startIcon={<DirectionsWalkIcon />}>
                Close to hotel
      </Button>
                </Grid>
                
            </Grid>
        </Grid>

        <Grid item lg={3.9} xs={12} className="card-body">
            <Grid container className="pricing">
            <Grid item lg={6}>
                 </Grid>
                <Grid item lg={7} xs={12} sx={{display:"flex",flexDirection:"column",justifyContent:"flex-end"}}>
                <Typography variant="h4" sx={{fontWeight:700,display:"flex",float:"right",justifyContent:"end"}}> <Typography variant="body1" sx={{fontSize:20,fontWeight:700,color:"#808080"}}>$</Typography>2050</Typography>
                <Typography variant="body1" sx={{fontSize:12,color:"#808080",textAlign:"right"}}>Avg. spend for 3 people</Typography>
                </Grid>
          
            </Grid>
            
            <Grid container sx={{mt:3,pl:{lg:1,sm:"7%"},display:"flex",justifyContent:"space-between"}}>
           
                <Grid item lg={5} sm={6} xs={5.5}>
                <Button sx={{ml:1}}variant="outlined" className="viewBtns">View details</Button>
                </Grid>
                
                <Grid item lg={7} sm={5} xs={6.5} className="adjustToEnd" sx={{textAlign:"right"}}>
                <Button sx={{fontSize:9,fontWeight:700,padding:0}}variant="text"  className="textBtns">Swap experience</Button>
                </Grid>
            </Grid>
        </Grid>


        </Grid>

      </Card>
    </>
  )
}
