import { LocalizationProvider } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  Select,
  Slider,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Calendar, DateRange } from "react-date-range";
import Checkbox from "@mui/material/Checkbox";
import { format, setDate, startOfMonth } from "date-fns";
import axios from "axios";
import { cityContext } from "../../App";
import { CityContext } from "../../pages/Hotels-list/HotelsList";
import ProfileDrawer from "./Profiledrawer";
import MainNavDrawer from "./navigation/MainNavDrawer";
import Navbar from "../../components/ui-components/Navbar";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import CloseIcon from "@mui/icons-material/Close";
import PaidIcon from "@mui/icons-material/Paid";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import { FlightTakeoff } from "@mui/icons-material";
import TodayIcon from "@mui/icons-material/Today";
import DoneIcon from "@mui/icons-material/Done";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import HotelsSearchbar from "./HotelsSearchbar";
import { useDataContext } from "../../vendors/Contexts";
import useQueryParams from "../../utils/hooks/useQueryParams";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  //baseURL: "http://localhost:3001",

  headers: {
    Authorization: "Bearer " + window.sessionStorage.getItem("token"),
  },
});

export default function BookingModel(props) {
  const params = useQueryParams();
  const [isLocationDrawerOpen, setIsLocationDrawerOpen] = useState(false);
  const [isModalDrawerOpen, setIsModalDrawerOpen] = useState(false);
  const [isCalendarDrawerOpen, setIsCalendarDrawerOpen] = useState(false);
  const [isPassengerDrawerOpen, setIsPassengerDrawerOpen] = useState(false);
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [singleDate, setSingleDate] = useState(new Date());
  const [oneWay, setOneWay] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const currentSearchParams = new URLSearchParams(window.location.search);
  const dateFrom = searchParams.get("dateFrom");
  const dateTo = searchParams.get("dateTo");
  const adults = searchParams.get("adults");
  const children = searchParams.get("children");
  const rooms = searchParams.get("rooms");

  // popover code
  const [popoverDateAnchor, setDatePopoverAnchor] = useState(null);
  const [popoverGuestAnchor, setGuestPopoverAnchor] = useState(null);
  const [departureCity, setDepartureCity] = useState();
  const [arrivalCity, setArrivalCity] = useState();
  const {
    date,
    updateDate,
    setDate,
    passengers,
    setPassengers,
    flightType,
    getFlightType,
    cityName,
    cityCode,
    setCityName,
    setCityCode,
    cityName2,
    cityCode2,
    setCityName2,
    setCityCode2,
    updatedCityName2,
  } = useDataContext();

  const {
    currentPage,
    getCity,
    handleFlightSearch,
    getDepartureCityName,
    getArrivalCityName,
    onButtonClick,
  } = props;
  const sendFlightType = (e) => {
    getFlightType(e);
  };
  const handleDepartureCity = (event) => {
    setDepartureCity(event);
    getDepartureCityName(event);
  };
  const handleArrivalCity = (event) => {
    setArrivalCity(event);
    getArrivalCityName(event);
  };
  const handleDatePopover = (event) => {
    setDatePopoverAnchor(event.currentTarget);
  };
  const handleGuestPopover = (event) => {
    setGuestPopoverAnchor(event.currentTarget);
  };

  const handleDatePopoverClose = () => {
    setDatePopoverAnchor(null);
    updateDate(dates);
  };
  const handleGuestPopoverClose = () => {
    setGuestPopoverAnchor(null);
  };

  const openDatePopover = Boolean(popoverDateAnchor);
  const openGuestPopover = Boolean(popoverGuestAnchor);
  const datePopoverId = openDatePopover ? "simple-popover-date" : undefined;
  const guestPopoverId = openGuestPopover ? "simple-popover-guest" : undefined;

  // Fetching countries and cityNames
  let [countries, setCountries] = useState([]);
  const cNames = axios.create({
    baseURL: "https://localhost:7195/api",
    headers: {
      Authorization: "Bearer " + window.sessionStorage.getItem("token"),
    },
  });

  // Make a GET request to fetch data from the API
  useEffect(() => {
    cNames
      .get("/Cities")
      .then((response) => {
        // The data from the API response will be available in the 'data' property of the response object
        const data = response.data.data.data;
        setCountries(data);
        // mapping countries
      })
      // Process the data or update the UI with the retrieved information
      .catch((error) => {
        // Handle any errors that occur during the request
        console.error("Error fetching data:", error);
      });
  }, []);

  let isLargeScreen = useMediaQuery("(min-width:992px)");
  let isMediumScreen = useMediaQuery("(min-width:768px)");

  let navigate = useNavigate();

  let handleSubmit = () => {
    // setCitySearchValue()
    currentPage == "flights"
      ? navigate(
          `/flightslist?FlyFrom=${cityCode}&FlyTo=${cityCode2}&dateFrom=${format(
            date[0].startDate,
            "yyyy-MM-dd"
          )}${
            flightType === false
              ? `&dateTo=${format(date[0].endDate, "yyyy-MM-dd")}`
              : ""
          }&adults=${passengers.adults}&infants=${
            passengers.infants
          }&children=${passengers.children}`
        )
      : navigate(
          `/hotelslist?FlyFrom=${cityCode}&FlyTo=${cityCode2}&dateFrom=${format(
            date[0].startDate,
            "yyyy-MM-dd"
          )}${
            flightType === false
              ? `&dateTo=${format(date[0].endDate, "yyyy-MM-dd")}`
              : ""
          }&adults=${passengers.adults}&infants=${
            passengers.infants
          }&children=${passengers.children}&rooms=${passengers.rooms}`
        );
  };
  const [dateSelected, setDateSelected] = useState(false);
  const [alldestinations, setDestinations] = useState([
    { city: "", country: "", minStayDuration: 1, maxStayDuration: 2 },
  ]);
  const [flexible, setflexible] = useState(false);
  const [flexibledays, setflexibledays] = useState("0");
  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 6)),
      key: "selection",
    },
  ]);
  // dates try
  const inputDate = new Date();
  const numberOfDaysToAdd = 357;
  const resultDate = new Date(inputDate);

  resultDate.setDate(inputDate.getDate() + numberOfDaysToAdd);

  // Now you can use resultDate as the maxDate in your DateRange component

  const [expanded, setExpanded] = useState(false);

  const datesoption = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const adultoption = (panel) => (event, isExpanded) => {
    setadultExpanded(isExpanded ? panel : false);
  };

  const updateflexibility = () => {
    if (flexible) {
      setflexible(false);
      setflexibledays("");
    } else {
      setflexible(true);
    }
  };
  const handleFlexibleDays = (days) => {
    setflexibledays(days);
  };

  const [destinationsExpands, setdestinationExpand] = useState([false]);

  const [departure, setdeparture] = useState("");
  const [departurError, setDepartureError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [destinationError, setDestinationError] = useState([false]);

  const [loading, setLoading] = useState(false);
  const [adultexpanded, setadultExpanded] = useState(false);

  const validateInputs = () => {
    if (departure == "") {
      setDepartureError(true);
      return false;
    }
    if (format(dates[0].startDate, "PP") === format(dates[0].endDate, "PP")) {
      setDateError(true);
      return false;
    } else {
      setDateError(false);
    }
    let hasInvalidDestination = false;
    let destinations = [...alldestinations];
    let destinationerrors = [...destinationError];
    destinations.forEach((element, index) => {
      if (element.city == "") {
        destinationerrors[index] = true;
        hasInvalidDestination = true;
      }
    });
    if (hasInvalidDestination) {
      setDestinationError(destinationerrors);
      return false;
    }

    return true;
  };

  const handledestinationExpandeds = (index) => () => {
    let destinations = [...destinationsExpands];

    if (destinationsExpands[index] == true) {
      destinations[index] = false;
      setdestinationExpand(destinations);
    } else {
      destinations[index] = true;
      setdestinationExpand(destinations);
    }
  };

  const updateSearchParams = async (key, value) => {
    return new Promise((resolve, reject) => {
      try {
        const updatedSearchParams = new URLSearchParams(window.location.search);
  
        if (value === null || value === undefined) {
          updatedSearchParams.delete(key);
        } else {
          updatedSearchParams.set(key, value);
        }
  
        const newUrl = `${window.location.pathname}?${updatedSearchParams.toString()}`;
        window.history.replaceState(null, '', newUrl);
  
        setSearchParams(updatedSearchParams.toString());
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const handledestinationcountry = (value, country, index) => {
    const values = [...alldestinations];
    values[index].city = value;
    values[index].country = country;

    if (destinationError[index] == true) {
      const error = [...destinationError];
      error[index] = false;
      setDestinationError(error);
    }
    setDestinations(values);
  };

  const [passengerSelected, setPassengerSelected] = useState(false);
  // const [passengers, setPassengers] = useState({
  //   adults: 2,
  //   children: 0,
  //   infants: 0,
  //   rooms: 1,
  // });

  // passenger, rooms counter functions

  const minusfrompassenger = (value) => () => {
    if (value == "room") {
      let rooms = passengers.rooms - 1;
      if (rooms >= 0) {
        setPassengers({ ...passengers, rooms: rooms });
        updateSearchParams("rooms", rooms);
      }
    } else if (value == "adult") {
      let adult = passengers.adults - 1;
      if (adult >= 0) {
        setPassengers({ ...passengers, adults: adult });
        updateSearchParams("adults", adult);
      }
    } else if (value == "infants") {
      let infant = passengers.infants - 1;
      if (infant >= 0) {
        setPassengers({ ...passengers, infants: infant });
        updateSearchParams("infants", infant);
      }
    } else {
      let child = passengers.children - 1;
      if (child >= 0) {
        setPassengers({ ...passengers, children: child });
        updateSearchParams("children", child);
      }
    }
  };
  const addtopassenger = (value) => () => {
    if (value == "room") {
      let rooms = passengers.rooms + 1;
      if (passengers.rooms < 10) {
        setPassengers({ ...passengers, rooms: rooms });
        updateSearchParams("rooms", rooms);
      }
    } else if (value == "adult") {
      let adult = passengers.adults + 1;
      if (passengers.adults < 10) {
        setPassengers({ ...passengers, adults: adult });
        updateSearchParams("adults", adult);
      }
    } else if (value == "infants") {
      let infant = passengers.infants + 1;
      if (passengers.infants < 10) {
        setPassengers({ ...passengers, infants: infant });
        updateSearchParams("infants", infant);
      }
    } else {
      let child = passengers.children + 1;
      if (passengers.children < 10) {
        setPassengers({ ...passengers, children: child });
        updateSearchParams("children", child);
      }
    }
  };

  const submitdata = () => {
    //if(window.sessionStorage.getItem("token")!=null)

    if (true) {
      let from = departure.split(",");
      if (validateInputs()) {
        let data = {
          name: "dummy",
          startLocation: { city: from[0], country: from[1] },
          startDate: format(dates[0].startDate, "yyyy-MM-dd"),
          endDate: format(dates[0].endDate, "yyyy-MM-dd"),
          daysFlexibility: parseInt(flexibledays),
          destinations: alldestinations,
          tripGuestDetail: {
            numberOfAdults: passengers.adults,
            numberOfChildren: passengers.children,
            numberOfRooms: passengers.rooms,
          },
        };

        setLoading(true);
        api
          .post("/api/plans", data)
          .then((res) => {
            setLoading(false);
            localStorage.setItem(
              "planId",
              JSON.stringify(res.data.data.temporaryPlanId)
            );
            localStorage.setItem("planDetail", JSON.stringify(data));
            window.location.href = "/itinerary";
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    }
  };
  const convertMsToDays = (ms) => {
    const msInOneSecond = 1000;
    const secondsInOneMinute = 60;
    const minutesInOneHour = 60;
    const hoursInOneDay = 24;

    const minutesInOneDay = hoursInOneDay * minutesInOneHour;
    const secondsInOneDay = secondsInOneMinute * minutesInOneDay;
    const msInOneDay = msInOneSecond * secondsInOneDay;

    return Math.ceil(ms / msInOneDay);
  };

  const handeldestinationrange = (e, index, value) => {
    const values = [...alldestinations];
    values[index].minStayDuration = value[0];
    values[index].maxStayDuration = value[1];
    setDestinations(values);
  };
  const handledeparture = (event) => {
    if (departurError == true) {
      setDepartureError(false);
    }
    setdeparture(event.target.value);
  };

  const removedestination = (index) => () => {
    let length = [...alldestinations].length;
    let data = [...alldestinations];
    if (length > 1) {
      data.splice(index, 1);
      setDestinations(data);
    }
  };

  // search value handeling

  let [citySearchValue, setCitySearchValue] = useState();
  let handleCitySearchValue = (event, newValue) => {
    getCity(event);
  };

  // dateRange code

  const toggleLocationDrawer = () => {
    setIsLocationDrawerOpen(!isLocationDrawerOpen);
  };
  const toggleModalDrawer = () => {
    setIsModalDrawerOpen(!isModalDrawerOpen);
  };
  const toggleCalendarDrawer = () => {
    setIsCalendarDrawerOpen(!isCalendarDrawerOpen);
  };
  const togglePassengerDrawer = () => {
    setIsPassengerDrawerOpen(!isPassengerDrawerOpen);
  };

  const handleSingleDateChange = (date) => {
    setSingleDate(date);
    updateSearchParams("dateFrom", format(date, "yyyy-MM-dd"));
  };

  const handleDualDateChange = (item) => {
    const updatedSearchParams = new URLSearchParams(
      currentSearchParams.toString()
    );
    updatedSearchParams.set(
      "dateTo",
      format(item.selection.endDate, "yyyy-MM-dd")
    );
    updatedSearchParams.set(
      "dateFrom",
      format(item.selection.startDate, "yyyy-MM-dd")
    );
    setSearchParams(updatedSearchParams.toString());
  };

  const calendar = [
    flightType == true ? (
      <Calendar
        date={singleDate}
        months={1}
        minDate={new Date()}
        direction="horizontal"
        onChange={handleSingleDateChange}
        color="#3546AB"
      />
    ) : (
      <DateRange
        showMonthAndYearPickers={false}
        editableDateInputs={false}
        showDateDisplay={false}
        sx={{ width: "100%" }}
        onChange={(item) => {
          setDates([item.selection]);
          updateDate([item.selection]);
          handleDualDateChange(item);
        }}
        moveRangeOnFirstSelection={false}
        minDate={new Date()}
        ranges={dates}
        months={isLargeScreen ? 2 : 1}
        direction="horizontal"
        rangeColors={["#D1E5F4"]}
      />
    ),
  ];
  
  const swapDestinations = async () => {
    let tempName = cityName;
    let tempName2 = cityName2;
    let tempCode = cityCode;
    let tempCode2 = cityCode2;
  
    setCityName(tempName2);
    setCityName2(tempName);
    setCityCode(tempCode2);
    setCityCode2(tempCode);
  
    await new Promise(resolve => setTimeout(resolve, 0)); // Wait for the state to update
  
    await updateSearchParams("FlyFrom", tempCode2);
    await updateSearchParams("FlyTo", tempCode);
  };
  const passengerCounter = [
    <div>
      <Grid container>
        <Grid item lg={12} xs={12}>
          <div className="people">
            <Grid container sx={{ display: "flex" }}>
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" component="h6">
                  Adults
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <button className="minus" onClick={minusfrompassenger("adult")}>
                  -
                </button>
                <h3 style={{ width: "20px", minWidth: "20px" }}>
                  {passengers.adults}
                </h3>
                <button className="plus" onClick={addtopassenger("adult")}>
                  +
                </button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item lg={12} xs={12}>
          <div className="people">
            <Grid container sx={{ display: "flex" }}>
              <Grid item xs={6}>
                <Typography variant="h6" component="h6">
                  Children
                </Typography>
                <Typography variant="caption" component="h2">
                  Under 11
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <button
                  className="minus"
                  onClick={minusfrompassenger("children")}
                >
                  -
                </button>
                <h3 style={{ width: "20px", minWidth: "20px" }}>
                  {passengers.children}
                </h3>
                <button className="plus" onClick={addtopassenger("children")}>
                  +
                </button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item lg={12} xs={12}>
          <div className="people">
            <Grid container sx={{ display: "flex" }}>
              <Grid item xs={6}>
                <Typography variant="h6" component="h6">
                  Infants
                </Typography>
                <Typography variant="caption" component="h2">
                  Under 2
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <button
                  className="minus"
                  onClick={minusfrompassenger("infants")}
                >
                  -
                </button>
                <h3 style={{ width: "20px", minWidth: "20px" }}>
                  {passengers.infants}
                </h3>
                <button className="plus" onClick={addtopassenger("infants")}>
                  +
                </button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Divider />
      {currentPage === "flights-list" || currentPage === "flights" ? (
        ""
      ) : (
        <Grid container>
          <Grid item lg={12} xs={12}>
            <div className="people">
              <Grid container sx={{ display: "flex" }}>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="h6" component="h6">
                    Rooms
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <button
                    className="minus"
                    onClick={minusfrompassenger("room")}
                  >
                    -
                  </button>
                  <h3 style={{ width: "20px", minWidth: "20px" }}>
                    {passengers.rooms}
                  </h3>
                  <button className="plus" onClick={addtopassenger("room")}>
                    +
                  </button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid
          item
          lg={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 3,
          }}
        >
          {isMediumScreen ? (
            <>
              <Button
                variant="link"
                sx={{
                  padding: "6px 0px",
                  color: "black",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  setadultExpanded(false);
                  togglePassengerDrawer();
                  handleGuestPopoverClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setadultExpanded(false);
                  togglePassengerDrawer();
                  handleGuestPopoverClose();
                }}
                className="donebtn"
              >
                Done
              </Button>
            </>
          ) : (
            <Button
              sx={{
                width: "80%",
                left: "10%",
                textTransform: "none",
                position: "fixed",
                bottom: "3vh",
              }}
              onClick={() => {
                setadultExpanded(false);
                togglePassengerDrawer();
                setPassengerSelected(true);
              }}
              variant="contained"
            >
              Done
            </Button>
          )}
        </Grid>
      </Grid>
    </div>,
  ];
  // bookingModel code

  const model = [
    <Card
      variant="outlined"
      sx={{
        padding: currentPage == "hotels-list" ? "1vh 10vw" : "0",
        background: "#F6F6F6",
        borderRadius: "10px",
        mt: { sm: 0 },
      }}
      className="formcard"
    >
      <CardContent>
        {/* Departure Input */}

        {currentPage === "home" ? (
          <>
            <Card
              variant="outlined"
              sx={{ borderRadius: "0.5em" }}
              className="cardfields"
            >
              <Grid container sx={{ padding: "1% 2% 0", display: "flex" }}>
                <Grid item lg={1} sm={1} xs={1}>
                  <LocationOnIcon sx={{ fontSize: "38px" }} className="icon" />
                </Grid>
                <Grid item lg={11.3} sm={11} xs={11}>
                  <FormControl sx={{ minWidth: "80%", width: "100%" }}>
                    <InputLabel id="demo-simple-select-autowidth-label">
                      From where?
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={departure}
                      onChange={handledeparture}
                      label="From where?"
                      IconComponent={KeyboardArrowDownIcon}
                      sx={{
                        fontWeight: 600,
                        paddingBottom: 0,
                        letterSpacing: 1,
                      }}
                    >
                      <MenuItem value="">
                        <em>From where?</em>
                      </MenuItem>
                      <MenuItem value={"SYD,Australia"}>
                        Sydney, Australia
                      </MenuItem>
                      <MenuItem value={21}>Twenty one</MenuItem>
                      <MenuItem value={22}>Twenty one and a half</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Card>
            {departurError ? (
              <Typography variant="body2" className="error">
                * Departure filed is required
              </Typography>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        {/* Date Picker */}

        {dateError ? (
          <Typography variant="body2" className="error">
            * Starting and Ending dates can't be same
          </Typography>
        ) : (
          ""
        )}

        {/* Destination Picker */}

        <>
          <Card
            variant="outlined"
            className="cardfields"
            sx={{ marginTop: 3, borderRadius: "0.5em" }}
          >
            {/* <Accordion
                    expanded={destinationsExpands[index] === true}
                    kucbnam={index}
                    onChange={handledestinationExpandeds(index)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      {destination.country ? (
                        ""
                      ) : (
                        <LocationOnIcon
                          sx={{ fontSize: "35px", marginRight: "1%" }}
                          className="icon"
                        />
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        {destination.country ? (
                          ""
                        ) : (
                          <Typography sx={{ color: "#999999" }}>
                            Where do you want to go?
                            <br />
                          </Typography>
                        )}
                        {destinationsExpands[index] === false
                          ? destination.city &&
                            destination.minStayDuration
                            && (
                              <>
                                <div className="selectedDestination">
                                  <Typography
                                    sx={{ fontSize: 15, display: "flex" }}
                                  >
                                    {destination.city}{" "}
                                    {currentPage === 'home' ? destination.minStayDuration + '-' + destination.maxStayDuration + 'days' : ""}
                                    <CloseIcon
                                      className="removeButton"
                                      sx={{
                                        ml: 1,
                                        fontSize: "23px",
                                      }}
                                      onClick={removedestination(index)}
                                    />
                                  </Typography>{" "}
                                </div>
                              </>
                            )
                          : ""}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails> */}
            {/* <Autocomplete className="x-auto"
                onChange={(e, newValue) => {
                  handleCitySearchValue(newValue.cityName);
                  setCitySearchValue(newValue.cityName);
                  handledestinationcountry(
                    newValue.cityName,
                    newValue.cityCode,
                    index
                  );
                }}
                id="country-select-demo"
                sx={{
                  width: "100%",
                  border: "1px solid #E5E7EB",
                  borderRadius: "0.3rem",
                }}
                options={countries}
                autoHighlight
                key={index}
                getOptionLabel={(option) => option.cityName}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.cityName} , {option.cityCode}
                  </Box>
                )}
                renderInput={(params) => ( */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {isLargeScreen === true && currentPage === "hotels-list" ? (
                <>
                  <Card>
                    <Box
                      border="1px solid #808080"
                      borderRadius="100px"
                      margin="15px 10px"
                      width="80%"
                      display="flex"
                      pl="20px"
                      alignItems="center"
                      sx={{
                        transition:
                          "border 0.3s, border-color 0.3s, box-shadow 0.3s, color 0.3s, outline 0.3s",
                        "&:hover": {
                          border: "1px solid #3546AB",
                          boxShadow: "0 0 2px #3546AB",
                          outline: "#3546AB solid 1px",
                          "& .MuiSvgIcon-root": {
                            color: "#3546AB", // Change the color of LocationOnIcon on hover
                          },
                        },
                      }}
                    >
                      <LocationOnIcon
                        fontSize="medium"
                        sx={{ color: "#808080" }}
                      />
                      <HotelsSearchbar
                        cities={countries}
                        getArrivalCity={handleArrivalCity}
                      />
                      {/* <TextField
                              variant="outlined"
                              placeholder="Type location"
                              value={alldestinations[0].city}
                              // placeholder={{
                              //     <LocationOnIcon
                              //       sx={{ color: "#808080" }}
                              //       fontSize="small"
                              //     />,
                              //     where do you want to go ?
                              // }}
                              sx={{
                                width: "100%",
                                padding: "0px !important",
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "transparent !important",
                                },
                                "& .MuiInputBase-input": {
                                  padding: "10px 15px !important",
                                },
                                "& input::placeholder": {
                                  fontSize: { xs: "16px" },
                                  color: "black",
                                  fontWeight: "500",
                                },
                                "& .MuiSvgIcon-root": {
                                  display: "none",
                                },
                              }}
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                              }}
                            /> */}
                    </Box>
                  </Card>
                </>
              ) : isMediumScreen == true && currentPage !== "hotels-list" ? (
                <>
                  <Grid
                    container
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={1.5} md={1} lg={1}>
                      <LocationOnIcon
                        sx={{
                          color: "#808080",
                          marginLeft: "15px",
                          fontSize: { xs: "35px", lg: "35px" },
                        }}
                      />
                    </Grid>
                    <Grid item xs={10.5} md={11} lg={11} padding="12px 0">
                      <HotelsSearchbar
                        currentSearchbar="first"
                        cities={countries}
                        getArrivalCity={handleArrivalCity}
                      />
                      {/* <TextField
                              variant="outlined"
                              placeholder={
                                currentPage == "flights"
                                  ? "Where From"
                                  : "where do you want to go ?"
                              }
                              sx={{
                                "& input::placeholder": {
                                  fontSize: { xs: "14px", lg: "14px" },
                                  color: "#000000",
                                  fontWeight: "500",
                                },
                                "& .MuiSvgIcon-root": {
                                  display: "none",
                                },
                              }}
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                              }}
                            /> */}
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Box display="flex" alignItems="center" padding="10px 5px">
                  <LocationOnIcon
                    sx={{
                      color: "#808080",
                      fontSize: { xs: "30px", lg: "35px" },
                    }}
                  />
                  <Button
                    onClick={toggleLocationDrawer}
                    sx={{
                      backgroundColor: "none",
                      color: "#0F0E0F",
                      textTransform: "none",
                      fontSize: { xs: "12px", lg: "14px" },
                      fontWeight: "600",
                    }}
                  >
                    {alldestinations[0].city
                      ? alldestinations[0].city
                      : "Where to ?"}
                  </Button>
                  <Drawer
                    open={isLocationDrawerOpen}
                    onClose={toggleLocationDrawer}
                    anchor="bottom"
                  >
                    <Box height="100vh">
                      <Box
                        position="relative"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        boxShadow="0 1px 1px #D3D3D3"
                      >
                        <Box
                          border="1px solid #808080"
                          borderRadius="100px"
                          margin="15px 10px"
                          width="80%"
                          display="flex"
                          pl="20px"
                          alignItems="center"
                          sx={{
                            transition:
                              "border-color 0.3s, box-shadow 0.3s, color 0.3s, outline 0.3s",
                            "&:hover": {
                              border: "1px solid #3546AB",
                              boxShadow: "0 0 2px #3546AB",
                              outline: "#3546AB solid 1px",
                              "& .MuiSvgIcon-root": {
                                color: "#3546AB", // Change the color of LocationOnIcon on hover
                              },
                            },
                          }}
                        >
                          <LocationOnIcon
                            fontSize="medium"
                            sx={{ color: "#808080" }}
                          />
                          {/* <HotelsSearchbar cities={countries} getArrivalCity={handleArrivalCity} /> */}
                          {/* <TextField
                                  variant="outlined"
                                  placeholder="Type location"
                                  value={alldestinations[0].city}
                                  // placeholder={{
                                  //     <LocationOnIcon
                                  //       sx={{ color: "#808080" }}
                                  //       fontSize="small"
                                  //     />,
                                  //     where do you want to go ?
                                  // }}
                                  sx={{
                                    width: "100%",
                                    padding: "0px !important",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      borderColor: "transparent !important",
                                    },
                                    "& .MuiInputBase-input": {
                                      padding: "10px 15px !important",
                                    },
                                    "& input::placeholder": {
                                      fontSize: { xs: "16px" },
                                      color: "black",
                                      fontWeight: "500",
                                    },
                                    "& .MuiSvgIcon-root": {
                                      display: "none",
                                    },
                                  }}
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                /> */}
                        </Box>
                        <IconButton onClick={toggleLocationDrawer}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                      {/* <Box>
                              {countries.filter(item =>{
                                const searchTerm = citySearchValue.toLowerCase();
                                const cityName = item.cityName.toLowerCase();
                                return searchTerm && cityName.startsWith(searchTerm)
                              }).map((country, index) => {
                                return(
                                  <Typography>{country.cityName}</Typography>
                                )
                              })}
                            </Box> */}
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      position="fixed"
                      width="100%"
                      bottom="3vh"
                    >
                      <Button
                        sx={{
                          position: "relative",
                          backgroundColor: "#3C50C3",
                          textTransform: "none",
                          width: "80%",
                        }}
                        onClick={() => {
                          toggleLocationDrawer();
                          dateSelected == false
                            ? toggleCalendarDrawer()
                            : console.log("do nothing");
                        }}
                        variant="contained"
                      >
                        Search
                      </Button>
                    </Box>
                  </Drawer>
                </Box>
              )}
            </Box>
            {/* )}
              /> */}
            {/* <Grid container>
                       {currentPage === 'home' ? <> <Grid item xs={12} sx={{ display: "flex" }}>
                          <h5>
                            Stay {destination.minStayDuration}-
                            {destination.maxStayDuration} days
                          </h5>{" "}
                          <h6 className="small-text">Suggested stay time</h6>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                          <Slider
                            sx={{
                              color: "#6373CF",
                              "& .MuiSlider-thumb": {
                                border: "1px solid #3546AB",
                                backgroundColor: "white",
                              },
                            }}
                            key={index}
                            name="rangeofdays"
                            getAriaLabel={() => "Days range"}
                            value={[
                              destination.minStayDuration,
                              destination.maxStayDuration,
                            ]}
                            onChange={(event, newValue) =>
                              handeldestinationrange(event, index, newValue)
                            }
                            valueLabelDisplay="auto"
                            min={1}
                            max={
                              dates[0].endDate &&
                              dates[0].startDate &&
                              convertMsToDays(
                                dates[0].endDate.getTime() -
                                  dates[0].startDate.getTime()
                              )
                            }
                          />
                        </Grid>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            lg={12}
                            sx={{
                              display: "flex",
                              justifyContent: {
                                xs: "space-between",
                                lg: "space-between",
                              },
                            }}
                          >
                            <span>1</span>
                            <span>
                              {dates[0].endDate &&
                                dates[0].startDate &&
                                convertMsToDays(
                                  dates[0].endDate.getTime() -
                                    dates[0].startDate.getTime()
                                )}
                            </span>
                          </Grid>
                        </Grid>
                        </> : ""}
                        {/* <Grid container>
                          <Grid
                            item
                            lg={12}
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: 3,
                            }}
                          >
                            <Button
                              variant="text"
                              sx={{ color: "black", fontWeight: "bold" }}
                              onClick={handledestinationExpandeds(index)}
                            >
                              cancel
                            </Button>
                            <Button
                              variant="contained"
                              disabled={destination.country ? false : true}
                              onClick={handledestinationExpandeds(index)}
                              className="donebtn"
                            >
                              Done
                            </Button>
                          </Grid>
                        </Grid> 
                      </Grid> */}
            {/* </AccordionDetails>
                  </Accordion>  */}
          </Card>
          {currentPage == "flights" ? (
            <Card
              variant="outlined"
              className="cardfields"
              sx={{ marginTop: 3, borderRadius: "0.5em" }}
            >
              <Grid container display="flex" alignItems="center">
                <Grid item xs={1.5} md={1} lg={1}>
                  <LocationOnIcon
                    sx={{
                      color: "#808080",
                      marginLeft: "15px",
                      fontSize: { xs: "35px", lg: "35px" },
                    }}
                  />
                </Grid>
                <Grid item xs={10.5} md={11} lg={11} padding="12px 0">
                  <HotelsSearchbar getArrivalCity={handleArrivalCity} />
                  {/* <TextField
                      variant="outlined"
                      placeholder={
                        currentPage == "flights"
                          ? "Where to"
                          : "where do you want to go ?"
                      }
                      sx={{
                        "& input::placeholder": {
                          fontSize: { xs: "14px", lg: "14px" },
                          color: "#000000",
                          fontWeight: "500",
                        },
                        "& .MuiSvgIcon-root": {
                          display: "none",
                        },
                      }}
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    /> */}
                </Grid>
              </Grid>
            </Card>
          ) : (
            ""
          )}
          <Card
            variant="outlined"
            className="cardfields"
            sx={{ marginTop: 3, borderRadius: "0.5em" }}
          >
            {isMediumScreen ? (
              <Accordion
                expanded={expanded === "panel1"}
                onChange={datesoption("panel1")}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Grid container>
                    <Grid item xs={1.5} md={1} lg={1}>
                      {currentPage === "home" ? (
                        <FlightTakeoff
                          sx={{
                            fontSize: { xs: "30px", lg: "35px" },
                          }}
                          className="icon"
                        />
                      ) : (
                        <CalendarMonthIcon
                          sx={{
                            fontSize: { xs: "30px", lg: "35px" },
                          }}
                          className="icon"
                        />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={10.5}
                      md={11}
                      lg={11}
                      alignItems="center"
                      display="flex"
                    >
                      <>
                        {currentPage === "home" ? (
                          <>
                            {dateSelected === false ? (
                              <Typography
                                fontSize={{ xs: "12px", lg: "14px" }}
                                color="#0C1027"
                                fontWeight="700"
                              >
                                {`${format(
                                  dates[0].startDate,
                                  "dd-MMM"
                                )} - ${format(
                                  dates[0].endDate,
                                  "dd-MMM"
                                )} `}{" "}
                              </Typography>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          <>
                            {dateSelected === false ? (
                              <Typography
                                fontSize={{ xs: "12px", lg: "14px" }}
                                color="#0C1027"
                                fontWeight="700"
                              >
                                {`${format(
                                  dates[0].startDate,
                                  "dd-MMM"
                                )} - ${format(
                                  dates[0].endDate,
                                  "dd-MMM"
                                )} `}{" "}
                              </Typography>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </>

                      {dateSelected === true
                        ? dates[0].endDate && (
                            <div sx={{ width: "100%" }}>
                              <Typography
                                fontSize={{ xs: "12px", lg: "14px" }}
                                color="#0C1027"
                                fontWeight="700"
                                display="inline-flex"
                              >
                                {`${format(
                                  dates[0].startDate,
                                  "dd-MMM"
                                )} - ${format(
                                  dates[0].endDate,
                                  "dd-MMM"
                                )} `}{" "}
                                {flexibledays != "0" ? (
                                  <Typography
                                    sx={{ fontWeight: 600 }}
                                    className="flexibledays"
                                  >
                                    <FiberManualRecordRoundedIcon
                                      sx={{
                                        fontSize: 14,
                                        color: "black",
                                        ml: 1,
                                      }}
                                    />{" "}
                                    {flexibledays} days
                                  </Typography>
                                ) : (
                                  ""
                                )}
                              </Typography>{" "}
                            </div>
                          )
                        : ""}
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item lg={12} xs={12}>
                      {currentPage === "flights" ? (
                        <>
                          <Button
                            variant={
                              flightType == false ? "contained" : "outlined"
                            }
                            onClick={() => {
                              setOneWay(false);
                              sendFlightType(false);
                            }}
                            sx={{
                              textTransform: "none",
                              padding: "20px 0",
                              width: "50%",
                              borderTopLeftRadius: "12px",
                              borderBottomLeftRadius: "12px",
                              borderTopRightRadius: "0px",
                              borderBottomRightRadius: "0px",
                              backgroundColor:
                                flightType == true ? "#ffffff" : "#3546AB",
                            }}
                          >
                            Return &nbsp;{" "}
                            {flightType == true ? "" : <DoneIcon />}
                          </Button>
                          <Button
                            variant={
                              flightType == false ? "outlined" : "contained"
                            }
                            onClick={() => {
                              setOneWay(true);
                              sendFlightType(true);
                            }}
                            sx={{
                              textTransform: "none",
                              padding: "20px 0",
                              width: "50%",
                              borderTopLeftRadius: "0",
                              borderBottomLeftRadius: "0",
                              borderTopRightRadius: "12px",
                              borderBottomRightRadius: "12px",
                              backgroundColor:
                                flightType == true ? "#3546AB" : "#ffffff",
                            }}
                          >
                            One Way &nbsp;{" "}
                            {flightType == true ? <DoneIcon /> : ""}
                          </Button>
                        </>
                      ) : (
                        ""
                      )}
                      {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
                      <DateRange
                        showMonthAndYearPickers={false}
                        editableDateInputs={false}
                        showDateDisplay={false}
                        sx={{ width: "100%" }}
                        onChange={(item) => setDates([item.selection])}
                        moveRangeOnFirstSelection={false}
                        minDate={new Date()}
                        ranges={dates}
                        months={
                          currentPage === "home1" ? (isLargeScreen ? 2 : 1) : 1
                        }
                        direction="horizontal"
                        rangeColors={["#D1E5F4"]}
                      />
                      {/*       
                          <DateRangePicker sx={{ border : "0.5px solid #E5E7EB", my: "1em"}}
                          disablePast = {true}
                          format ="DD/MM/YYYY"
                          /> */}
                      {/* </LocalizationProvider> */}
                    </Grid>
                  </Grid>
                  {currentPage === "home" ? (
                    <Grid
                      container
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Grid item lg={4}>
                        <FormControlLabel
                          control={<Checkbox onChange={updateflexibility} />}
                          label="I'm flexible"
                        />
                      </Grid>
                      <Grid item lg={4}>
                        <input
                          type="text"
                          placeholder="7 days"
                          className="days-input"
                          disabled={flexible ? false : true}
                          sx={{ color: "black" }}
                          onChange={(e) => handleFlexibleDays(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid container>
                    <Grid
                      item
                      lg={12}
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 3,
                      }}
                    >
                      <Button
                        variant="text"
                        sx={{ color: "black", fontWeight: "bold" }}
                        onClick={() => setExpanded(false)}
                      >
                        cancel
                      </Button>
                      <Button
                        variant="contained"
                        disabled={dates[0].endDate ? false : true}
                        onClick={() => {
                          return setExpanded(false), setDateSelected(true);
                        }}
                        className="donebtn"
                      >
                        Done
                      </Button>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ) : (
              <>
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    borderRight="1px solid #999"
                    display="flex"
                    alignItems="center"
                    color="#999"
                    padding="10px"
                  >
                    <TodayIcon sx={{ color: "#808080" }} />
                    <Button
                      onClick={toggleCalendarDrawer}
                      sx={{
                        backgroundColor: "none",
                        color: "#0F0E0F",
                        textTransform: "none",
                        fontSize: { xs: "12px", lg: "14px" },
                        fontWeight: "600",
                      }}
                    >
                      {format(dates[0].startDate, "dd-MMM")}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    display="flex"
                    alignItems="center"
                    color="#999"
                    padding="10px"
                  >
                    <InsertInvitationIcon sx={{ color: "#808080" }} />
                    <Button
                      onClick={toggleCalendarDrawer}
                      sx={{
                        backgroundColor: "none",
                        color: "#0F0E0F",
                        textTransform: "none",
                        fontSize: { xs: "12px", lg: "14px" },
                        fontWeight: "600",
                      }}
                    >
                      {format(dates[0].endDate, "dd-MMM")}
                    </Button>
                  </Grid>
                </Grid>
                <Drawer
                  open={isCalendarDrawerOpen}
                  onClose={toggleCalendarDrawer}
                  anchor="bottom"
                  // Add style to make the drawer scrollable
                  sx={{ overflowY: "auto" }}
                >
                  <Box
                    position="relative"
                    display="flex"
                    justifyContent="center"
                    boxShadow="0 1px 3px #808080"
                  >
                    <Typography
                      justifyContent="center"
                      color="#121416"
                      fontWeight="600"
                      padding="2.5vh 0"
                    >
                      Check in - Check out
                    </Typography>
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                      }}
                      onClick={toggleCalendarDrawer}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      overflowY: "auto", // Add overflowY to make the content scrollable
                      maxHeight: "100vh", // Limit the maximum height of the content
                    }}
                  >
                    <DateRange
                      showMonthAndYearPickers={false}
                      editableDateInputs={false}
                      showDateDisplay={false}
                      onChange={(item) => setDates([item.selection])}
                      moveRangeOnFirstSelection={false}
                      minDate={new Date()}
                      ranges={dates}
                      months={12}
                      direction="vertical"
                      rangeColors={["#D1E5F4"]}
                    />
                  </Box>
                  <Button
                    sx={{
                      width: "80%",
                      left: "10%",
                      textTransform: "none",
                      position: "fixed",
                      bottom: "3vh",
                    }}
                    onClick={() => {
                      toggleCalendarDrawer();
                      setDateSelected(true);
                      passengerSelected == false
                        ? togglePassengerDrawer()
                        : console.log("do nothing");
                    }}
                    variant="contained"
                  >
                    Select {numberOfDays} Days
                  </Button>
                </Drawer>
              </>
            )}
          </Card>

          {/* {destinationError[index] ? (
              <Typography variant="body2" className="error">
                * Destination field is required
              </Typography>
            ) : (
              ""
            )} */}
        </>
        {/* <Grid
                  conatiner
                  sx={{ display: "flex", color: "#18204E", marginTop: 2 }}
                >
                  {maxDestinations ? (
                    <Typography
                      variant="body2"
                      component="h2"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      Add New Destination{" "}
                      <AddIcon
                        sx={{ cursor: "pointer", marginLeft: 1 }}
                        onClick={addnewdestination}
                      />
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid> */}

        {/* Passsenger information */}

        <Grid
          container
          sx={{
            marginTop: 1,
            display: "flex",
            columnGap: 1,
            justifyContent: "space-between",
          }}
        >
          <Grid item lg={12} xs={12}>
            <Card
              variant="outlined"
              sx={{ borderRadius: "0.5em" }}
              className="cardfields"
            >
              {isMediumScreen ? (
                <Accordion
                  expanded={adultexpanded === "panel1"}
                  onChange={adultoption("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Grid container>
                      <Grid item xs={1.5} md={1} lg={1}>
                        <PersonIcon
                          sx={{ fontSize: { xs: "30px", lg: "35px" } }}
                          className="icon"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={10.5}
                        md={11}
                        lg={11}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        {passengers.adults ||
                        passengers.children ||
                        passengers.rooms > 0 ? (
                          ""
                        ) : (
                          <Typography color="#999999" fontSize="14px">
                            Add guests
                          </Typography>
                        )}
                        {adultexpanded === false ? (
                          passengers.adults ||
                          passengers.children ||
                          passengers.infants ||
                          passengers.rooms > 0 ? (
                            <Typography
                              fontSize={{ xs: "12px", lg: "14px" }}
                              fontWeight="600"
                              color="0C1027"
                            >
                              {`
                              ${
                                passengers.adults > 0
                                  ? passengers.adults + " Adults"
                                  : ""
                              }
                              ${
                                passengers.children > 0
                                  ? " - " + passengers.children + " Children"
                                  : ""
                              }
                              ${
                                passengers.infants > 0
                                  ? " - " + passengers.infants + " Infants"
                                  : ""
                              }
                              ${
                                currentPage === "flights" || currentPage === "flights-list" 
                                  ? ""
                                  : passengers.rooms > 0
                                  ? " - " + passengers.rooms + " Rooms"
                                  : ""
                              }
                            `}
                            </Typography>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>{passengerCounter}</AccordionDetails>
                </Accordion>
              ) : (
                <>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      alignItems="center"
                      color="#999"
                      padding="10px"
                    >
                      <PersonIcon sx={{ color: "#808080" }} />
                      <Button
                        onClick={togglePassengerDrawer}
                        sx={{
                          backgroundColor: "none",
                          color: "#999",
                          textTransform: "none",
                          fontSize: "12px",
                        }}
                      >
                        {passengers.adults ||
                        passengers.children ||
                        passengers.rooms > 0 ? (
                          <Typography
                            color="#0F0E0F"
                            fontSize="12px"
                            fontWeight="600"
                          >
                            {" "}
                            {passengers.adults +
                              passengers.children} Guest(s), {currentPage == 'flights-list' ? "" : passengers.rooms + ' Room(s)'}
                          </Typography>
                        ) : (
                          <Typography color="#999999" fontSize="14px">
                            Add guests
                          </Typography>
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                  <Drawer
                    open={isPassengerDrawerOpen}
                    onClose={togglePassengerDrawer}
                    anchor="bottom" // You can adjust the anchor position as needed
                  >
                    <Box
                      position="relative"
                      display="flex"
                      justifyContent="center"
                      boxShadow="0 1px 3px #808080"
                    >
                      <Typography
                        justifyContent="center"
                        color="#121416"
                        fontWeight="600"
                        padding="2vh 0"
                      >
                        Guests and Rooms
                      </Typography>
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                        }}
                        onClick={togglePassengerDrawer}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    <Box padding="0 5vw" height="100vh">
                      {passengerCounter}
                    </Box>
                  </Drawer>
                </>
              )}
            </Card>
          </Grid>
          {/* <Grid item lg={5} xs={5}>
                <Paper sx={{ borderRadius: "1px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "1px",
                      padding: "2px 15px",
                    }}
                  >
                    <PaidIcon
                      sx={{ fontSize: "35px", marginRight: "1%" }}
                      className="icon"
                    />
                    <TextField
                      sx={{ color: "#999999" }}
                      type="number"
                      id="outlined-textarea"
                      label="Budget (optional)"
                    />
                  </Box>
                </Paper> */}
          {/* <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography sx={{ color: "#999999" }}>
                              Budget <i>(optional)</i>
                              <br />
                            </Typography>
                            </div> */}
          {/* </Grid>  */}

          <Grid container>
            <Grid item lg={12} xs={12}>
              <Button
                variant="contained"
                disabled={loading}
                sx={{
                  width: "100%",
                  mt: 3,
                  mb: 2,
                  padding: 1.5,
                  borderRadius: 4,
                  textTransform: "none",
                  backgroundColor: "#3C50C3",
                }}
                className="submit-btn"
                onClick={() => {
                  submitdata();
                  handleSubmit();
                  toggleModalDrawer();
                }}
              >
                {currentPage === "home" ? "Submit" : "Search"}
              </Button>
            </Grid>
            <Grid
              item
              lg={12}
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {localStorage.getItem("planId") != null ? (
                <NavLink
                  to="/itinerary"
                  className="existingLink"
                  sx={{ m: "auto" }}
                >
                  View Existing Plan
                </NavLink>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>,
  ];
  useEffect(() => {
    // Parse the URL
    const urlParams = new URLSearchParams(window.location.search);

    // Extract dateFrom and dateTo from the URL
    const dateFrom = urlParams.get("dateFrom");
    const dateTo = urlParams.get("dateTo");
    const rooms = urlParams.get("rooms");
    const children = urlParams.get("children");
    const infants = urlParams.get("infants");
    const adults = urlParams.get("adults");

    // Set the dates in the state if dateFrom and dateTo are available
    if (dateFrom && dateTo) {
      setDates([
        {
          startDate: new Date(dateFrom),
          endDate: new Date(dateTo),
          key: "selection",
        },
      ]);
      setDate([
        {
          startDate: new Date(dateFrom),
          endDate: new Date(dateTo),
          key: "selection",
        },
      ]);
    }

    // Set the guests if any of the values are available
    if (adults || children || rooms || infants) {
      setPassengers({
        adults: Number(adults) || 0, // or default value
        children: Number(children) || 0, // or default value
        infants: Number(infants) || 0, // or default value
        rooms: Number(rooms) || 1, // or default value
      });
    }
  }, []);
  
  useEffect(() => {
    // Calculate the number of days when the dates state changes
    const startDate = dates[0].startDate;
    const endDate = dates[0].endDate;

    const daysDifference = Math.ceil(
      (endDate - startDate) / (1000 * 60 * 60 * 24)
    );

    setNumberOfDays(daysDifference + 1);
  }, [dates]);

  return (
    <>
      {currentPage == "home1" ? (
        model
      ) : isLargeScreen &&
        currentPage !== "hotels-list" &&
        currentPage !== "flights-list" ? (
        model
      ) : currentPage == "hotels-list" ||
        (currentPage == "flights-list" && isLargeScreen == true) ? (
        <>
          <Card
            variant="outlined"
            onClick={toggleModalDrawer}
            sx={{
              width: "100%",
              padding: "2px",
              mt: "2vh",
              borderRadius: { xs: "100px", md: "12px" },
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {currentPage == "flights-list" ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "100px",
                  color: "#808080",
                  padding: "10px",
                  margin: "0 10px 0 0",
                  width: "15%",
                }}
              >
                <LocationOnIcon />{" "}
                <HotelsSearchbar
                  currentSearchbar="first"
                  getDepartureCity={handleDepartureCity}
                />
              </Box>
            ) : (
              ""
            )}
            {currentPage == "flights-list" ? 
            <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent:'start',
                  borderRadius: "100px",
                  color: "#808080",
                  padding: "10px 0",
                  width: "12%",
                }}>
                  <IconButton onClick={swapDestinations}>
              <SwapHorizIcon color="primary"/>
              </IconButton>
            </Box>
            :
            ""
              }
            <Box
              variant="outlined"
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: "100px",
                color: "#808080",
                padding: "10px",
                margin: "0 10px 0 0",
                width: currentPage == "flights-list" ? "15%" : "30%",
              }}
            >
              <LocationOnIcon />{" "}
              <HotelsSearchbar
                currentSearchbar="second"
                getArrivalCity={handleArrivalCity}
              />
            </Box>
            <Button
              variant="outlined"
              aria-describedby={datePopoverId}
              onClick={handleDatePopover}
              sx={{
                textTransform: "none",
                border: "1px solid transparent",
                borderRadius: "100px",
                color: "#808080",
                padding: "10px",
                margin: "0 10px 0 0",
              }}
            >
              <CalendarMonthIcon />
              &nbsp;
              <Typography
                fontFamily="inter"
                color="#0F0E0F"
                fontSize={{ xs: "12px", md: "14px" }}
                fontWeight="600"
              >
                {flightType === true
                  ? format(date[0].startDate, "dd-MMM")
                  : format(date[0].startDate, "dd-MMM") +
                    " - " +
                    format(date[0].endDate, "dd-MMM")}
              </Typography>
            </Button>

            <Popover
              open={openDatePopover}
              anchorEl={popoverDateAnchor}
              onClose={handleDatePopoverClose}
              anchorOrigin={{
                vertical: "bottom", // Center vertically
                horizontal: "center", // Center horizontally
              }}
              transformOrigin={{
                vertical: "top", // Center vertically
                horizontal: "center", // Center horizontally
              }}
            >
              <Box
                width="30vw"
                padding="20px"
                borderRadius="10px"
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                {currentPage == "hotels-list" ? (
                  ""
                ) : (
                  <Box display="flex" justifyContent="center">
                    <Button
                      variant={oneWay == true ? "outlined" : "contained"}
                      onClick={() => {
                        setOneWay(false);
                        sendFlightType(false);
                      }}
                      sx={{
                        textTransform: "none",
                        padding: "20px 0",
                        width: "20%",
                        borderTopLeftRadius: "12px",
                        borderBottomLeftRadius: "12px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        backgroundColor: oneWay == true ? "#ffffff" : "#3546AB",
                      }}
                    >
                      Return &nbsp; {oneWay == true ? "" : <DoneIcon />}
                    </Button>
                    <Button
                      variant={oneWay == true ? "contained" : "outlined"}
                      onClick={() => {
                        setOneWay(true);
                        sendFlightType(true);
                      }}
                      sx={{
                        textTransform: "none",
                        padding: "20px 0",
                        width: "20%",
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0",
                        borderTopRightRadius: "12px",
                        borderBottomRightRadius: "12px",
                        backgroundColor: oneWay == true ? "#3546AB" : "#ffffff",
                      }}
                    >
                      One Way &nbsp; {oneWay == true ? <DoneIcon /> : ""}
                    </Button>
                  </Box>
                )}
                {calendar}

                <Grid
                  container
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={3}>
                    <Typography
                      display="flex"
                      alignItems="center"
                      fontFamily="inter"
                      fontWeight="600"
                      color="#333"
                    >
                      <Checkbox
                        checked={flexible}
                        onChange={(e) => setflexible(e.target.checked)}
                      />{" "}
                      I'm flexible
                    </Typography>
                  </Grid>
                  <Grid item={9}>
                    <TextField
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">±</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">days</InputAdornment>
                        ),
                      }}
                      defaultValue="7"
                      disabled={!flexible}
                      variant="outlined"
                      sx={{
                        fontWeight: "600",
                        border: "1px solid #E5E7EB",
                        borderRadius: "8px",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 3,
                    }}
                  >
                    <Button
                      variant="text"
                      sx={{ color: "black", fontWeight: "bold" }}
                      onClick={() => handleDatePopoverClose()}
                    >
                      cancel
                    </Button>
                    <Button
                      variant="contained"
                      disabled={dates[0].endDate ? false : true}
                      onClick={() => {
                        return handleDatePopoverClose(), setDateSelected(true);
                      }}
                      className="donebtn"
                    >
                      Done
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Popover>
            <Button
              variant="outlined"
              aria-describedby={guestPopoverId}
              onClick={handleGuestPopover}
              sx={{
                textTransform: "none",
                border: "1px solid transparent",
                borderRadius: "100px",
                color: "#808080",
                padding: "10px",
                margin: "0 10px 0 0",
              }}
            >
              <PersonIcon />
              &nbsp;
              <Typography
                fontFamily="inter"
                color="#0F0E0F"
                fontSize={{ xs: "12px", md: "14px" }}
                fontWeight="600"
              >
                {passengers.adults > 0 ||
                passengers.children > 0 ||
                passengers.rooms > 0
                  ? `${passengers.adults + passengers.children} Guests ${
                      currentPage == "flights-list" ? "" : passengers.rooms + " Room(s)"
                    }`
                  : ""}
              </Typography>
            </Button>
            <Popover
              open={openGuestPopover}
              anchorEl={popoverGuestAnchor}
              onClose={handleGuestPopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Box width="30vw" padding="20px" borderRadius="10px">
                {passengerCounter}
              </Box>
            </Popover>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                border: "1px solid transparent",
                borderRadius: "12px",
                color: "white",
                padding: "10px 30px",
                backgroundColor: "#3C50C3",
              }}
              onClick={onButtonClick}
            >
              Search
            </Button>
          </Card>
        </>
      ) : (
        <>
          <Card
            variant="outlined"
            onClick={toggleModalDrawer}
            sx={{
              padding: "10px",
              mt: "2vh",
              borderRadius: { xs: "100px", md: "100px" },
            }}
          >
            <Grid container>
              <Grid
                item
                xs={1.5}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <img
                  src="./assets/images/search-icon.svg"
                  width="100%"
                  height="20px"
                />
              </Grid>
              <Grid
                item
                xs={10.5}
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                alignItems={{ md: "center" }}
                gap={{ md: 2 }}
                padding={{ xs: "0", md: "10px 0", lg: "0" }}
              >
                <Typography
                  fontFamily="inter"
                  color="#0F0E0F"
                  fontSize={{ xs: "16px", md: "14px" }}
                  fontWeight="600"
                >
                  {alldestinations[0].city
                    ? alldestinations[0].city
                    : "Location"}
                </Typography>
                <Typography
                  fontFamily="inter"
                  color="#0F0E0F"
                  fontSize={{ xs: "12px", md: "14px" }}
                  fontWeight="600"
                >
                  {dateSelected == false
                    ? "Choose dates"
                    : format(dates[0].startDate, "dd-MMM") +
                      " - " +
                      format(dates[0].endDate, "dd-MMM")}
                  {passengers.adults > 0 ||
                  passengers.children > 0 ||
                  passengers.rooms > 0
                    ? `, ${passengers.adults + passengers.children} Guests, ${
                        currentPage == "flights-list" ? "" :passengers.rooms
                      } Room(s)`
                    : ""}
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Drawer
            open={isModalDrawerOpen}
            onClose={toggleModalDrawer}
            anchor="top"
            sx={{ "& .MuiDrawer-paper": { boxShadow: { md: "none" } } }}
          >
            <Grid
              container
              sx={{
                backgroundImage: `url(/assets/images/litineraryback.png)`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              {/* <Grid item xs={2}>
                <ProfileDrawer />
                <MainNavDrawer />
              </Grid>
              <Grid item xs={10}>
                <IconButton href="/">
                  <img src="./assets/images/logo.svg" width="85%" />
                </IconButton>
              </Grid> */}
              <Navbar />
            </Grid>
            <Box>{model}</Box>
          </Drawer>
        </>
      )}
    </>
  );
}
