import {React,useState }from 'react'
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import axios from 'axios';
import "./scss/Signup.css";
import { Link } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';
import { useContext } from 'react';
import  { auths } from './AuthsContext';
import {session} from '../Sessions';


export default function Signin() {

  const{email,emailerror,passerror,password,responseerror,emailborder,passwordborder,loading,setEmail,setemailerror,setPassword,
    setResponseError,setEmailborder,setPasswordborder,setpasserror,setloading}=useContext(auths)
 
    const [submitform,setSubmit]=useState(false)
    const{token,setUserToken,handletoken}=useContext(session)
  const error =value=>{
    
    if(value=="email")
    {
      console.log(value)
      return "red !important"
    }
    else if(value=="pass")
    {
      console.log(value)
      return "red !important"
    }
  }

  const validateInputs=()=>{
    console.log(email,password)
    if(email=="" || password=="")
    {
      if(email=="")
      {
        setemailerror(true)
      }
      if(password==""){
        setpasserror(true)
      }
      return false;
    }
    else{
      return true;
    }
  }

  const submit=()=>{
    let data={userName: email,password:password};
  
    console.log(data);
    setloading(true);
    setSubmit(submitform=>[submitform,true]);
    if(validateInputs()){
    axios.post(`https://localhost:7195/api/Account/login`,data)
    .then(res => {

      if(res.status==200)
      {
        console.log(res.data.data.token)
        window.sessionStorage.setItem("token",res.data.data.token)
        console.log(res.data.data.token)
        // let tkn=[...token]
        // tkn=res.data.data.token;
        // setUserToken(tkn)
        
// handletoken();
//         console.log(token)
//         console.log("sub",submitform)
      
       window.location.href="/";
      // console.log(res)
      // setResponseError("")
      // setemailerror(false);
      // setpasserror(false);
      }
    }).catch(error=> {
      console.log(error)
      setloading(false);
      if(error.response.status==400)
      {
        setResponseError(error.response.data.message)

        if(error.response.data.message=="Invalid Password"){
          setpasserror(true);
          setPassword("");
        }
        if(error.response.data.message=="Email is not confirmed")
        {
          setTimeout(() => {
            window.location.href="/confirmemail";
          }, 1500);
        }
        else{
       
        }
        // setemailerror(true);
       
        // setEmail("");
        

      }

    });
  }
  else{
    setResponseError("All Fields are Require")
  }
  }

  const handleemail=val=>{
    console.log(val)
    if(val!="")
    {
      setEmail(val)
      setemailerror(false);
    }
    else{
      setEmail(val)
      setemailerror(true);

    }
  }

  const handlepassword=val=>{
    console.log(val)
    if(val!="")
    {
      setPassword(val)
      setpasserror(false);
    }
    else{
      setPassword(val)
      setpasserror(true);

    }
  }

  return (
    <>
    <div className="form">
      
      <Typography variant='h5' sx={{fontWeight:700,marginTop:0.8}}>Welcome back!</Typography>
       <FormControl  sx={{ mt: 3,mb:3}}  variant="outlined">
          <label className="input-label">E-Mail or Username</label>
          <OutlinedInput
          className='input'
            id="filled-adornment-amount"
            startAdornment={<InputAdornment position="start"><EmailIcon/></InputAdornment>}
            placeholder="e.g.: elonmusk@mars.com "
            sx={{borderColor:emailerror?error("email"):emailborder}}
            onChange={(e)=>handleemail(e.target.value)}
            value={email}
          />
          {(emailerror) ? (
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: "red", mt: 1.5 }}
            >
              Email is required.
            </Typography>
          ) : (
            ""
          )}
        </FormControl>

        <FormControl  sx={{mt: 1,mb:2}}  variant="outlined">
          <label className="input-label">Password</label>
          <OutlinedInput
          className='input'
          
            id="filled-adornment-amount"
            startAdornment={<InputAdornment position="start"><LockIcon/></InputAdornment>}
            placeholder="e.g.: X Æ A-12"
            sx={{borderColor:passerror?error("pass"):passwordborder}}
            onChange={(e)=>handlepassword(e.target.value)}
            value={password}
            type="password"
          />
           {passerror? (
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: "red", mt: 1.5 }}
            >
              Password is required.
            </Typography>
          ) : (
            ""
          )}
        </FormControl>
        <Grid container>
          <Grid item lg={12} sx={{display:"flex",flexDirection:"row"}}>
       <Link to="/forgotpassword"><Typography variant='body2' sx={{fontWeight:600,cursor:"pointer",color:"black"}}>Forgot Password? </Typography></Link>
        </Grid>
        </Grid>
{console.log("lod",loading)}
        <Button variant="contained" disabled={loading} className="btn-primary" sx={{mt:3}} onClick={submit}>Sign In</Button>
        <Typography variant="h6" sx={{color:"red",mt:2,display:"flex",alignItems:"center"}}>{responseerror!=''?<><ErrorIcon sx={{mr:1}}/><Typography variant="h6">{responseerror}</Typography></>:""}</Typography>
        

        <Grid container sx={{mt:"20%"}}>
          <Grid item lg={12}>
            <Typography variant='body1' className="catch-info">© Catch U There 2022</Typography>
            <Typography variant='body1' className="catch-info">1A/2 Resolution Drive, Caringbah NSW 2229</Typography>
            <Typography variant='body1' className="catch-info">Australia</Typography>
          </Grid>
        </Grid>

       
    </div>
     <Grid container sx={{mt:"10%",mb:"2%"}}>
     <Grid item lg={12}>
       <Typography variant='body1' className="catch-info">By signing in or creating an account, you agree with our <u>Terms & conditions</u> and <u>Privacy policy</u></Typography>
      
     </Grid>
   </Grid>
   </>
  )
}
