import {React,useState }from 'react'
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import "./scss/Signup.css";
import axios from "axios";
import ErrorIcon from "@mui/icons-material/Error";
import VerificationModel from "../../components/ui-components/VerificationModel";


export default function Resetpassword(props) {


  let emailerror=false;
  let passerror=true;
  const [newpassword,setNewPassword]=useState('');
  const [cnfrmpassword,setConfermPassword]=useState('');
  const [emailborder,setEmailborder]=useState('#98989E')
  const [passwordborder,setPasswordborder]=useState('#98989E')
  const [pserror,setpserror]=useState(false);
  const [cpserror,setcpserror]=useState(false);
  const [mterror,setmterror]=useState(false);
  const [responseerror,setResponseError]=useState("");
  const [loading,setloading]=useState(false);
  const [reseted,setReseted]=useState(false)

  const error =value=>()=>{
    if(value=="email")
    {
      console.log(value)
      return "red !important"
    }
  }

  const checkpass = (val) => {
    let r = val.match(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
    );
    console.log("checkpass:", r);
    if (r == null) {
      return true;
    } else {
      return false;
    }
  };

  const handlepassword = (val) => {
    checkpass(val);
    if (val == "") {
      setpserror(true);
      setNewPassword(val);
    } else if (checkpass(val)) {
      setpserror(true);
      setNewPassword(val);
    } else {
      setpserror(false);
      setNewPassword(val);
    }
  };
  const handlematch=()=>{
    if(newpassword!=cnfrmpassword)
    {
      setmterror(true);
    }
    else{
      setmterror(false);
    }
  }

  const validateinputs=()=>{
    if(newpassword!="" && cnfrmpassword!="" && newpassword==cnfrmpassword && !checkpass(newpassword))
    {
      return true;
    }
    else{
      return false;
    }
  }

  const submit=()=>{
    setloading(true);
    let data={
      email:props.email,
      token:props.token,
      newPassword:newpassword,
    }
    if(validateinputs())
    {
axios.post("https://localhost:7195/api/Account/ResetPassword",data).then((res)=>{
  setloading(false)
  setReseted(true)
}).catch(error=>{
  setloading(false)
  setResponseError(error.response.data.message);
  console.log(error.response.data.message);
})
    }
    else{
      console.log("e")
    }
  }

  return (
    <>
    {reseted?
      <VerificationModel header="Success" sub="Your password has been reseted successfully...!" color="green" type="Sign In" page="/signin" />
      :
    <>
    <div className="form">
      
    <Typography variant='h5' sx={{fontWeight:700,marginTop:0.8,marginBottom:1}}>Rest Your Password</Typography>
      <Typography variant='body1' sx={{fontWeight:500,color:"#98989E"}}>Please add your new password to rest.</Typography>
        <FormControl  sx={{ mt: 3,mb:3}}  variant="outlined">
          <label className="input-label">New Password</label>
          <OutlinedInput
          className='input'
            id="filled-adornment-amount"
            startAdornment={<InputAdornment position="start"><EmailIcon/></InputAdornment>}
            placeholder="e.g.: elonmusk@mars.com "
            sx={{borderColor:emailerror?error("email"):emailborder}}
            onChange={(e)=>handlepassword(e.target.value)}
            type="password"
          />
              {pserror ? (
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, color: "red", mt: 1.5 }}
              >
                Passwords must be at least 8 characters.Passwords must have at
                least one non alphanumeric character.Passwords must have at
                least one digit ('0'-'9').Passwords must have at least one
                uppercase ('A'-'Z').
              </Typography>
            ) : (
              ""
            )}
        </FormControl>

        <FormControl  sx={{mt: 1,mb:2}}  variant="outlined">
          <label className="input-label">Confirm Password</label>
          <OutlinedInput
          className='input'
          
            id="filled-adornment-amount"
            startAdornment={<InputAdornment position="start"><LockIcon/></InputAdornment>}
            placeholder="e.g.: X Æ A-12"
            sx={{borderColor:passwordborder}}
            onChange={(e)=>setConfermPassword(e.target.value)}
            onBlur={() => handlematch()}
            type="password"
          />
          {mterror ? (
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, color: "red", mt: 1.5 }}
              >
                {cpserror
                  ? "Confirm Password is required"
                  : "Confirm Password does not match."}
              </Typography>
            ) : (
              ""
            )}
        </FormControl>
       

        <Button variant="contained" className="btn-primary" disabled={loading} sx={{mt:3}} onClick={submit}>Rest Password</Button>
        <Typography
          variant="h6"
          sx={{ color: "red", mt: 2, display: "flex", alignItems: "center" }}
        >
          {responseerror != "" ? (
            <>
              <ErrorIcon sx={{ mr: 1 }} />
              <Typography variant="h6">{responseerror}</Typography>
            </>
          ) : (
            ""
          )}
        </Typography>

        <Grid container sx={{mt:"20%"}}>
          <Grid item lg={12}>
            <Typography variant='body1' className="catch-info">© Catch U There 2022</Typography>
            <Typography variant='body1' className="catch-info">1A/2 Resolution Drive, Caringbah NSW 2229</Typography>
            <Typography variant='body1' className="catch-info">Australia</Typography>
          </Grid>
        </Grid>

       
    </div>
     <Grid container sx={{mt:"10%",mb:"2%"}}>
     <Grid item lg={12}>
       <Typography variant='body1' className="catch-info">By signing in or creating an account, you agree with our <u>Terms & conditions</u> and <u>Privacy policy</u></Typography>
      
     </Grid>
   </Grid>
   </>
  }
  </>
  );
}
