import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { DateRange } from "react-date-range";
import { iitineraryContext } from "../../pages/IitineraryContext";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import FlightTakeoffRoundedIcon from "@mui/icons-material/FlightTakeoffRounded";
import Card from "@mui/material/Card";
import { format } from "date-fns";
import axios from "axios";

import Button from "@mui/material/Button";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: "Bearer " + window.sessionStorage.getItem("token"),
  },
});

export default function ChangeDate() {
  const { dates, setDates, expanded, setExpanded, travellerDetail, planId } =
    useContext(iitineraryContext);
  const [selections, setSelections] = useState(-1);
  useEffect(() => {
    setDates([
      {
        startDate: new Date(travellerDetail.startDate),
        endDate: new Date(travellerDetail.endDate),
        key: "selection",
      },
    ]);
  }, []);

  function sendPut(data) {
    console.log("sending");
    api
      .put("/api/plans/" + planId, data)
      .then((res) => {
        console.log("res", res);

        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function changeDate() {
    console.log("date change");
    travellerDetail.startDate = format(dates[0].startDate, "yyyy-MM-dd");
    travellerDetail.endDate = format(dates[0].endDate, "yyyy-MM-dd");
    sendPut(travellerDetail);
  }

  return (
    <Card className="info-cards" sx={{ height: "100% !important" }}>
      <Accordion className="drop-down borderless-drop">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => [setExpanded(expanded ? false : true)]}
        >
          <div className="summary">
            <FlightTakeoffRoundedIcon sx={{ mr: 1 }} />{" "}
            <Typography>Trip Dates</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item lg={12} xs={12}>
              <DateRange
                sx={{ width: "100%" }}
                editableDateInputs={true}
                onChange={(item) => setDates([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={dates}
                rangeColors={["#EFF1FA"]}
              />
            </Grid>
            <Grid container>
              <Grid
                item
                lg={12}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 3,
                }}
              >
                <Button variant="text" sx={{ color: "black" }}>
                  Cencel
                </Button>

                <Button
                  variant="contained"
                  onClick={() => {
                    changeDate();
                  }}
                  disabled={false}
                  className="donebtn applyBtn"
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {expanded === false
        ? dates[0].endDate && (
            <div sx={{ width: "100%" }}>
              <Typography
                className="information-text"
                variant="h6"
                sx={{ mt: 2 }}
              >{`${format(dates[0].startDate, "PP")} - ${format(
                dates[0].endDate,
                "PP"
              )} `}</Typography>{" "}
            </div>
          )
        : ""}
    </Card>
  );
}
