import {React,useState,useEffect,useContext }from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Card from '@mui/material/Card';

import { Grid} from '@mui/material';

import Typography from '@mui/material/Typography';

import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { iitineraryContext } from '../../pages/IitineraryContext';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import axios from "axios";

import { format } from 'date-fns';



const api=axios.create({
  baseURL:process.env.REACT_APP_BASE_URL,
  headers:{Authorization: 'Bearer '+window.sessionStorage.getItem("token")}
});

export default function TravelersInfoModal() {
    const{passengers,expanded,handleChange,setPassengers
        ,addtopassenger,minusfrompassenger,travellersDisable,travellerDetail,planId,setTravellerData,setDataChange
    }=useContext(iitineraryContext);

    useEffect(()=>{
setPassengers({
  numberOfAdults:travellerDetail.tripGuestDetail.numberOfAdults,
  numberOfChildren:travellerDetail.tripGuestDetail.numberOfChildren,
  numberOfRooms:travellerDetail.tripGuestDetail.numberOfRooms
})
console.log(travellerDetail)
    },[])

    function sendPut(data){
      console.log("sending")
      api.put("/api/plans/"+planId,data).then((res)=>{
        console.log("res",res)
        setDataChange(true);
        window.location.reload();
        
              }).catch(error=>{
        
                console.log(error);
              })
    }

    const changeTravellers=()=>{
      
      travellerDetail.tripGuestDetail=passengers;
      travellerDetail.startDate=format(new Date(new Date().setDate(new Date().getDate() + 1)),"yyyy-MM-dd");
      travellerDetail.endDate=format(new Date(new Date().setDate(new Date().getDate() + 7)),"yyyy-MM-dd");
      sendPut(travellerDetail)
      console.log("passed")
      console.log("details all",travellerDetail);

    }
    


    
  return (

    
    <Card className="info-cards">
    <Accordion className="drop-down borderless-drop" 
        // onClick={()=>[setadultExpanded(adultexpanded?false:true)]}
        onChange={handleChange("panel1")}
        expanded={expanded === 'panel1'}
        >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
          <div className='summary'>
       <PersonRoundedIcon sx={{mr:1}}/> <Typography>Travellers</Typography>
       
       </div>
       </AccordionSummary>
      <AccordionDetails >
      <Grid container>
      <Grid item lg={12} xs={12}>
        <div className="people">
            <Grid container sx={{display:"flex"}}>
              <Grid item lg={5} xs={6} sx={{display:"flex",alignItems:"center"}}>
              <Typography variant="h6" component="h6" >Adults</Typography>
              </Grid>
              <Grid item lg={7} xs={6} sx={{display:"flex",alignItems:"center"}}>
              <button className='minus' onClick={minusfrompassenger("adult")}>-</button>
              <h3>{passengers.numberOfAdults}</h3>
              <button className='plus'  onClick={addtopassenger("adult")}>+</button>
              </Grid>
            </Grid>
        </div>

      </Grid>

      </Grid>
      <Grid container>
      <Grid item lg={12} xs={12}>
        <div className="people">
            <Grid container sx={{display:"flex"}}>
              <Grid item lg={5} xs={6} >
              <Typography variant="h6" component="h6">
Children
</Typography>
<Typography variant="caption" component="h2">
Under 11
</Typography>
              </Grid>
              <Grid item lg={7} xs={6} sx={{display:"flex",alignItems:"center"}}>
              <button className='minus' onClick={minusfrompassenger("children")} >-</button>
              <h3>{passengers.numberOfChildren}</h3>
              <button className='plus' onClick={addtopassenger("children")}>+</button>
              </Grid>
            </Grid>
        </div>

      </Grid>

      </Grid>
      <Divider />
      <Grid container>
      <Grid item lg={12} xs={12}>
        <div className="people">
            <Grid container sx={{display:"flex"}}>
              <Grid item lg={5} xs={6} sx={{display:"flex",alignItems:"center"}}>
              <Typography variant="h6" component="h6" >Rooms</Typography>
              </Grid>
              <Grid item lg={7} xs={6} sx={{display:"flex",alignItems:"center"}}>
              <button className='minus' onClick={minusfrompassenger("room")}>-</button>
              <h3>{passengers.numberOfRooms}</h3>
              <button className='plus' onClick={addtopassenger("room")}>+</button>
              </Grid>
            </Grid>
        </div>

      </Grid>

      </Grid>
      <Grid container>
<Grid item lg={12} xs={12} sx={{display:"flex",justifyContent:"space-between",marginTop:3}}>
<Button variant="text" onClick={handleChange("panel1") } sx={{color:"black"}}>Cencel</Button>
<Button variant="contained" onClick={()=>{changeTravellers()}} disabled={travellersDisable}   className="donebtn applyBtn">Apply</Button>
</Grid>

</Grid>
        </AccordionDetails>
    </Accordion>
    {expanded!=="panel1"?passengers.numberOfAdults>0?<Typography className="information-text" variant="body1" >{passengers.numberOfAdults} Adults - {passengers.numberOfChildren>0?<>{passengers.numberOfChildren} Children - </> :""}{passengers.numberOfRooms} Rooms</Typography>:"":"" }
    </Card>
  )
}
