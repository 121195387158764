import {React,useState,useEffect,useContext }from 'react';

import Card from '@mui/material/Card';

import { Grid} from '@mui/material';

import Typography from '@mui/material/Typography';

import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import IconButton from '@mui/material/IconButton';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AddIcon from '@mui/icons-material/AddRounded';

import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { iitineraryContext } from '../../pages/IitineraryContext';




export default function ChangeDestinations() {
    const{destinations,countries,handledestinationcountry,
        addnewdestination,handleminStayDurationChange}=useContext(iitineraryContext)
  return (
    <Card sx={{padding:2,marginTop:2}} className="display-card">
      <div className='summary'>
         <LocationOnRoundedIcon sx={{mr:1}}/> <Typography>Destinations</Typography>
         </div>
{destinations.map((destination,index)=>(
  <>
  {destination.minStayDuration>0?<>
    <div className='destination-stay'>
      <Grid container sx={{alignItems:"center"}}>
        <Grid item lg={5} xs={4.5}>
        <Typography variant="h6" sx={{mr:2,fontSize:16,fontWeight:600}}>{destination.country} :</Typography>

        </Grid>
        <Grid item lg={7} xs={7.5} sx={{display:"flex",alignItems:"center"}}>
        <IconButton aria-label="delete">
<RemoveRoundedIcon sx={{fontSize:16,color:"black"}} onClick={()=>handleminStayDurationChange("-",index)}/>
</IconButton>
<Typography variant="body1" sx={{fontWeight:800}} className="value">{destination.minStayDuration}</Typography>

<IconButton aria-label="delete">
<AddRoundedIcon sx={{fontSize:16,color:"black"}} onClick={()=>handleminStayDurationChange("+",index)}/>
</IconButton>
<Typography variant="body1" sx={{ml:2,fontWeight:600}}>nights</Typography>
        </Grid>
      </Grid>

   
</div>
  </>:""}


{destination.minStayDuration==0?<>
  <Autocomplete
      onChange={(e,newValue)=>{handledestinationcountry(newValue.label,newValue.code,index)}}
      id="country-select-demo"
      sx={{ mt:3,width: "100%",border:"1px solid #E5E7EB",borderRadius:"0.3rem"}}
      options={countries}
      autoHighlight
      key={index}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
        
          {option.label} , {option.code}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
        
          {...params}
          label="Choose a city"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          
        />
      )}
    />

</>:""}
</>

))}


         <Typography variant="body2" component="h2" sx={{display:"flex",alignItems:"center",mt:2}} className="add-destination">

         <AddIcon sx={{cursor:"pointer",mr:1}} onClick={addnewdestination}/> Add New Destination 
</Typography>
      </Card>
  )
}
