import React, { createContext, useContext, useEffect, useState } from 'react';
import http from '../utils/http';

const DataContext = createContext();

export const Contexts = ({ children }) => {
  const [cityName, setCityName] = useState("")
  const [cityName2, setCityName2] = useState("")
  const [cityCode, setCityCode] = useState("")
  const [cityCode2, setCityCode2] = useState("")
  const [cities, setCities] = useState([])
  const [flightType, setFlightType] = useState(false)
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 6)),
      key: "selection",
    },
  ]);
  const [passengers, setPassengers] = useState({
    adults: 2,
    children: 0,
    infants: 0,
    rooms: 1,
  });

  useEffect(() => {
    http
      .get("/Cities")
      .then((response) => {
        // The data from the API response will be available in the 'data' property of the response object
        const data = response.data.data.data;
        setCities(data);
        // mapping countries
      })
      // Process the data or update the UI with the retrieved information
      .catch((error) => {
        // Handle any errors that occur during the request
        console.error("Error fetching data:", error);
      });
    }, []);
  const updateDate = (newData) => {
    setDate(newData);
  };
  const updateCityName1 = (newCityName)=>{
    setCityName(newCityName)
  }
  const updateCityName2 = (newCityName)=>{
    setCityName(newCityName)
  }
  const getFlightType = (newData)=>{
    setFlightType(newData)
  }

  return (
    <DataContext.Provider value={{ date, setDate, updateDate, passengers, setPassengers, cities, flightType, getFlightType, setCityName, setCityCode, cityCode, cityName, setCityName2, setCityCode2, cityCode2, cityName2, updateCityName1, updateCityName2 }}>
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => useContext(DataContext);