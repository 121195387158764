import React, { useEffect } from "react";
import Navbar from "../components/ui-components/Navbar";
import { Box, Grid, Typography } from "@mui/material";
import Footer from "../components/ui-components/Footer";
export default function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <Grid
        item
        xs={12}
        sx={{
          backgroundImage: `url(assets/images/litineraryback.png)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Navbar currentPage="explore" />
      </Grid>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        padding={{ xs: "80px 10vw", md: "80px 20vw", lg: "80px 30vw" }}
        bgcolor="#F3F6FC"
      >
        <Typography
        className="heading"
          textAlign="center"
          color="#14183E"
          fontSize={{ xs: "36px", md: "46px", lg: "53px" }}
          fontWeight="600"
          lineHeight={{xs:'45px', md:'65px', lg:'75px'}}
          marginBottom="36px"
        >
          We're changing the way people plan travel
        </Typography>
        <Typography
          textAlign="center"
          color="#5E6282"
          fontSize="16px"
          fontFamily="inter"
          fontWeight='600'
          lineHeight="30px"
        >
          At Catch U There, we have a deep love for travel. Exploring new
          destinations, immersing ourselves in diverse cultures, and creating
          unforgettable memories is what fuels our passion. However, we
          understand that planning a trip can often be a daunting and
          time-consuming task. That's why we are dedicated to building a
          platform that revolutionizes the way people plan their travels, making
          it easier, simpler, and more enjoyable.
        </Typography>
      </Box>
      <Box
        bgcolor="#3546AB"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        padding={{ xs: "80px 10vw", md: "80px 30vw", lg: "80px 30vw" }}
      >
        <Typography
        className="heading"
          fontSize={{ xs: "34px", md: "35px", lg: "35px"}}
          fontWeight="600"
          color="white"
          textAlign="center"
          marginBottom="36px"
          lineHeight='42px'
        >
          Our Mission
        </Typography>
        <Typography
          textAlign="center"
          color="white"
          fontSize="16px"
          fontFamily="inter"
          fontWeight='600'
          lineHeight="30px"
        >
          Our mission is clear: to become the ultimate one-stop shop for
          planning, booking, and finding travel inspiration. We aim to solve the
          problem of travelers having to rely on multiple sources to research
          their trip, create itineraries, and make bookings. Catch U There
          strives to redefine the travel-booking process and establish itself as
          a global travel brand.
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        padding={{ xs: "80px 10vw", md: "80px 30vw", lg: "80px 30vw" }}
      >
        <Typography
        className="heading"
          textAlign="center"
          color="#14183E"
          fontSize={{ xs: "34px", md: "35px", lg: "35px" }}
          fontWeight="600"
          lineHeight='42px'
          marginBottom="36px"
        >
          Our Culture
        </Typography>

        <Typography
          textAlign="center"
          color="#5E6282"
          fontSize="16px"
          fontFamily="inter"
          fontWeight='600'
          lineHeight="30px"
        >
          At Catch U There, we believe that creating a company where people love
          to work is essential for achieving our mission. We value
          relationships, growth, and impact, and we are committed to cultivating
          a supportive and empowering work environment. <br/> <br/>
          We celebrate the
          contributions of our team members and foster a culture of appreciation
          and recognition. By providing opportunities for personal and
          professional development, we empower our employees to thrive and reach
          their full potential. We understand that our success as a company is
          directly tied to the success of our employees, and we are dedicated to
          supporting them in their journey. <br/> <br/>
          As we strive to become a global
          leader in the travel industry, we remain focused on making a positive
          impact on the lives of both our dedicated employees and our esteemed
          members. Through innovation, dedication, and a deep passion for
          travel, we are determined to create something of lasting value and
          transform the way people plan and experience their travels.
        </Typography>
      </Box>
      <Footer currentPage = "about-us" />
    </Box>
  );
}
