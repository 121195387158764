import React, { createContext,useState } from 'react'

export const session=createContext()

const Session = ({children}) => {
    const [email,setEmail]=useState('');
    const [token,setUserToken]=useState("");
    const [name,setName]=useState('');
    
    const handletoken=()=>{
        setUserToken("dsaf564s6af");
        setEmail("dsaf564s6af");
        console.log("token:",token);
        console.log("email:",email);
        

    }


  return <session.Provider value={{token,setUserToken,handletoken}}>{children}</session.Provider>;
};

export default Session