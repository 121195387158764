import React, { useRef, useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';

const App = () => {
  const [filterHeight, setFilterHeight] = useState(0);
  const filterRef = useRef(null);

  useEffect(() => {
    if (filterRef.current) {
      setFilterHeight(filterRef.current.scrollHeight);
    }
  }, [filterRef.current]);

  return (
    <Container maxWidth="lg" style={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
      <Box
        sx={{
          width: '250px',
          overflowY: 'auto',
        }}
      >
        <div
          ref={filterRef}
          style={{
            position: 'sticky',
            top: 0,
            maxHeight: '100vh',
          }}
        >
          {/* Filters go here */}
          <div style={{ height: '1200px' }}>Filters</div>
        </div>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          height: '100vh',
        }}
      >
        {/* Main content goes here */}
        <div style={{ height: '4000px' }}>Main Content</div>
      </Box>
    </Container>
  );
};

export default App;