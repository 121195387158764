import React, { useEffect } from "react";
import BlogsComponent from "./BlogsComponent";
import { useParams } from "react-router-dom";
import blogsData from "./blogsData";
import { Box, Grid } from "@mui/material";
import Navbar from "../../components/ui-components/Navbar";
import Footer from "../../components/ui-components/Footer";

export default function Blogs() {
  const params = useParams();
  const { blogid } = params;
  const blogs = blogsData.find((b) => b.id == blogid);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          backgroundImage: `url(/assets/images/litineraryback.png)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Navbar currentPage = 'blogs' />
      </Grid>
      <BlogsComponent blogs={blogs} />
      <Footer/>
    </>
  );
}
