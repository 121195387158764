import React, { useState, useContext, useEffect } from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Navbar from "../../components/ui-components/Navbar";
import "./scss/Iitinerary.css";

import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import LockIcon from "@mui/icons-material/Lock";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import ForumIcon from "@mui/icons-material/Forum";
import Leftbar from "../../components/Leftbar";
import Allactivities from "../../components/Allactivities";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import GroupsIcon from "@mui/icons-material/Groups";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import LinkIcon from "@mui/icons-material/Link";
import { iitineraryContext } from "../IitineraryContext";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ChangeInfoModel from "../../components/ui-components/ChangeInfoModel";
import AddItemModel from "../../components/ui-components/AddItemModel";
import IsoIcon from "@mui/icons-material/Iso";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: "Bearer " + window.sessionStorage.getItem("token"),
  },
});

export default function Itinerary() {
  const { toggleChangeInfo, handleaddItemClick, addItemopen, swapBtnClick } =
    useContext(iitineraryContext);

  const [inviteModel, setOpen] = useState(false);

  const handleWindowResize = () => {
    if (window.screen.width <= 820) {
      setScreenType("sm");
    } else {
      setScreenType("lg");
    }
  };

  window.addEventListener("resize", handleWindowResize);
  const [screenType, setScreenType] = useState("lg");

  const [emails, setEmails] = useState([]);
  const [openAlert, setAlert] = React.useState(false);
  const [alertText, setAlertText] = useState("");

  const handleAlertClick = () => {
    setAlert(true);
  };
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlert(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addemail = () => {
    let email = [...emails];
    let data = document.getElementById("mailInput");
    let emaildata = data.value;
    data.value = "";
    email.push(emaildata);
    setEmails(email);
    console.log(emails);
  };

  const checkkey = (event) => {
    if (event.keyCode == 13) {
      addemail();
    }
  };

  const removeEmail = (index) => () => {
    let length = [...emails].length;
    let data = [...emails];
    if (length > 0) {
      data.splice(index, 1);
      setEmails(data);
    }
  };

  const sendInvitations = () => {
    let data = {
      tripId: "a4a2e989-3afb-4e3b-6548-08dab5e458ca",
      emails: emails,
    };
    api
      .post("/api/TripInvitations", data)
      .then((res) => {
        console.log(res);
        setEmails([]);
        setAlertText("Email Invitations has been sent successfuly...!");
        handleAlertClick();
      })
      .catch((error) => {
        console.log("error:", error.response.data);
      });
  };

  const copyInvitationLink = () => {
    setAlertText("Invitations link has been Copied to clipboard...!");
    handleAlertClick();
    //       api.post("api/TripInvitations/getinvitationlink").then((res)=>{
    //         navigator.clipboard.writeText(res.data.message);

    //       }).catch(error=>{
    // console.log(error.data);
    //       })
  };

  const [menuOption, setMenuOption] = useState(false);

  const toggleMenu = () => {
    setMenuOption(!menuOption);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <ChangeInfoModel />
        <AddItemModel
          setvisibility={addItemopen}
          closeFn={handleaddItemClick}
        />

        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleAlertClose}
        >
          <Alert
            onClose={handleAlertClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {alertText}
          </Alert>
        </Snackbar>
        <Dialog
          open={inviteModel}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <Grid container className="cardHeader">
              <Grid item lg={10} xs={10}>
                <div className="cardTop">
                  <Typography variant="h4">
                    <GroupsIcon /> Share with friends
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                lg={2}
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "baseline",
                }}
              >
                <IconButton
                  aria-label="delete"
                  size="large"
                  onClick={handleClose}
                  className="closeBtn"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container sx={{ marginTop: 2 }}>
              <Grid item lg={12} xs={12}>
                <Typography variant="body1">
                  Planning is always better with friends. Add collaborators to
                  help plan your trip.
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item lg={12} xs={12}>
                <div className="emailInputContainer">
                  {emails.map((email, index) => (
                    <>
                      <div className="friendEmail">
                        <Typography sx={{ fontSize: 13, textAlign: "center" }}>
                          {email}
                        </Typography>
                        <IconButton
                          aria-label="delete"
                          onClick={removeEmail(index)}
                        >
                          <CloseIcon sx={{ fontSize: 13 }} />
                        </IconButton>
                      </div>
                    </>
                  ))}

                  <TextField
                    className="emailInput"
                    id="mailInput"
                    onKeyUp={checkkey}
                    variant="outlined"
                    placeholder="Add collaborator’s email"
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item lg={12} className="buttonRow">
                <Button
                  variant="contained"
                  id="sendbtn"
                  onClick={sendInvitations}
                >
                  Send
                </Button>
              </Grid>
            </Grid>

            <Divider sx={{ margin: "2% 0" }} />

            <Grid container>
              <Grid item lg={7}>
                <Typography variant="h5" sx={{ fontWeight: 700 }}>
                  Share a link
                </Typography>
                <Typography variant="body1">
                  Anyone with the link can access the itinerary.
                </Typography>
              </Grid>
              <Grid
                item
                lg={5}
                sx={{ display: "flex", alignItems: "end", width: "100%" }}
              >
                <Button
                  variant=""
                  id="linkIcon"
                  startIcon={<LinkIcon />}
                  onClick={copyInvitationLink}
                >
                  Copy invite link
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
      {/* Main-header */}
      <Grid
        container
        className="main-header"
        sx={{
          backgroundImage: `url(assets/images/litineraryback.png)`,
          backgroundSize: "cover",
        }}
      >
        <Grid item lg={12} xs={12}>
          <Navbar />
        </Grid>
        <Grid item lg={12} sm={12} xs={12}>
          <Grid
            container
            className="headerContent"
            sx={{ padding: 4, color: "white" }}
          >
            <Grid item lg={0.4}></Grid>
            <Grid item lg={6}>
              <Typography variant="h3" sx={{ fontWeight: 800, mb: 1 }}>
                Europe Trip
              </Typography>

              <Typography
                variant="body1"
                sx={{ color: "#D8DCF3" }}
                onClick={() => {
                  if (screenType == "sm") {
                    toggleChangeInfo("changedate");
                  }
                }}
              >
                9th September 2021 - 4th October 2021 (25 days)
              </Typography>
            </Grid>
            <Grid item lg={5} sm={12} xs={12} className="header-btns">
              <Grid
                container
                sx={{
                  dislpay: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: 4,
                }}
              >
                <Grid item lg={5} sm={3} xs={5.5}>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    sx={{ backgroundColor: "#E5E5E5", color: "black" }}
                    className="header-btn"
                    onClick={handleOpen}
                  >
                    Invite Friends
                  </Button>
                </Grid>
                <Grid item lg={5} sm={3} xs={5.5}>
                  <Button
                    variant="contained"
                    startIcon={<LockIcon />}
                    sx={{ backgroundColor: "#6373CF", color: "white" }}
                    className="header-btn"
                  >
                    Finalise Itinerary
                  </Button>
                </Grid>
                <Grid item lg={2} sm={3} xs={1}>
                  <IconButton aria-label="setting" sx={{ color: "white" }}>
                    <SettingsIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Main body */}

      <Grid container className="rest-body">
        <Grid item lg={12} xs={12}>
          {/* Tabs */}
          <Grid container sx={{ padding: 2 }}>
            <Grid
              item
              lg={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "fit-content",
              }}
            >
              <div className="buttons tabButtons">
                <Button
                  variant="contained"
                  sx={{ fontWeight: 900 }}
                  className="tab-btn activeTab"
                >
                  Trip itinerary
                </Button>
                <IconButton
                  className="tab-btn"
                  sx={{ display: { xs: "block", lg: "none" } }}
                  onClick={() => {
                    if (screenType == "sm") {
                      toggleChangeInfo("changetraveling");
                    }
                  }}
                >
                  <IsoIcon />
                </IconButton>
                <Button variant="contained" className="tab-btn">
                  Activity <div className="notify">5</div>
                </Button>
                <IconButton
                  aria-label="delete"
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  sx={{ display: { xs: "block", lg: "none" } }}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  className="menu"
                >
                  <MenuItem onClick={handleMenuClose}>
                    <Button variant="contained" className="tab-btn">
                      Budget Tools
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose}>
                    <Button variant="contained" className="tab-btn">
                      Favorities
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose}>
                    <Button variant="contained" className="tab-btn">
                      Change route
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose}>
                    <Button variant="contained" className="tab-btn">
                      Shuffle trip
                    </Button>
                  </MenuItem>
                </Menu>
                {/* <Button variant="contained"    className="tab-btn" sx={{display:{xs:"none",sm:"block"}}}>
      Budget planner
      </Button> */}
              </div>
              {/* <Button variant="contained" startIcon={<ForumIcon />} sx={{display:{xs:"none",sm:"flex"}}}  className="tab-btn chat-btn">
 Chat
      </Button> */}
            </Grid>
          </Grid>

          <hr className="divider" />

          {/* Main Body */}
          <Grid
            container
            sx={{
              paddingLeft: { xs: 1, sm: 4 },
              paddingRight: { xs: 1, sm: 0 },
              paddingTop: 4,
            }}
            className="main-body"
          >
            <Grid
              item
              lg={2.7}
              xs={0}
              sx={{ display: { xs: "none", lg: "block" } }}
            >
              <Leftbar />
            </Grid>
            <Grid item lg={0.2}></Grid>
            <Grid item lg={9} xs={12}>
              <Allactivities />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
