import React, { useEffect } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import ShareIcon from "@mui/icons-material/Share";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from "react-router-dom";

export default function BlogsComponent({ blogs }) {
  // useEffect(()=>{
  //   document.title= blogs.title;
  // },[])
  return (
    <>
    <head>
      <title>{blogs.title}</title>
      <meta charSet="UTF-8"/>
      {blogs.meta.map((meta)=>{
        return(
          <>
          <meta name={meta.name} content={meta.content}/>
          </>
        )
      })}
    </head>
      <Box padding="0 10vw">
        <Typography
          padding={{xs:'32px 0', md:"32px 5vw"}}
          variant="h3"
          fontWeight="600"
          fontSize={{ xs: "36px", lg: "45px" }}
          textAlign={{xs:'left', md:'center'}}
        >
          {blogs.heading}
        </Typography>
        <Box display="flex" flexDirection="column" justifyContent="center">
          {/* <Typography
            color="black"
            padding="2vh 0"
            fontWeight="400"
            variant="subtitle"
          >
            {blogs.cityName} / {blogs.location}
          </Typography> */}
          <img
            src={blogs.bannerImg.url}
            alt={blogs.bannerImg.altText}
            title={blogs.bannerImg.title}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" padding="5vh 0">
          <Box display="flex" gap={2} alignItems="center">
            <Box borderRadius="50%" overflow='hidden' width='70px' height='70px'>
              {blogs.authorImgSmall ?
              <img
                src={blogs.authorImgSmall}
                width="70px" height='70px'
              />
              :
              <img src="/assets/images/userimg.png" 
              width="70px" height='70px'/>
}
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography variant="overline" fontSize="18px">
                {blogs.author}
              </Typography>
              <Typography variant="subtitle" fontSize="14px">
                {blogs.date}
              </Typography>
            </Box>
          </Box>
        </Box>
        <div style={{display:'flex', justifyContent:'center'}}>
        <Box padding={{xs:'32px 0px', md:"32px 5vw"}} maxWidth='600px'>
          {blogs.content.map((item, index) => {
            switch (item.type) {
              case "intro-para":
                return item.text.map((item) => {
                  return (
                    <Typography className="text-readability" padding="0 0 2em" color="#121416" fontSize="16px">
                      {item.text}
                    </Typography>
                  );
                });
              case "tableOfContents":
                return (
                  <>
                    <Typography
                    className="text-readability"
                      padding="2vh 0"
                      fontFamily="Inter"
                      fontWeight="600"
                      color="#121416"
                      fontSize="28px"
                    >
                      Table of Contents
                    </Typography>
                    {item.text.map((item) => {
                      return (
                        <>
                          <ul>
                            <li>
                              <Typography className="text-readability" sx={{ textDecoration: "underline", textDecorationColor:'blue' }}>
                                {item.text}
                              </Typography>
                            </li>
                          </ul>
                        </>
                      );
                    })}
                  </>
                );
              case "paragraph":
                return (
                  <>
                    <h2 id={item.id} className="text-readability" style={{ padding: "32px 0 1vh" }}>
                      {item.heading}
                    </h2>
                    {item.text.map((item) => {
                      return (
                        <Typography className="text-readability" padding="0 0 2em"  variant="body1">
                          {item.text}
                        </Typography>
                      );
                    })}
                  </>
                );
              case "image":
                return (
                  <Box padding="32px 0 0">
                    <img src={item?.url} alt={item?.altText} width="100%" />
                  </Box>
                );
              case "key-takeaway":
                return (
                  <Box bgcolor="#f0f8ff" padding="30px 30px" margin="32px 0 0">
                    <Typography variant="h6" padding="1vh 0">
                      Key Takeaway:
                    </Typography>
                    <Typography className="text-readability" padding="0 0 2em" variant="body1">
                      {item.text}
                    </Typography>
                  </Box>
                );
              default:
                break;
            }
          })}
        </Box>
        </div>
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' padding={{xs:'10vh 2vw', md:'10vh 10vw'}}>
          <Typography color='#6373CF' fontSize='16px' fontWeight='600' fontFamily='Inter' pb='5vh'>MEET THE AUTHOR</Typography>
          <Grid container borderRadius="30px" bgcolor='#F3F5F6'>
          <Grid item xs={12} md={4} padding={{xs:'2vh 2vw 5vh', md:'0'}}>
            {blogs.authorImgLarge ?
            <img src={blogs.authorImgLarge}
            style={{
              maxWidth: '100%',
              width: '100%',
              height: 'auto',
              display: 'block',
              margin: 'auto',
              borderRadius:'30px'
            }}/>
            :
            <img src="/assets/images/userimg.png" style={{
              
              maxWidth: '100%',
              width: '100%',
              height: 'auto',
              display: 'block',
              margin: 'auto',
              borderRadius:'30px'
            }}/>
          }
          </Grid>
          <Grid item xs={12} md={8}>
            <Box padding='0 50px' height='100%' display='flex' flexDirection='column' justifyContent='center' gap={1}>
            <Typography color='#170F49' fontSize='34px' fontWeight='700' fontFamily='Inter' textAlign={{xs:'center', md:'left'}}>
              {blogs.author}
            </Typography>
            <Typography color='#6373CF' fontSize='18px' fontWeight='600' fontFamily='Inter' textAlign={{xs:'center', md:'left'}}>
              {blogs.authorDesignation}
            </Typography>
            <Typography color='#6F6C90' fontSize='18px' fontWeight='400' fontFamily='Inter' textAlign={{xs:'center', md:'left'}}>
              {blogs.authorMessage}
            </Typography>
            <Box display='flex' justifyContent={{xs:'center', md:'start'}}>
              <IconButton href={blogs.authorSocialLinks?.linkedIn}>
              <LinkedInIcon fontSize="large"/>
              </IconButton>
            </Box>
            </Box>
          </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
