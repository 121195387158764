import { React, useState, useEffect, createContext } from "react";
import Grid from "@mui/material/Grid";
import Navbar from "../../components/ui-components/Navbar";
import { OutlinedInput, Paper, Typography, useMediaQuery } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DateRange } from "react-date-range";
import { format } from "date-fns";
import PersonIcon from "@mui/icons-material/Person";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./scss/HomePage.css";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Slider from "@mui/material/Slider";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import axios from "axios";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Footer from "../../components/ui-components/Footer";
import { session } from "../Sessions";
import { useContext } from "react";
import Mobilefooter from "../../components/ui-components/Mobilefooter";
import Cookies from "universal-cookie";
import PaidIcon from "@mui/icons-material/Paid";

// trying

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import DestinationPicker from "../../components/ui-components/DestinationPicker";
import HotelIcon from "@mui/icons-material/Hotel";
import BookingModel from "../../components/ui-components/BookingModel";
import ContentSection from "../../components/ui-components/ContentSection";
import HotelsList from "../Hotels-list/HotelsList";
import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.production";

export default function LandingPage() {
  let isLargeScreen = useMediaQuery("(min-width:992px)");
  let isMediumScreen = useMediaQuery("(min-width:768px)");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // states for feild open and close

  // state opening and closing checks

  // const handleDateRange = (event, newValue) => {
  //   setDaysRange(newValue);
  // };

  const addnewdestination = () => {
    // let destinations = [...destinationsExpands];
    // if (destinationsExpands[0] == true) {
    //   destinations[0] = false;
    //   setdestinationExpand(destinations);
    // } else {
    //   destinations[0] = true;
    //   setdestinationExpand(destinations);
    // }
    // let length = [...alldestinations].length;
    // if (length + 1 < 11) {
    //   setDestinations([
    //     ...alldestinations,
    //     { city: "", country: "", minStayDuration: 1, maxStayDuration: 2 },
    //   ]);
    //   if (length + 2 > 10) {
    //     setmaxDestination(false);
    //   }
    // } else {
    //   // here i will update value of maxdestination to true if its > 11 and will display it under add destination
    // }
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        container
        height={{ xs: "auto", md: "auto", lg: "auto" }}
        sx={{
          backgroundImage: {
            xs: "none",
            md: `url('./assets/images/landing-page-blob.svg')`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "100% 0%",
            backgroundSize: "50%",
          },
        }}
      >
        <Grid item container xs={12}>
          <Navbar currentPage="landing-page" />
        </Grid>

        <Grid
          item
          container
          xs={12}
          marginTop="0"
          padding={{ xs: "0 5vw", md: "0 0 0 5vw", lg: "0 0 0 7vw" }}
          display="flex"
          alignItems={{ xs: "flex-start", md: "center", lg: "center" }}
          justifyContent="center"
        >
          <Grid item lg={7} md={6} xs={12}>
            <Typography
              className="heading"
              color="#181E4B"
              fontWeight="700"
              fontSize={{ xs: "40px", md: "38px", lg: "74px" }}
              lineHeight={{ xs: "normal", md: "normal", lg: "89px" }}
              letterSpacing={{ xs: "-1.6px", md: "-1.92px", lg: "-3.36px" }}
              width={{ xs: "auto", md: "auto", lg: "50vw" }}
              marginBottom={{ xs: "0", md: "16px", lg: "32px" }}
              mt={{xs:'3vh', lg:'0px'}}
            >
              The Ultimate Trip <br />
              Planning Solution
            </Typography>
            <Typography
              paddingTop={{ xs: "2vh", lg: "1vh" }}
              marginBottom={{ xs: "16px", md: "16px", lg: "5vh" }}
              lineHeight={{ xs: "170%", md: "24px", lg: "30px" }}
              color="#5E6282"
              fontWeight="600"
              fontSize={{ xs: "16px", md: "16px", lg: "18px" }}
              fontFamily="Inter"
              width={{ xs: "auto", md: "400px", lg: "550px" }}
            >
              <b>Welcome to Catch U There</b> – the future of effortless travel
              planning. Get ready to simplify your trips with AI-driven
              itineraries, combining flights, hotels, and experiences in one
              easy search. Coming soon – your adventure starts here!
            </Typography>
            <HashLink smooth to="#subscribe">
              <Button
                variant="contained"
                sx={{
                  width: { xs: "100%", md: "auto", lg: "auto" },
                  bgcolor: "#6373CF",
                  textTransform: "none",
                  padding: "10px 30px",
                  color: "white",
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                Subscribe
              </Button>
            </HashLink>
          </Grid>
          <Grid
            item
            lg={5}
            md={6}
            xs={12}
            display="flex"
            marginTop="3vh"
            justifyContent="center"
            alignItems="center"
            position="relative"
            boxSizing="border-box"
            overflow="hidden"
            // sx={{
            //   backgroundImage: `url(assets/images/landing-background.svg)`,
            //   backgroundSize:'100%',
            //   backgroundPosition: "50% 50%",
            //   backgroundRepeat: "no-repeat",
            // }}
          >
            {/* <svg
              style={{
                position: "absolute",
                top: "-5vh",
                right: "0",
                zIndex: "-1",
                overflow: "visible",
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 704 724"
              fill="none"
            >
              <path
                d="M22.3069 222C-18.0931 153.2 5.4736 13.3333 22.3069 -48L701.807 -149L766.307 122.5L727.807 723.5C649.14 724.5 473.707 717 401.307 679C310.807 631.5 351.807 555 276.807 515C201.807 475 244.807 369.5 218.807 329.5C192.807 289.5 72.8069 308 22.3069 222Z"
                fill="#FFF1DA"
              />
            </svg> */}
            <Box
              display={{ xs: "block", md: "none" }}
              position="absolute"
              zIndex="-1"
            >
              <img
                src="./assets/images/landing-page-blob.svg"
                width="100%"
                height="100%"
              />
            </Box>
            <img
              src="./assets/images/lp-cover.webp"
              alt="A woman perched on a suitcase, with a backpack at her side, embodies the spirit of exploration and journey."
              width="100%"
            />
            {/* {isLargeScreen ? (
              <img src="./assets/images/lp-desktop.png" width="100%" />
            ) : isMediumScreen ? (
              <img src="./assets/images/lp-tablet.png" width="100%" />
            ) : (
              <img src="./assets/images/lp-mobile.png" width="100%" />
            )} */}
            {/* <BookingModel currentPage="home1" /> */}
          </Grid>
        </Grid>
      </Grid>

      {/*Rest of Body */}
      <Grid item xs={12}>
        <ContentSection currentPage="landing-page" />
      </Grid>
      {/* Footer */}
      <Grid item xs={12}>
        <Footer currentPage="landing-page" />
      </Grid>
    </Grid>
  );
}
