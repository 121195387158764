import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Button, SvgIcon } from '@mui/material';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
const HotelNotFound = () => {

  return (
    <Box sx={{ p: 3 }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
      <SvgIcon sx={{ fontSize:'100px', color:'#808080' }}>
      // Your icon SVG path or component here
      <HomeWorkOutlinedIcon/>
    </SvgIcon>
      <Typography color="#808080" variant="h4" sx={{ mb: 2 }}>
        No match found
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        The Hotel you are looking for does not exist or has been removed.
      </Typography>
    </Box>
  );
};

export default HotelNotFound;
