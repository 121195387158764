import { Button, Typography, Box } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';



export default function PageNotFound() {
  return (
    <>
    <Box height='100vh' gap={5} display='flex' flexDirection='column' justifyContent="center" padding='1em' alignItems='center'>
    <Typography color='red' fontSize='150px'>
        404
    </Typography>
    <Typography fontSize='35px' color="#808080">
    Oop, page Not found
    </Typography>
    
    <Typography fontSize='18px' color="#808080">
        The requested page could not be found on this server <ErrorOutlineIcon fontSize='small'/>
    </Typography>
    <Link to='/'>
        <Button variant='contained'>Back to home</Button>
    </Link>
    </Box>
    </>
  )
}
