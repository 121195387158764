import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Grid } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import FlightIcon from '@mui/icons-material/Flight';
import Button from '@mui/material/Button';
import "./scss/Navdrawer.css";
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';


const drawerWidth = "100%";

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function NavDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
    
      <Drawer
        sx={{
        
          width: "auto",
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader sx={{padding:"2% 3%",paddingBottom:0}}>
          <Grid container sx={{display:"flex",justifyContent:"space-between"}}>
            <Grid item xs={9}>
<img src="./assets/images/logofiled.png" alt="" />
            </Grid>
            <Grid item xs={3} sx={{textAlign:"right"}}>
            <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ClearIcon /> : <ClearIcon />}
          </IconButton>
</Grid>
          </Grid>
      
        </DrawerHeader>
       <Grid container >
        <Grid item xs={12} className="menu-items">
        <Button  startIcon={<CardTravelIcon /> } className="item">Trip Planner</Button>
        <Button  startIcon={<FlightIcon /> } className="item">Flights</Button>
        <Button  startIcon={<HotelOutlinedIcon /> } className="item">Hotels</Button>
        <Button  startIcon={<DirectionsCarFilledOutlinedIcon /> } className="item">Car Hire</Button>
        <Button  startIcon={<LocationOnOutlinedIcon /> } className="item">Experinces</Button>

        <Divider sx={{mt:1,mb:1}}/>

        <Typography className="sub-heading">Need help?</Typography>

        <Button  startIcon={<HelpOutlineOutlinedIcon /> } className="item">Help Center</Button>
        <Button  startIcon={<EmailOutlinedIcon /> } className="item">Contact Us</Button>
        


        </Grid>
       </Grid>
      </Drawer>
      
    </Box>
  );
}
