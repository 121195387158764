import React from 'react'
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';
import "./scss/Mobilefooter.css"
import Divider from '@mui/material/Divider';
export default function Mobilefooter() {
  return (
    <></>
//     <Grid container className="mobile-footer" sx={{display:{xs:"block",lg:"none"}}}>
//         <Grid item xs={12} sx={{display:'flex',justifyContent:"center",padding:"4% 0"}}>
//             <img src="./assets/images/logofiled.png" alt=""  />
//         </Grid>
//         <Divider />
//         <Grid container sx={{padding:3}} spacing={1}>
//             <Grid item xs={7}>
//             <Typography className="primary-text sub-heading">Discover</Typography>
// <Link to="" ><Typography  >Catch ya Blog</Typography></Link>
// <Link to="" ><Typography  >Cities/regions</Typography></Link>
// <Link to="" ><Typography  >Countries</Typography></Link>
// <Link to="" ><Typography  >Popular Itineraries</Typography></Link>
// <Link to="" ><Typography  >Travel guides</Typography></Link>
// <Link to="" ><Typography  >Catch ya Blog</Typography></Link>

// <Typography className="primary-text sub-heading" sx={{mt:"20% !important"}}>Company</Typography>
//           <Link to="" ><Typography>About us</Typography></Link>
//           <Link to="" ><Typography>Company details</Typography></Link>
//           <Link to="" ><Typography>Why Catch U There</Typography></Link>
//           <Link to="" ><Typography>News and Media</Typography></Link>
//           <Link to="" ><Typography>Cookie policy</Typography></Link>
//           <Link to="" ><Typography>Privacy Policy</Typography></Link>
//           <Link to="" ><Typography>Terms and conditions</Typography></Link>
//             </Grid>
//             <Grid item xs={5}>
//             <Typography className="primary-text sub-heading" >More</Typography>
//           <Link to="" ><Typography  >Trip planner</Typography></Link>
//           <Link to="" ><Typography  >Flights</Typography></Link>
//           <Link to="" ><Typography  >Accommodation</Typography></Link>
//           <Link to="" ><Typography  >Car Hire</Typography></Link>
//           <Link to="" ><Typography  >Airlines</Typography></Link>
//           <Link to="" ><Typography  >Airports</Typography></Link>
//           <Link to="" ><Typography  >Travel insurance</Typography></Link>
//           <Link to="" ><Typography  >Coronavirus travel advice</Typography></Link>


//           <Typography className="primary-text sub-heading" sx={{mt:"20% !important"}}>Help</Typography>
// <Link to="" ><Typography>Help/FAQ</Typography></Link>
// <Link to="" ><Typography>Privacy setings</Typography></Link>
// <Link to="" ><Typography>Security</Typography></Link>
//             </Grid>
//         </Grid>

//         <Divider sx={{mt:2}}/>
//         <Grid container>
//             <Grid item xs={12} sx={{textAlign:"center"}}>
//                 <Typography variant="body2" className="small-heading">Need Help?</Typography>
//                 <Typography className="primary-text sub-heading">Contact Us</Typography>
//                 <Grid container sx={{display:"flex",justifyContent:"center"}} >
//           <Grid item lg={5} sx={{borderRight:"1px solid black",pr:2}}>
//           <Typography className="primary-text">Customer Service</Typography>
//           </Grid>
//           <Grid item lg={6} sx={{ml:2}}><Typography className="primary-text">Help Center</Typography></Grid>
//         </Grid>

//         <Typography variant="body2" className="small-heading">Subscribe</Typography>
//             <Typography className="primary-text sub-heading">Wanna receive email from us?</Typography>
//             <Typography><span className="primary-text"> Sign up</span> to get the latest deals</Typography>

//             <Typography variant="body2" className="small-heading">Connect with us</Typography>
//             <Grid container spacing={0.8} sx={{display:"flex",justifyContent:"center",mt:2}}>
//       <Grid item xs={1.3} lg={2}>
//           <img src="assets/images/icons/twitter.png" alt="" className='footer-icon'/>
//           </Grid>
//           <Grid item xs={1.3}lg={2}>
//           <img src="assets/images/icons/facebook.png" alt="" className='footer-icon'/>
//           </Grid>
//           <Grid item xs={1.3}lg={2}>
//           <img src="assets/images/icons/instagram.png" alt="" className='footer-icon'/>
//           </Grid>
//           <Grid item xs={1.3}lg={2}>
//           <img src="assets/images/icons/youtube.png" alt="" className='footer-icon'/>
//           </Grid>
//           <Grid item xs={1.3} lg={2}>
//           <img src="assets/images/icons/linkedin.png" alt="" className='footer-icon'/>
//           </Grid>
//           <Grid item xs={1.3}lg={2}>
//           <img src="assets/images/icons/pintrest.png" alt="" className='footer-icon'/>
//           </Grid>
         

//       </Grid>

//       <Divider sx={{mt:2}}/>

//       <Typography className="copy-right">© 2022 Catch U There Pty Ltd All Rights Reserved.</Typography>
//       <Typography className="copy-right">Use of the Catch U There websites and our
// Company Information constitutes acceptance of our <u className='primary-text'>Terms and Conditions</u> and <u className='primary-text'>Privacy Policy</u></Typography>

//             </Grid>


            
//         </Grid>
      
//     </Grid>
  )
}
