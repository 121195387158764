import React, {
  useEffect,
  useState
} from 'react'
import axios from 'axios';
import VerificationModel from '../../components/ui-components/VerificationModel';
import Restpassword from "./Restpassword";
import {
  useLocation
} from "react-router-dom";
export default function Verificationforrest() {
  const [verified, setVerification] = useState(true);
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const queryString = useLocation().search;
  
  useEffect(() => {
    let newtoken = new URLSearchParams(queryString).get('token');
    newtoken = newtoken.replaceAll(" ", "+");
    const urlParams = new URLSearchParams(queryString);
    const newemail = urlParams.get('email');
    console.log(newemail);
    console.log(newtoken);
    setToken(newtoken)
    setEmail(newemail)
    let data = {
      email: newemail,
      token: newtoken,
    }
    console.log(data);

    axios.post("https://localhost:7195/api/Account/VerifyPasswordResetToken", data).then((res) => {
      if (res.status == 200) {
        setVerification(true)

      }

    }).catch(error => {

      console.log(error.response.data)
      setVerification(false)
    })
  }, []);
  return ( <> {!verified ?<VerificationModel header = "Token Expired"
      color = "red"
      page = "/forgotpassword"
      sub = "Your token for rest has been expired.Please try again..!!"
      type = "Try Again" / > : <Restpassword token={token} email={email}/>
    } </>
  )
}
