import React,{useContext} from 'react'
import { Grid} from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import HotelIcon from '@mui/icons-material/Hotel';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { iitineraryContext } from '../../pages/IitineraryContext';



export default function AddItemOptions(props) {

  const{addItemModel,handleaddItemClick,displayPage,changePage,setdisplayPage}=useContext(iitineraryContext)
        
   
  return (
    <>
     <Grid container  className='cardHeader'>
            <Grid item lg={12}  xs={12} className="cardTop">
                <Typography variant='h4'>Add Item</Typography>
                <IconButton aria-label="delete" size='large' onClick={handleaddItemClick}>
  <CloseIcon />
</IconButton>
             </Grid>
             </Grid>

             <Grid container className='addItemOptions'>
              <Grid item lg={12} xs={12}>
<Typography variant='h6'>What would you like to add?</Typography>

<Grid container  sx={{display:"flex",justifyContent:"space-evenly",padding:"4% 3%"}}>
  <Grid item lg={3} xs={9} className="item">
  <Card className='itemCard' onClick={()=>changePage("addaccomodation")}>
      <CardContent>
      <HotelIcon className='itemIcon' sx={{color:"#E44E4E"}}/>
      </CardContent>
   
    </Card>

    <Typography variant='h6'>Accomodation</Typography>
  </Grid>
  <Grid item lg={3} xs={9}>
  <Card className='itemCard' onClick={()=>changePage("experience")}>
      <CardContent>
      <LocationOnIcon className='itemIcon'sx={{color:"#6373CF"}} />
      </CardContent>
   
    </Card>

    <Typography variant='h6'>Experience</Typography>
  </Grid>
  <Grid item lg={3} xs={9}>
  <Card className='itemCard' onClick={()=>changePage("transport")}>
      <CardContent>
      <DirectionsCarIcon className='itemIcon' sx={{color:"#F2A818"}}/>
      </CardContent>
   
    </Card>

    <Typography variant='h6'>Transport Hire</Typography>
  </Grid>
</Grid>

              </Grid>

             </Grid> 
    </>
  )
}
