import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Link } from 'react-router-dom';

export default function ComingSoon(){
  return (
    <>
    <Box height='100vh' gap={5} display='flex' flexDirection='column' justifyContent="center" padding='1em' alignItems='center'>
    <Typography color='green' fontSize='100px'>
        Coming Soon
    </Typography>
    <Typography fontSize='35px' color="#808080">
    Great things coming soon !
    </Typography>
    
    <Typography fontSize='18px' color="#808080">
        The requested page could not be found on this server <ErrorOutlineIcon fontSize='small'/>
    </Typography>
    <Link to='/'>
        <Button variant='contained'>Back to home</Button>
    </Link>
    </Box>
    </>
  );
};
