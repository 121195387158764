import { React, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Grid } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import FlightIcon from '@mui/icons-material/Flight';
import Button from '@mui/material/Button';
import "../scss/Navdrawer.css";
import SecurityIcon from '@mui/icons-material/Security';
import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import LuggageIcon from '@mui/icons-material/Luggage';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HotelIcon from '@mui/icons-material/Hotel';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LanguageIcon from '@mui/icons-material/Language';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';

const drawerWidth = "100%";

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function ProfileDrawer() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState('Language');
  const [currency, setCurrency] = useState('AUD');
  const handleLanguage = (event) => {
    setLanguage(event.target.value);
  };
  const handleCurrency = (event) => {
    setCurrency(event.target.value);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      

      <Drawer
        sx={{

          width: "auto",
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <Grid container sx={{ ml: 2}}>
          
          <Grid item lg={2} sm={4} xs={12}>
            <Button
              onClick={handleDrawerClose}
            >
              <img src='assets/images/mobile-nav-logo.svg' className='logo'/>
            </Button>
          </Grid>
          <Grid item xs={12} className="menu-items">
            <Link><Button startIcon={<WorkOutlineIcon />} className="item">Trip Planner</Button></Link>
            <Link><Button startIcon={<FlightTakeoffIcon />} className="item">Flights</Button></Link>
            <Link><Button startIcon={<HotelIcon />} className="item">Hotels</Button></Link>
            <Link><Button startIcon={<DirectionsCarIcon />} className="item">Car Hire</Button></Link>
            <Link><Button startIcon={<LocationOnIcon />} className="item">Experiences</Button></Link>

            <Divider sx={{ mt: 1, mb: 1 }} />

            <Typography className="sub-heading">Need help?</Typography>

            <Button startIcon={<HelpOutlineIcon />} className="item">Help Center</Button>

            <Button startIcon={<MailOutlineIcon />} className="item">Contact Us</Button>

          </Grid>
        </Grid>
      </Drawer>

    </Box>
  );
}