import React from 'react'
import { Grid} from '@mui/material';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Countryactivity from './Countryactivity';

export default function Allactivities() {
  return (
    <Grid container className="all-activities">
        <Grid item lg={12} xs={12}>
        <Countryactivity/>
        </Grid>
      
    </Grid>
  )
}
