import React from 'react'
import ChangeDestinations from './ChangeDestinations'
import TravelersInfoModal from './TravellersInfoModal'

export default function ChangeTravling() {
  return (
    <>
      <TravelersInfoModal/>
      <ChangeDestinations/>
    </>
  )
}
