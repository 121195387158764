import React,{useState,useContext} from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import AddItemOptions from './AddItemOptions';
import AddExperience from './AddExperience';
import "../../pages/Iitinerary/scss/Iitinerary.css";
import SwapAccomodation from './SwapAccomodation';
import SwapFlight from './SwapFlight';
import AddAccomodation from "./AddAccomodation";
import { iitineraryContext } from '../../pages/IitineraryContext';
import AccomodationDetail from './AccomodationDetail';
import ChangeDate from './ChangeDate';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function AddItemModel(props) {
  
  const{addItemModel,handleaddItemClick,displayPage,changePage,setdisplayPage}=useContext(iitineraryContext)


  return (
    <>
       
       <Dialog
        open={props.setvisibility}
        TransitionComponent={Transition}
        keepMounted
       className='addItem'
        aria-describedby="alert-dialog-slide-description"
      >

        <DialogContent >
          {displayPage=="home"?<AddItemOptions/>:""}
          {displayPage=="experience"?<AddExperience/>:""}
          {displayPage=="swapaccomodation"?<SwapAccomodation/>:""}
          {displayPage=="swapflight"?<SwapFlight/>:""}
          {displayPage=="addaccomodation"?<AddAccomodation/> :""}

          {/* <AccomodationDetail/> */}

        </DialogContent>
      
      </Dialog>
    </>
  )
}
