import { React, useState, useEffect, useContext } from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { Doughnut } from "react-chartjs-2";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Chart, ArcElement } from "chart.js";
import { iitineraryContext } from "../pages/IitineraryContext";
import Cookies from "universal-cookie";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import TravelersInfoModal from "./ui-components/TravellersInfoModal";
import ChangeDate from "./ui-components/ChangeDate";
import ChangeDestinations from "./ui-components/ChangeDestinations";
Chart.register(ArcElement);

export default function Leftbar() {
  const cookies = new Cookies();
  const { travellerDetail, setTravellerData } = useContext(iitineraryContext);

  useEffect(() => {
    //setTravellerData(JSON.parse(localStorage.getItem('planDetail')));
    console.log("travellers Data:", travellerDetail);
  }, []);

  const data = {
    labels: ["Red", "Blue", "Yellow"],

    datasets: [
      {
        label: "My First Dataset",
        data: [300, 50, 100],
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(255, 205, 86)",
        ],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <div className="left-bar">
      {travellerDetail ? (
        <>
          <TravelersInfoModal />

          {/* Dates Information */}
          <ChangeDate />

          {/* Destination Information */}

          <ChangeDestinations />

          {/* Budget Information */}

          <Card sx={{ padding: 2 }} className="display-card budget-card">
            <div className="summary">
              <AccountBalanceWalletIcon sx={{ mr: 1 }} />{" "}
              <Typography>Trip Budget</Typography>
              <div className="budget-button">
                <Typography variant="body1" sx={{ ml: 4, float: "right" }}>
                  {" "}
                  Budget planner{" "}
                </Typography>{" "}
                <ArrowForwardIcon sx={{ cursor: "pointer", ml: 0.5 }} />
              </div>
            </div>
            <TextField
              id="outlined-start-adornment"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              className="budget-field"
              value={1000}
            />

            <Doughnut data={data} />
          </Card>
        </>
      ) : (
        <>
          <Stack sx={{ width: "100%" }}>
            <Skeleton
              variant="rounded"
              width={"95%"}
              height={60}
              sx={{ mt: 4 }}
            />
            <Skeleton
              variant="rounded"
              width={"95%"}
              height={60}
              sx={{ mt: 4 }}
            />
            <Skeleton
              variant="rounded"
              width={"95%"}
              height={60}
              sx={{ mt: 4 }}
            />
          </Stack>
        </>
      )}
    </div>
  );
}
