import {React,useState }from 'react'
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import "./scss/Signup.css";
import ErrorIcon from '@mui/icons-material/Error';
import axios from 'axios';
export default function Forgotpassword() {
  

  const [email,setEmail]=useState('');
  const [emailerror,setEmailError]=useState(false);
  const [buttontext,setButtontext]=useState('Send reset link');
  const [headertext,setHeadertext]=useState('Forgot your password?');
  const [mailsent,setMailsent]=useState(false);
  const [emailborder,setEmailborder]=useState('#98989E')
  const [loading,setloading]=useState(false);
  const [responseerror,setResponseError]=useState("");

  const error =value=>()=>{
    
    if(value=="email")
    {
      console.log(value)
      return "red !important"
    }
  }

  const mailsend=()=>{
    if(!mailsent){
    let data={
      email:email,
    }
    setloading(true);
    axios.post("https://localhost:7195/api/Account/ForgetPassword",data).then((res)=>{
setloading(false)
      setMailsent(true);
      setButtontext("Back")
      setHeadertext("Check your inbox")
      setEmailError(false);
      setResponseError("");
    }).catch(error=>{
      setloading(false);
      setEmailError(true);
      setResponseError(error.response.data.Messages[0]);
    })
  }
  else{
    window.location.href="/forgotpassword";
  }
  }
  return (
    <>
    <div className="form">
      <Typography variant='h5' sx={{fontWeight:700,marginTop:0.8,color:"#293854"}}>{headertext}</Typography>
       {mailsent?<Typography variant='body1' sx={{marginTop:1.5,color:"#293854",margin:"auto"}}>We've just emailed instructions and a reset password link to {email}. It might take a few minutes to arrive.</Typography>
    :
    <>
    <Typography variant='body1' sx={{marginTop:1.5,color:"#293854"}}>No worries! We'll send you a link to reset it. Enter the email address you use to sign in to Catchuthere.com.</Typography>
     <FormControl  sx={{ mt: 3,mb:3}}  variant="outlined">
    <label className="input-label">Your email address</label>
    <OutlinedInput
    className='input'
      id="filled-adornment-amount"
      startAdornment={<InputAdornment position="start"><EmailIcon/></InputAdornment>}
      placeholder="e.g.: elonmusk@mars.com "
      sx={{borderColor:emailerror?error("email"):emailborder}}
      onChange={(e)=>setEmail(e.target.value)}
    />
  </FormControl></> 
  
  }
        <Button variant="contained" className="btn-primary" sx={{mt:3}} disabled={loading}  onClick={mailsend}>{buttontext}</Button>
        <Typography variant="h6" sx={{color:"red",mt:2,display:"flex",alignItems:"center"}}>{responseerror!=''?<><ErrorIcon sx={{mr:1}}/><Typography variant="h6">{responseerror}</Typography></>:""}</Typography>
        
        <Grid container sx={{mt:"20%"}}>
          <Grid item lg={12}>
            <Typography variant='body1' className="catch-info">© Catch U There 2022</Typography>
            <Typography variant='body1' className="catch-info">1A/2 Resolution Drive, Caringbah NSW 2229</Typography>
            <Typography variant='body1' className="catch-info">Australia</Typography>
          </Grid>
        </Grid>
    </div>
     <Grid container sx={{mt:"4%",mb:"2%"}}>
     <Grid item lg={12}>
       <Typography variant='body1' className="catch-info">By signing in or creating an account, you agree with our <u>Terms & conditions</u> and <u>Privacy policy</u></Typography>
      
     </Grid>
   </Grid>
   </>
  )
}
