import React, {useContext} from 'react'
import { Grid} from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Stack } from '@mui/system';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ExperienceItem from './ExperienceItem';
import Slider from '@mui/material/Slider';
import Resturantcard from './Resturantcard';
import StarIcon from '@mui/icons-material/Star';
import { iitineraryContext } from '../../pages/IitineraryContext';
export default function SwapAccomodation(props) {
  const Back=()=>{
    props.page("home")
      }

      const{addItemModel,handleaddItemClick,displayPage,changePage,setdisplayPage}=useContext(iitineraryContext)

      
      const [price, setPrice] = React.useState([1, 100]);

      const handlePriceChange = (event, newValue) => {
        setPrice(newValue);
      };
    
      const [people, setPeople] = React.useState([1, 100]);
    
      const handlePeopleChange = (event, newValue) => {
        setPeople(newValue);
      };
  return (
    <>
          <Grid container  className='cardHeader addAccomodation'>
            <Grid item lg={12}  xs={12} className="cardTop">
                <div className='headingBack'>
                <Button variant="" startIcon={<ArrowBackIosIcon />} className="backBtn" onClick={()=>setdisplayPage("home")}>
        Back
      </Button>
                <Typography variant='h5'>Add Accomodation</Typography>
                </div>
                <IconButton aria-label="delete" size='large' onClick={handleaddItemClick}>
  <CloseIcon/>
</IconButton>
             </Grid>
             </Grid>

             <Grid container >
                <Grid item lg={12 } xs={12} className='filters'>
                    
                    <Stack direction={"row"} className="sort">
<Typography variant='body1' sx={{color:"#3546AB"}}>Sort:</Typography>
<Select
          sx={{ m: 1, minWidth: 120 }}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10} selected>High to Low</MenuItem>
          <MenuItem value={20}>Low to High</MenuItem>
           </Select>

                    </Stack>
                </Grid>
             </Grid>


            <Grid container sx={{mt:0}}>
              
                <Grid item lg={3} xs={12} className="categorySection">
                <Typography variant='body1' sx={{fontWeight:700}}>Categories</Typography>

<Stack>
<FormControlLabel control={<Checkbox />} label="Shopping" />
<FormControlLabel control={<Checkbox />} label="Sightseeing" />
<FormControlLabel control={<Checkbox />} label="Restaurants" />
<FormControlLabel control={<Checkbox />} label="Bars" />
<FormControlLabel control={<Checkbox />} label="Activity" />
<FormControlLabel control={<Checkbox />} label="Museums" />
<FormControlLabel control={<Checkbox />} label="Fitness" />
<FormControlLabel control={<Checkbox />} label="Sport" />
<Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant='body2'>View More</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Stack>
        <FormControlLabel control={<Checkbox />} label="Shopping" />
<FormControlLabel control={<Checkbox />} label="Sightseeing" />
<FormControlLabel control={<Checkbox />} label="Restaurants" />
<FormControlLabel control={<Checkbox />} label="Bars" />
        </Stack>
        </AccordionDetails>
      </Accordion>


      <Typography variant='body1' sx={{fontWeight:700,mt:4,mb:1}}>Price</Typography>
      <Typography variant='body2' sx={{mb:1}}>$ {price[0]} - $ {price[1]} +</Typography>
      <Slider
       
        value={price}
        onChange={handlePriceChange}
        valueLabelDisplay="auto"
        max={400}
      
      />

<Typography variant='body1' sx={{fontWeight:700,mt:4,mb:1}}>People Attending</Typography>
<Typography variant='body2' sx={{mb:1}}>{people[0]} - {people[1]} +</Typography>

      <Slider
       
        value={people}
        onChange={handlePeopleChange}
        valueLabelDisplay="auto"
        min={1}
        max={10}
      
      />


</Stack>



                </Grid>
                <Grid item lg={9} xs={12} spacing={3}>
                    <Grid container className='swapAccomodationResturants'>
                     <Resturantcard purpose="add"/>
                     <Resturantcard purpose="add"/>
                     <Resturantcard purpose="add"/>
                       
                    </Grid>
                </Grid>
            </Grid>
    </>
  )
}
