import { React, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import EmailIcon from "@mui/icons-material/Email";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import "./scss/confirmemail.css";
import { Link } from 'react-router-dom';
import axios from "axios";
import ErrorIcon from "@mui/icons-material/Error";
export default function ConfirmEmail() {
    const [email,setEmail]=useState('');
    const [emailborder,setEmailborder]=useState('#98989E')
    const [emailerror,setemailerror]=useState(false);
    const [loading,setloading]=useState(false);
    const [mailsent,setMailSent]=useState(false);
    const [responseerror,setResponseError]=useState('');

    const handleemail=val=>{
        console.log(val)
        if(val!="")
        {
          setEmail(val)
          setemailerror(false);
        }
        else{
          setEmail(val)
          setemailerror(true);
    
        }
      }

      const error =value=>{
    
        if(value=="email")
        {
          console.log(value)
          return "red !important"
        }
      }
      
      const submit=()=>{
        setloading(true);
        if(email!=""){
axios.post("https://localhost:7195/api/Account/ConfirmEmail",{email:email}).then((res)=>{
    setloading(false);
    setMailSent(true);

}).catch(error=>{
  setloading(false);
  console.log(error.response.data)
  setResponseError(error.response.data.Messages[0])
})
      }
      else{
        setemailerror(true)
        setloading(false);
      }
    }
    
  return (
    <>
     <div className="form">
    {mailsent?<><Typography variant='h5' sx={{fontWeight:700,marginTop:0.8,color:"#293854"}}>Mail Sent Successfully..!</Typography><Typography variant='body1' sx={{marginTop:1.5,color:"#293854"}}>We've just emailed instructions and a Email Confirmation link to {email}. It might take a few minutes to arrive.</Typography></>:
     <>
     <Typography variant='h5' sx={{fontWeight:700,marginTop:0.8}}>Confirm Email</Typography>
      <Typography variant="body1" sx={{ fontWeight: 500, color: "#98989E" }}>
          Please enter your email for confirmation
        </Typography>
       <FormControl  sx={{ mt: 3,mb:3}}  variant="outlined">
          <label className="input-label">E-Mail</label>
          <OutlinedInput
          className='input'
            id="filled-adornment-amount"
            startAdornment={<InputAdornment position="start"><EmailIcon/></InputAdornment>}
            placeholder="e.g.: elonmusk@mars.com "
            sx={{borderColor:emailerror?error("email"):emailborder}}
            onChange={(e)=>handleemail(e.target.value)}
            value={email}
          />
          {(emailerror) ? (
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: "red", mt: 1.5 }}
            >
              Email is required.
            </Typography>
          ) : (
            ""
          )}
        </FormControl>

        
        <Grid container>
          <Grid item lg={12} sx={{display:"flex",flexDirection:"row"}}>
        </Grid>
        </Grid>
{console.log("lod",loading)}
        <Button variant="contained" disabled={loading} className="btn-primary" sx={{mt:3}} onClick={submit}>Confirm Email</Button>
        <Typography variant="h6" sx={{color:"red",mt:2,display:"flex",alignItems:"center"}}>{responseerror!=''?<><ErrorIcon sx={{mr:1}}/><Typography variant="h6">{responseerror}</Typography></>:""}</Typography>
        

        <Grid container sx={{mt:"20%"}}>
          <Grid item lg={12}>
            <Typography variant='body1' className="catch-info">© Catch U There 2022</Typography>
            <Typography variant='body1' className="catch-info">1A/2 Resolution Drive, Caringbah NSW 2229</Typography>
            <Typography variant='body1' className="catch-info">Australia</Typography>
          </Grid>
        </Grid>
        <Grid container sx={{mt:"10%",mb:"2%"}}>
     <Grid item lg={12}>
       <Typography variant='body1' className="catch-info">By signing in or creating an account, you agree with our <u>Terms & conditions</u> and <u>Privacy policy</u></Typography>
      
     </Grid>
   </Grid>
</>
          }
       
    </div>
    
    
   </>
  )
}
