import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDataContext } from "../../vendors/Contexts";
import { useSearchParams } from "react-router-dom";

export default function HotelsSearchbar({
  currentSearchbar,
  currentPage,
  getDepartureCity,
  getArrivalCity,
}) {
  // const [cities, setCities] = useState([]);
  // let [cityName1, setCityName1] = useState(null);
  // let [cityName2, setCityName2] = useState(null);
  let [source, setSource] = useState(null);
  let [destination, setDestination] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentSearchParams = new URLSearchParams(searchParams.toString());
  const updatedSearchUrl = `${window.location.origin}${
    window.location.pathname
  }?${currentSearchParams.toString()}`;
  const {
    updateCityName1,
    updateCityName2,
    cityName,
    cityCode,
    setCityName,
    setCityCode,
    cityName2,
    cityCode2,
    setCityName2,
    setCityCode2,
    cities,
  } = useDataContext();
  const [alldestinations, setDestinations] = useState([
    { city: "", country: "", minStayDuration: 1, maxStayDuration: 2 },
  ]);
  const cNames = axios.create({
    baseURL: "https://localhost:7195/api",
    headers: {
      Authorization: "Bearer " + window.sessionStorage.getItem("token"),
    },
  });
  // useEffect(()=>{
  //   if (currentSearchbar === "first") {
  //     setCityName(cityName);
  //     setCityCode(cityCode);
  //     updateSearchParams("FlyFrom", cityCode);
  //   } else {
  //     setCityName2(cityName2);
  //     setCityCode2(cityCode2);
  //     updateSearchParams("FlyTo", cityCode2);
  //   }
  // },[cityName,
  //   cityCode,
  //   setCityName,
  //   setCityCode,
  //   cityName2,
  //   cityCode2,
  //   setCityName2,
  //   setCityCode2,])
  const handleFirstSearchbar = (newValue) => {
    const trimmedCityName = newValue.cityName.trim();
    const trimmedCityCode = newValue.cityCode.trim();
    if (currentSearchbar === "first") {
      setCityName(trimmedCityName);
      setCityCode(trimmedCityCode);
      updateSearchParams("FlyFrom", trimmedCityCode);
    } else {
      setCityName2(trimmedCityName);
      setCityCode2(trimmedCityCode);
      updateSearchParams("FlyTo", trimmedCityCode);
    }
  };
  // const handleSecondSearchbar = () => {
  //   setUpdateCityName2(trimmedCityCode);
  //   setCityName2(trimmedCityName);
  //   updateSearchParams("FlyTo", trimmedCityCode);
  // };
  // Make a GET request to fetch data from the API
  // useEffect(() => {
  //   cNames
  //     .get("/Cities")
  //     .then((response) => {
  //       // The data from the API response will be available in the 'data' property of the response object
  //       const data = response.data.data.data;
  //       setCities(data);
  //       // mapping cities
  //     })
  //     // Process the data or update the UI with the retrieved information
  //     .catch((error) => {
  //       // Handle any errors that occur during the request
  //       console.error("Error fetching data:", error);
  //     });
  // }, []);
  // set search URL
  const flyFrom = searchParams.get("FlyFrom");
  const flyTo = searchParams.get("FlyTo");
  const updateSearchParams = (key, value) => {
    const updatedSearchParams = new URLSearchParams(
      currentSearchParams.toString()
    );
    updatedSearchParams.set(key, value);
    setSearchParams(updatedSearchParams.toString());
  };

  useEffect(() => {
    if (cities?.length && flyFrom) {
      const city1 = cities.find((city) => city.cityCode === flyFrom);

      if (city1) {
        setSource(city1);
      } else {
        // Handle the case where flyTo doesn't match any city code
        console.log(`City with code ${flyFrom} not found.`);
      }
    }
  }, [flyFrom, cities]);
  useEffect(() => {
    if (cities?.length && flyTo) {
      const city2 = cities.find((city) => city.cityCode === flyTo);

      console.log({ city2 });
      if (city2) {
        setDestination(city2);
      } else {
        // Handle the case where flyTo doesn't match any city code
      }
    }
  }, [flyTo, cities]);
  useEffect(() =>{
    const urlParams = new URLSearchParams(window.location.search);

    const source =  urlParams.get("flyFrom");
    const destination =  urlParams.get("flyTo");

    if (source && destination){
      setCityCode(source);
      setCityCode2(destination);
    }
  })
  const handledestinationcountry = (value, country, index) => {
    const values = [...alldestinations];
    values[index].city = value;
    values[index].country = country;
    // if (destinationError[index] == true) {
    //   const error = [...destinationError];
    //   error[index] = false;
    //   setDestinationError(error);
    // }
    setDestinations(values);
  };
  return (
    <>
      {alldestinations.map((destinationn, index) => {
        return (
          // cityName &&
          <Autocomplete
            value={
              currentSearchbar === "first" ? cityName?.replace(/"/g, "") : cityName2?.replace(/"/g, "")
            }
            onChange={(e, newValue) => {
              const trimmedCityName = newValue.cityName;
              const trimmedCityCode = newValue.cityCode;
              handleFirstSearchbar(newValue);
              handledestinationcountry(trimmedCityName, trimmedCityCode, index);
            }}
            id="country-select-demo"
            options={cities}
            autoHighlight
            key={index}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.cityName.toString())}
            // multiple={true}
            renderInput={(params) => (
              <TextField
              required
                placeholder={
                  currentSearchbar === "first" ? "Where from" : "Where to"
                }
                onChange={(e) => {
                   currentSearchbar === "first"
                     ? setCityName(e.target.value):
                  setCityName2(e.target.value);
                }}
                sx={{
                  outline: "none",
                  "& .MuiOutlinedInput-root": {
                    padding: "0px",
                  },
                  "& input::placeholder": {
                    fontSize: { xs: "14px", lg: "14px" },
                    color: "#000000",
                    fontWeight: "500",
                  },
                  "& .MuiSvgIcon-root": {
                    display: "none",
                  },
                }}
                {...params}
                inputProps={{
                  style: {
                    width: "auto",
                    fontFamily: "inter",
                    color: "#262426",
                    fontSize: { xs: "12px", md: "12px" },
                    fontWeight: "600",
                  },
                  ...params.inputProps,
                }}
              />
            )}
          />
        );
      })}
    </>
  );
}
