import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import ExploreCard from "./ExploreCard";
import blogsData from "../Blogs/blogsData";

export default function ExploreComponent({ desiredLocation }) {
  const filteredData = blogsData.filter(
    (item) => item.location === desiredLocation
  );

  return (
    <Box
      padding="0 0 5vh 0"
    >
      <Grid container>
      {desiredLocation === 'all' ? 
      blogsData.slice(-4).map((item) => {
        return(
          <ExploreCard item={item}/>
        )
      })
      :
      filteredData.slice(0, 4).map((item) => {
        return (
          <ExploreCard item = {item}/>
        );
      })}
      </Grid>
    </Box>
  );
}
