import React, { useContext } from 'react'
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Button from '@mui/material/Button';
import AirlineSeatLegroomNormalIcon from '@mui/icons-material/AirlineSeatLegroomNormal';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import StarPurple500OutlinedIcon from '@mui/icons-material/StarPurple500Outlined';
import PoolOutlinedIcon from '@mui/icons-material/PoolOutlined';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import WifiIcon from '@mui/icons-material/Wifi';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { iitineraryContext } from '../../pages/IitineraryContext';

import { Stack } from '@mui/system';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LockIcon from '@mui/icons-material/Lock';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';

import {Helmet} from "react-helmet";

export default function Resturantcard(props) {

    const{swapBtnClick}=useContext(iitineraryContext);

  
  return (
    <>
    <Card className="resturant card">
      <Grid container>
        <Grid item lg={2.3} xs={12}>
          <div className='cardImg'>
            <img src="./assets/images/resturant.png" alt="" />
            </div>
        </Grid>
        <Grid item lg={5.5} xs={12} sx={{ml:2}}className="card-body">
            <Grid container sx={{dislpay:"flex",mb:1}}>
                <Grid item xs={7} sx={{width:"auto",mr:1}}>
                <Typography variant="h6" sx={{fontWeight:600,display:"inline"}}>Roma Cappucia Hotel</Typography>
                </Grid>
                <Grid item xs={4} sx={{display:"flex",alignItems:"center"}}>
                    <StarPurple500OutlinedIcon  className="star"/>
                    <StarPurple500OutlinedIcon  className="star"/>
                    <StarPurple500OutlinedIcon  className="star"/>
                    <StarPurple500OutlinedIcon  className="star"/>
                    <StarPurple500OutlinedIcon  className="star"/>
                    <div className='notify'>8.5</div>
                </Grid>
            </Grid>
           
            <Typography variant="body2" sx={{mb:2}}>13 Madrid Road, Madrid, 20175, Spain</Typography>
            <Grid container sx={{mb:1}}>
                <Grid item lg={3}>
                <Button className="card-icons" variant="text" startIcon={<PoolOutlinedIcon />}>
        Pool
      </Button>
                </Grid>
                <Grid item lg={3}>
                <Button className="card-icons" variant="text" startIcon={<FitnessCenterIcon />}>
                Gym
      </Button>
                </Grid>
                
                <Grid item lg={3}>
                <Button className="card-icons" variant="text" startIcon={<DirectionsCarIcon />}>
                Parkinig
      </Button>
                </Grid>

                <Grid item lg={3}>
                <Button className="card-icons" variant="text" startIcon={<RestaurantMenuIcon />}>
                Resturant
      </Button>
                </Grid>

                <Grid item lg={3}>
                <Button className="card-icons" variant="text" startIcon={<WifiIcon />}>
                Wifi
      </Button>
                </Grid>
                <Grid item lg={3}>
                <Button className="card-icons" variant="text" startIcon={<AcUnitIcon />}>
                Air-con
      </Button>
                </Grid>
            </Grid>
        </Grid>

        <Grid item lg={3.9} xs={12} className="card-body">
            <Grid container className="pricing">
            <Grid item lg={6}>
                 </Grid>
                <Grid item lg={6} xs={12} sx={{display:"flex",flexDirection:"column",justifyContent:"flex-end"}}>
                <Typography variant="h4" sx={{fontWeight:700,display:"flex",float:"right",justifyContent:"end"}}> <Typography variant="body1" sx={{fontSize:20,fontWeight:700,color:"#808080"}}>$</Typography>2050</Typography>
                <Typography variant="body1" sx={{fontSize:12,color:"#808080",textAlign:"right"}}>for a 5 night stay with 1 room</Typography>
                </Grid>
          
            </Grid>
            
            <Grid container sx={{mt:3,pl:{lg:1,sm:"7%"},display:"flex",justifyContent:"space-between"}}>
           
                <Grid item lg={5} sm={6} xs={5.5}>
                <Button sx={{ml:1}}variant="outlined" className="viewBtns">View details</Button>
                </Grid>
                
                <Grid item lg={7} sm={5} xs={6.5} className="adjustToEnd" sx={{textAlign:"center"}}>
                {props.purpose=="swap"?<Button sx={{fontSize:10}} className='selectBtn'>Select accomodation</Button>:props.purpose=="add"?<Button sx={{fontSize:10}} className='selectBtn'>Add accomodation</Button>:<Button sx={{fontSize:9,fontWeight:700,padding:0}}variant="text" onClick={()=>swapBtnClick("swapaccomodation")} className="textBtns">Swap accomodation</Button>}
                </Grid>
            </Grid>
        </Grid>

</Grid>
    </Card>

    
<Stack className="cardsSide" sx={{right:{xs:"-16px !important",sm:"-6px !important",lg:"0"}}}>

  <IconButton aria-label="favorite" className='sideIconBtn'>
    <DeleteForeverIcon />
  </IconButton>
</Stack>
{/* <Button className="delBtn">Dele</Button> */}

<Helmet>
  <script src='../../SwipeDetect.js' type="text/jsx" />

</Helmet>
</>



  )
}
