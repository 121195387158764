import React, { createContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Navbar from "../../components/ui-components/Navbar";
import {
  Grid,
  Box,
  Typography,
  Button,
  Card,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Radio,
  Select,
  MenuItem,
  BottomNavigation,
  BottomNavigationAction,
  Slider,
  Tab,
  Drawer,
  Tooltip,
  useMediaQuery,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { CityContext } from "../Hotels-list/HotelsList";
import HotelIcon from "@mui/icons-material/Hotel";
import ErrorIcon from "@mui/icons-material/Error";
import BookingModel from "../../components/ui-components/BookingModel";
import HotelFilters from "../Hotels-list/HotelFilters";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FiberManualRecordTwoToneIcon from "@mui/icons-material/FiberManualRecordTwoTone";
import TuneIcon from "@mui/icons-material/Tune";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SortIcon from "@mui/icons-material/Sort";
import LuggageIcon from "@mui/icons-material/Luggage";
import DoneIcon from "@mui/icons-material/Done";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import FlightIcon from "@mui/icons-material/Flight";
import FlightsCard from "./FlightsCard";
import http from "../../utils/http";
import {
  TabContext,
  TabList,
  TabPanel,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { useDataContext } from "../../vendors/Contexts";
import { Audio, Blocks } from "react-loader-spinner";
import EastIcon from "@mui/icons-material/East";
import carriers from "../../data/kiwi-carriers.json";
import { format } from "date-fns";

export default function FlightsList() {
  const [searchParams, setSearchParams] = useSearchParams();

  const flyFrom = searchParams.get("FlyFrom");
  const flyTo = searchParams.get("FlyTo");
  const destination = searchParams.get("destination");
  const dateFrom = searchParams.get("dateFrom");
  const dateTo = searchParams.get("dateTo");
  const adults = searchParams.get("adults");
  const children = searchParams.get("children");
  const rooms = searchParams.get("rooms");

  const initialTimeRange = [0, 1440]; // 0 corresponds to 00:00, 1440 corresponds to 24:00
  const [flightPrice, setFlightPrice] = useState([0, 3000]);
  const [cabinBaggage, setCabinBaggage] = useState(1);
  const [checkedBaggage, setCheckedBaggage] = useState(1);
  const [flights, setFlights] = useState([]);
  const [departureCity, setDepartureCity] = useState();
  const [arrivalCity, setArrivalCity] = useState();
  const [bagAddedPrice, setBagAddedPrice] = useState(0);
  const [oneWay, setOneWay] = useState(false);
  const [excludeAirlines, setExcludeAirlines] = useState(false);
  const [displayCount, setDisplayCount] = useState(5);
  const [travelDuration, setTravelDuration] = useState(60);
  const [departureTime, setDepartureTime] = useState(initialTimeRange);
  const [arrivalTime, setArrivalTime] = useState([0, 24]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState("");
  const [stopOver, setStopOver] = useState(initialTimeRange);
  const [maxStopOver, setMaxStopOver] = useState("");
  const [maxStopOverUrl, setMaxStopOverUrl] = useState("");
  const [overnightStops, setOvernightStops] = useState(true);
  const [timeRange, setTimeRange] = useState(initialTimeRange);
  const [loading, setLoading] = useState(false);
  let [flightNameFilter, setFlightNameFilter] = useState([]);
  const [triggerSearch, setTriggerSearch] = useState(false);

  const [showAdditionalFeeSign, setShowAdditionalFeeSign] = useState(false);
  const {
    date,
    flightType,
    getFlightType,
    cityCode,
    cityCode2,
    setCityCode,
    setCityCode2,
    cityName,
    cityName2,
  } = useDataContext();
  const currentSearchParams = new URLSearchParams(window.location.search);
  let isLargeScreen = useMediaQuery("(min-width:992px)");
  let isMediumScreen = useMediaQuery("(min-width:768px)");
  const formatTime = (hours, minutes) => {
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  };
  const getTimeDifferenceInHours = (startDateTimeString, endDateTimeString) => {
    const startDate = new Date(startDateTimeString);
    const endDate = new Date(endDateTimeString);
    const differenceInMilliseconds = endDate - startDate;
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
    return format(differenceInHours, "hh-mm");
  };
  const sendFlightType = (e) => {
    getFlightType(e);
  };
  let [cityNamee, setCityName] = useState("");
  let handleFlightSearch = (value) => {
    setCityName(value);
  };
  const toggleDrawer = (data) => {
    setIsDrawerOpen(!isDrawerOpen);
    setDrawerData(data);
  };
  let applyMaxStopover = (e) => {
    const newValue = e.target.value;
    setMaxStopOver(newValue);

    if (newValue.length === 0) {
      setMaxStopOverUrl("");
      updateSearchParams("MaxStopOver", null);
    } else {
      setMaxStopOverUrl(`&MaxStopOver=${newValue}`);
      updateSearchParams("MaxStopOver", newValue);
    }
  };
  const returnDateFrom = date[0].startDate
    ? new Date(
        date[0].startDate.getTime() + 7 * 24 * 60 * 60 * 1000
      ).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    : "";
  const returnDateTo = date[0].endDate
    ? new Date(
        date[0].endDate.getTime() + 10 * 24 * 60 * 60 * 1000
      ).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    : "";
  let addFlightNameFilter = (e) => {
    let filterType = e.target.name;
    let filterValue = e.target.value;
    let prevSubFilters = flightNameFilter;

    setExcludeAirlines(e.target.checked);

    if (flightNameFilter.includes(filterValue)) {
      let filterData = flightNameFilter.filter(
        (number) => number !== filterValue
      );
      setFlightNameFilter(filterData);
    } else {
      setFlightNameFilter([...prevSubFilters, filterValue]);
    }
  };
  const flightNameFilterUrl = "";
  let applySelectedFilter = () => {
    if (flightNameFilter.length == 0) {
      flightNameFilterUrl = "";
      updateSearchParams("SelectAirline", null);
    } else {
      flightNameFilterUrl = `&SelectAirline=${flightNameFilter.join(",")}`;
      updateSearchParams("SelectAirline", flightNameFilter);
    }
  };
  useEffect(() => {
    setLoading(true);
    http
      .get(
        `/Kiwi?FlyFrom=${cityCode}&FlyTo=${cityCode2}&DateFrom=${date[0].startDate?.toLocaleDateString(
          "en-GB",
          {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }
        )}&DateTo=${date[0].endDate?.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })}&Class=economy&Adults=2&Children=1&PriceFrom=${
          flightPrice[0]
        }&PriceTo=${flightPrice[1]}&TravelTimeFrom=00%3A00&TravelTimeTo=23%3A59&MaxFlyDuration=${travelDuration}&StopOverFrom=${
          overnightStops == true ? "00%3A00" : "08%3A00"
        }&StopOverTo=${overnightStops == true ? "24%3A00" : "24%3A00"}${
          oneWay === true
            ? ""
            : `&ReturnFrom=${returnDateFrom}&ReturnTo=${returnDateTo}`
        }${
          flightNameFilter.length === 0
            ? ""
            : `&SelectAirline=${flightNameFilter.join(",")}`
        }${maxStopOverUrl}&SelectAirlinesExclude=`
      )
      .then((res) => {
        console.log(res, "data of flights");
        setFlights(res.data.data.data.kiwiResponseModels);
        // setOriginalFlights(res.data.data.data);
        setShowAdditionalFeeSign(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    departureCity,
    cityCode,
    cityCode2,
    flightNameFilter,
    maxStopOverUrl,
    flightType,
    cityName,
    cityName2,
    travelDuration,
    departureTime,
    arrivalTime,
    stopOver,
    maxStopOverUrl,
    overnightStops,
    flightNameFilter,
    flightPrice,
    date,
    triggerSearch,
  ]);
  const url = `/Kiwi?FlyFrom=${cityCode}&FlyTo=${cityCode2}&DateFrom=${date[0].startDate?.toLocaleDateString(
    "en-GB",
    {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }
  )}&DateTo=${date[0].endDate?.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  })}&Class=economy&Adults=2&Children=1&PriceFrom=${
          flightPrice[0]
        }&PriceTo=${flightPrice[1]}&DepartureTimeFrom=${formatTime(
          Math.floor(departureTime[0] / 60),
          departureTime[0] % 60
        )}
        )}&DepartureTimeTo=${formatTime(
          Math.floor(departureTime[1] / 60),
          departureTime[1] % 60
        )}&TravelTimeFrom=00%3A00&TravelTimeTo=23%3A59&MaxFlyDuration=${travelDuration}&StopOverFrom=${
          overnightStops == true ? "00%3A00" : "08%3A00"
        }&StopOverTo=${overnightStops == true ? "24%3A00" : "24%3A00"}${
          oneWay === true
            ? ""
            : `&ReturnFrom=${returnDateFrom}&ReturnTo=${returnDateTo}`
        }${
          flightNameFilter.length === 0
            ? ""
            : `&SelectAirline=${flightNameFilter.join(",")}`
        }${maxStopOverUrl}&SelectAirlinesExclude=`;
  console.log(url, "urlllllllllllllllll");
  const loadMore = () => {
    setDisplayCount(displayCount + 10); // Increase the count by 10 when "Load More" is clicked
  };
  window.addEventListener("scroll", () => {
    // Check if user has scrolled to the bottom
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      displayCount < flights.length
    ) {
      // Fetch more data
      loadMore();
    }
  });
  const flightCards = flights.slice(0, displayCount).map((item, index) => {
    return (
      <FlightsCard
        toggleDrawer={toggleDrawer}
        displayCount={displayCount}
        // loadMore={loadMore}
        item={item}
      />
    );
  });

  const uniqueAirlineNames = new Set();

  const flightNames = flights
    .filter((item) => {
      const airlineName = item.airlineName.trim(); // Trim to remove white spaces
      if (!uniqueAirlineNames.has(airlineName)) {
        uniqueAirlineNames.add(airlineName);
        return true;
      }
      return false;
    })
    .map((item, index) => (
      <Typography
        key={index}
        color="#252A31"
        display="flex"
        alignItems="center"
      >
        <Checkbox value={item.airlineName} onChange={addFlightNameFilter} />{" "}
        {item.airlineName}
      </Typography>
    ));
  // const flightNames = flights.map((item, index) => {
  //   return (
  //     <Typography color="#252A31" display="flex" alignItems="center">
  //       <Checkbox /> {item.airlineName}
  //     </Typography>
  //   );
  // });
  useEffect(() => {
    // Parse the URL
    const urlParams = new URLSearchParams(window.location.search);

    // Extract parameters from the URL
    const flyFrom = urlParams.get("FlyFrom");
    const flyTo = urlParams.get("FlyTo");
    const maxStopOver = urlParams.get("MaxStopOver");
    const departureTimeTo = urlParams.get("DepartureTimeTo");
    const departureTimeFrom = urlParams.get("DepartureTimeFrom");
    const arrivalTimeTo = urlParams.get("ArrivalTimeTo");
    const arrivalTimeFrom = urlParams.get("ArrivalTimeFrom");
    const maxFlyDuration = urlParams.get("MaxFlyDuration");
    const stopOverFrom = urlParams.get("StopOverFrom");
    const stopOverTo = urlParams.get("StopOverTo");
    const priceFrom = urlParams.get("PriceFrom");
    const priceTo = urlParams.get("PriceTo");

    // Set each parameter in its own state
    if (flyFrom) setCityCode(flyFrom);
    if (flyTo) setCityCode2(flyTo);
    if (maxStopOver) setMaxStopOverUrl(maxStopOver);
    if (departureTimeFrom)
      setDepartureTime([
        Number(departureTimeFrom),
        departureTimeTo ? Number(departureTimeTo) : null,
      ]);
    if (arrivalTimeFrom)
      setArrivalTime([
        Number(arrivalTimeFrom),
        arrivalTimeTo ? Number(arrivalTimeTo) : null,
      ]);
    // if (maxFlyDuration) setTravelDuration([Number(maxFlyDuration), maxFlyDuration ? Number(maxFlyDuration) : null]);
    if (stopOverFrom)
      setStopOver([
        Number(stopOverFrom),
        stopOverTo ? Number(stopOverTo) : null,
      ]);
    if (priceFrom)
      setFlightPrice([Number(priceFrom), priceTo ? Number(priceTo) : null]);
  }, []);

  const updateSearchParams = async (key, value) => {
    return new Promise((resolve, reject) => {
      try {
        const updatedSearchParams = new URLSearchParams(window.location.search);

        if (value === null || value === undefined) {
          updatedSearchParams.delete(key);
        } else {
          updatedSearchParams.set(key, value);
        }

        const newUrl = `${
          window.location.pathname
        }?${updatedSearchParams.toString()}`;
        window.history.replaceState(null, "", newUrl);

        setSearchParams(updatedSearchParams.toString());
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  let addtoBaggage = () => {
    setCabinBaggage(cabinBaggage + 1);
  };
  let minusfromBaggage = () => {
    if (cabinBaggage > 0) {
      setCabinBaggage(cabinBaggage - 1);
    }
  };
  let addtoCheckedBaggage = () => {
    setCheckedBaggage(checkedBaggage + 1);
  };
  let minusfromCheckedBaggage = () => {
    if (checkedBaggage > 0) {
      setCheckedBaggage(checkedBaggage - 1);
    }
  };
  const handlePriceChange = (event, newValue) => {
    console.log(newValue, "price");
    setFlightPrice(newValue);
    updateSearchParams("PriceFrom", newValue[0]);
    updateSearchParams("PriceTo", newValue[1]);
  };
  const handleTravelDuration = (event, newValue) => {
    setTravelDuration(newValue);
    updateSearchParams("MaxFlyDuration", newValue);
  };
  const handleStopOver = (event, newValue) => {
    setStopOver(newValue);
    updateSearchParams("StopOverFrom", newValue[0]);
    updateSearchParams("StopOverTo", newValue[1]);
  };
  const handleDeparturetime = (event, newValue) => {
    updateSearchParams("DepartureTimeTo", newValue[1]);
    setDepartureTime(newValue);
    updateSearchParams("DepartureTimeFrom", newValue[0]);
  };
  const handleArrivaltime = (event, newValue) => {
    setArrivalTime(newValue);
    updateSearchParams("ArrivalTimeTo", newValue[1]);
    updateSearchParams("ArrivalTimeFrom", newValue[0]);
  };
  const handleDepartureCityName = (event) => {
    setDepartureCity(event);
  };
  const handleArrivalCityName = (event) => {
    setArrivalCity(event);
  };
  // flights time interval calculator
  const return0Routes = drawerData?.routes
    ? drawerData.routes.filter((route) => route.return === 0)
    : [];
  // Get the first and last items from return0Routes
  const firstReturn0 = return0Routes[0];
  const lastReturn0 = return0Routes[return0Routes.length - 1];
  // Filter routes where return is 1
  const return1Routes = drawerData?.routes
    ? drawerData.routes.filter((route) => route.return === 1)
    : [];

  // Get the first and last items from return1Routes
  const firstReturn1 = return1Routes[0];
  const lastReturn1 = return1Routes[return1Routes.length - 1];

  // time converstion
  const oneWayArrivalTime = new Date(lastReturn0?.arrival);
  const oneWayDepartureTime = new Date(firstReturn0?.departure);

  // Calculate the time difference in milliseconds
  const oneWayTimeDifference = oneWayArrivalTime - oneWayDepartureTime;

  // Convert milliseconds to hours and minutes
  const oneWayHours = Math.floor(oneWayTimeDifference / (1000 * 60 * 60));
  const oneWayDays = Math.floor(oneWayHours / 24);
  const oneWayMinutes = Math.floor(
    (oneWayDepartureTime % (1000 * 60 * 60)) / (1000 * 60)
  );

  const returnArrivalTime = new Date(lastReturn0?.arrival);
  const returnDepartureTime = new Date(firstReturn0?.departure);

  // Calculate the time difference in milliseconds
  const returnTimeDifference = returnArrivalTime - returnDepartureTime;

  // Convert milliseconds to hours and minutes
  const returnHours = Math.floor(returnTimeDifference / (1000 * 60 * 60));
  const returnDays = Math.floor(returnHours / 24);
  const returnMinutes = Math.floor(
    (returnTimeDifference % (1000 * 60 * 60)) / (1000 * 60)
  );
  return (
    <>
      {/* Drawer code starts */}
      <Drawer
        anchor="right"
        // sx={{
        //   "& .MuiBackdrop-root": { marginTop: "64px" },
        //   "& .MuiPaper-root": { marginTop: "64px" },
        // }}
        open={isDrawerOpen}
        onClose={toggleDrawer}
      >
        {/* <div className={classes.drawerContent}> */}
        <Box
          sx={{
            padding: "1vh 1vw",
            height: "100vh",
            width: { lg: "25vw", md: "50vw", xs: "98vw" },
            bgcolor: "#f9f9fd",
          }}
        >
          <Card
            sx={{
              marginTop: "0px !important",
              bgcolor: "#6373CF",
              borderRadius: "10px",
              padding: "20px 0 0 0",
            }}
          >
            <Box bgcolor="#ffffff" padding="0 40px" position="relative">
              <Button
                onClick={toggleDrawer}
                sx={{ position: "absolute", top: "1.5vh", right: "1vw" }}
              >
                <HighlightOffIcon />
              </Button>
              <Typography
                textAlign="center"
                padding="2vh 0"
                color="#000"
                fontFamily="inter"
                fontWeight="600"
              >
                Flight details
              </Typography>
            </Box>
            <Box
              bgcolor="#F2F4F3"
              display="flex"
              justifyContent="space-between"
            >
              <Typography
                padding="2vh 40px"
                color="#000"
                fontFamily="inter"
                fontWeight="700"
                display="flex"
                alignItems="center"
              >
                {return0Routes[0]?.cityFrom} &nbsp; <EastIcon />
                &nbsp; {return0Routes[0]?.cityTo}
              </Typography>
            </Box>
            <Box bgcolor="#ffffff" padding="0 40px 3vh" position="relative">
              {return0Routes?.map((item, index) => {
                return (
                  <>
                    <Typography
                      padding="2vh 0"
                      color="#000"
                      fontSize="14px"
                      fontFamily="inter"
                      fontWeight="600"
                    >
                      {new Date(item.departure).toLocaleString("en-US", {
                        weekday: "long",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </Typography>
                    <Timeline position="alternate" sx={{ translate: "-10% 0" }}>
                      <TimelineItem>
                        <TimelineOppositeContent>
                          <Typography
                            fontSize="12px"
                            fontFamily="inter"
                            color="#0C1027"
                            fontWeight="600"
                          >
                            {new Date(item.departure).toLocaleString("en-US", {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: false, // Use 24-hour format
                            })}
                          </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot
                            sx={{
                              border: "1px solid #6373cf",
                              backgroundColor: "#EFF1FA",
                            }}
                          />
                          <TimelineConnector
                            sx={{ backgroundColor: "#6373cf" }}
                          />
                        </TimelineSeparator>
                        <TimelineContent sx={{ px: 2 }}>
                          <Typography
                            fontSize="12px"
                            fontFamily="inter"
                            color="#0C1027"
                            fontWeight="700"
                          >
                            {item.cityFrom}
                          </Typography>
                          <Typography
                            fontSize="12px"
                            fontFamily="inter"
                            color="#0C1027"
                            fontWeight="600"
                          >
                            {item.fromAirport}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent sx={{ m: "auto 0" }}>
                          <Typography
                            fontSize="13px"
                            fontFamily="inter"
                            color="#000"
                            fontWeight="700"
                          >
                            {item.airlineName} - {item.flightNo}
                          </Typography>
                          <Typography
                            fontSize="12px"
                            fontFamily="inter"
                            color="#7F7C88"
                            fontWeight="600"
                          >
                            Operated by{" "}
                            {
                              carriers.find((c) => c.id === item.airlineName)
                                ?.name
                            }
                          </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot
                            sx={{
                              backgroundColor: "transparent",
                              boxShadow: "none",
                            }}
                          >
                            <img
                              src={`https://images.kiwi.com/airlines/32x32/${item.airlineName}.png?default=airline.png`}
                            />
                          </TimelineDot>
                        </TimelineSeparator>
                        <TimelineContent
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            px: 2,
                          }}
                        >
                          <Typography
                            fontSize="12px"
                            fontFamily="inter"
                            color="#7F7C88"
                            fontWeight="600"
                          >
                            {Math.abs(
                              new Date(item.departure).getHours() -
                                new Date(item.arrival).getHours()
                            )}
                            h
                            {Math.abs(
                              new Date(item.departure).getMinutes() -
                                new Date(item.arrival).getMinutes()
                            )}
                            m
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>

                      <TimelineItem>
                        <TimelineOppositeContent sx={{ m: "auto 0" }}>
                          <Typography
                            fontSize="12px"
                            fontFamily="inter"
                            color="#0C1027"
                            fontWeight="600"
                          >
                            {new Date(item.arrival).toLocaleString("en-US", {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: false, // Use 24-hour format
                            })}
                          </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector
                            sx={{ backgroundColor: "#6373cf" }}
                          />
                          <TimelineDot
                            sx={{
                              border: "1px solid #6373cf",
                              backgroundColor: "#6373CF",
                            }}
                          />
                        </TimelineSeparator>
                        <TimelineContent
                          sx={{
                            px: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            fontSize="12px"
                            fontFamily="inter"
                            color="#0C1027"
                            fontWeight="700"
                          >
                            {item.cityTo}
                          </Typography>
                          <Typography
                            fontSize="12px"
                            fontFamily="inter"
                            color="#0C1027"
                            fontWeight="600"
                          >
                            {item.toAirport}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>
                    {return0Routes.length > 1 ? (
                      index < return0Routes.length - 1 ? (
                        <Box
                          my="2vh"
                          display="flex"
                          flexDirection="column"
                          bgcolor="#EBF8FF"
                          padding="1vh 0"
                        >
                          <Typography
                            padding="0 1vw"
                            color="#0C1027"
                            fontSize="16px"
                            fontFamily="inter"
                            fontWeight="700"
                          >
                            {item.toAirport}
                          </Typography>

                          <Typography
                            padding="0 1vw"
                            fontSize="14px"
                            fontFamily="inter"
                            color="#364147"
                            fontWeight="600"
                          >
                            {/* {console.log(getTimeDifferenceInHours(drawerData.routes[index]?.arrival, drawerData.routes[index + 1]?.departure),'next date')} */}
                            {getTimeDifferenceInHours(
                              return0Routes[index]?.arrival,
                              return0Routes[index + 1]?.departure
                            )}{" "}
                            hrs stopover in {item.cityTo}
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
            </Box>
            {flightType === true ? (
              ""
            ) : (
              <Box
                bgcolor="#F2F4F3"
                display="flex"
                justifyContent="space-between"
              >
                <Typography
                  padding="2vh 40px"
                  color="#000"
                  fontFamily="inter"
                  fontWeight="700"
                  display="flex"
                  alignItems="center"
                >
                  {return1Routes[0]?.cityFrom} &nbsp; <EastIcon />
                  &nbsp; {return1Routes[0]?.cityTo}
                </Typography>
              </Box>
            )}
            <Box bgcolor="#ffffff" padding="0 40px 10vh" position="relative">
              {flightType === true
                ? ""
                : return1Routes?.map((item, index) => {
                    return (
                      <>
                        <Typography
                          padding="2vh 0"
                          color="#000"
                          fontSize="14px"
                          fontFamily="inter"
                          fontWeight="600"
                        >
                          {new Date(item.departure).toLocaleString("en-US", {
                            weekday: "long",
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })}
                        </Typography>

                        <Timeline
                          position="alternate"
                          sx={{ translate: "-10% 0" }}
                        >
                          <TimelineItem>
                            <TimelineOppositeContent>
                              <Typography
                                fontSize="12px"
                                fontFamily="inter"
                                color="#0C1027"
                                fontWeight="600"
                              >
                                {new Date(item.departure).toLocaleString(
                                  "en-US",
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: false, // Use 24-hour format
                                  }
                                )}
                              </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineDot
                                sx={{
                                  border: "1px solid #6373cf",
                                  backgroundColor: "#EFF1FA",
                                }}
                              />
                              <TimelineConnector
                                sx={{ backgroundColor: "#6373cf" }}
                              />
                            </TimelineSeparator>
                            <TimelineContent sx={{ px: 2 }}>
                              <Typography
                                fontSize="12px"
                                fontFamily="inter"
                                color="#0C1027"
                                fontWeight="700"
                              >
                                {item.cityFrom}
                              </Typography>
                              <Typography
                                fontSize="12px"
                                fontFamily="inter"
                                color="#0C1027"
                                fontWeight="600"
                              >
                                {item.fromAirport}
                              </Typography>
                            </TimelineContent>
                          </TimelineItem>
                          <TimelineItem>
                            <TimelineOppositeContent sx={{ m: "auto 0" }}>
                              <Typography
                                fontSize="13px"
                                fontFamily="inter"
                                color="#000"
                                fontWeight="700"
                              >
                                {item.airlineName} - {item.flightNo}
                              </Typography>
                              <Typography
                                fontSize="12px"
                                fontFamily="inter"
                                color="#7F7C88"
                                fontWeight="600"
                              >
                                Operated by{" "}
                                {
                                  carriers.find(
                                    (c) => c.id === item.airlineName
                                  )?.name
                                }
                              </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineDot
                                sx={{
                                  backgroundColor: "transparent",
                                  boxShadow: "none",
                                }}
                              >
                                <img
                                  src={`https://images.kiwi.com/airlines/32x32/${item.airlineName}.png?default=airline.png`}
                                />
                              </TimelineDot>
                            </TimelineSeparator>
                            <TimelineContent
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                px: 2,
                              }}
                            >
                              <Typography
                                fontSize="12px"
                                fontFamily="inter"
                                color="#7F7C88"
                                fontWeight="600"
                              >
                                {Math.abs(
                                  new Date(item.departure).getHours() -
                                    new Date(item.arrival).getHours()
                                )}
                                h
                                {Math.abs(
                                  new Date(item.departure).getMinutes() -
                                    new Date(item.arrival).getMinutes()
                                )}
                                m
                              </Typography>
                            </TimelineContent>
                          </TimelineItem>

                          <TimelineItem>
                            <TimelineOppositeContent sx={{ m: "auto 0" }}>
                              <Typography
                                fontSize="12px"
                                fontFamily="inter"
                                color="#0C1027"
                                fontWeight="600"
                              >
                                {new Date(item.arrival).toLocaleString(
                                  "en-US",
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: false, // Use 24-hour format
                                  }
                                )}
                              </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineConnector
                                sx={{ backgroundColor: "#6373cf" }}
                              />
                              <TimelineDot
                                sx={{
                                  border: "1px solid #6373cf",
                                  backgroundColor: "#6373CF",
                                }}
                              />
                            </TimelineSeparator>
                            <TimelineContent
                              sx={{
                                px: 2,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                fontSize="12px"
                                fontFamily="inter"
                                color="#0C1027"
                                fontWeight="700"
                              >
                                {item.cityTo}
                              </Typography>
                              <Typography
                                fontSize="12px"
                                fontFamily="inter"
                                color="#0C1027"
                                fontWeight="600"
                              >
                                {item.toAirport}
                              </Typography>
                            </TimelineContent>
                          </TimelineItem>
                        </Timeline>

                        {return1Routes.length > 1 ? (
                          index < return1Routes.length - 1 ? (
                            <Box
                              my="2vh"
                              display="flex"
                              flexDirection="column"
                              bgcolor="#EBF8FF"
                              padding="1vh 0"
                            >
                              <Typography
                                padding="0 1vw"
                                color="#0C1027"
                                fontSize="16px"
                                fontFamily="inter"
                                fontWeight="700"
                              >
                                {item.toAirport}
                              </Typography>

                              <Typography
                                padding="0 1vw"
                                fontSize="14px"
                                fontFamily="inter"
                                color="#364147"
                                fontWeight="600"
                              >
                                {/* {console.log(getTimeDifferenceInHours(drawerData.routes[index]?.arrival, drawerData.routes[index + 1]?.departure),'next date')} */}
                                {getTimeDifferenceInHours(
                                  return1Routes[index]?.arrival,
                                  return1Routes[index + 1]?.departure
                                )}{" "}
                                hrs stopover in {item.cityTo}
                              </Typography>
                            </Box>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })}
              <Box paddingTop="3vh">
                <Grid
                  container
                  bgcolor="#F2F4F3"
                  padding="14px 16px 14px 16px"
                  borderRadius="4px"
                >
                  <Grid item xs={12}>
                    <Typography
                      padding="0 0 1vh"
                      fontSize="14px"
                      fontFamily="inter"
                      color="#364147"
                      fontWeight="700"
                    >
                      Bags
                    </Typography>
                    <hr />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      display="flex"
                      color="#364147"
                      alignItems="center"
                      fontFamily="inter"
                      fontSize="14px"
                      fontWeight="600"
                    >
                      Included
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      display="flex"
                      color="#364147"
                      alignItems="center"
                      fontFamily="inter"
                      fontSize="14px"
                      fontWeight="600"
                    >
                      <ShoppingBagIcon sx={{ fontSize: "16px" }} /> 1 Personal
                      item {drawerData.personalItemWeight}kg
                    </Typography>
                    <Typography
                      display="flex"
                      color="#364147"
                      alignItems="center"
                      fontFamily="inter"
                      fontSize="14px"
                      fontWeight="600"
                    >
                      <LuggageIcon sx={{ fontSize: "16px" }} /> {cabinBaggage}{" "}
                      Cabin bag {drawerData.holdWeight}kg
                    </Typography>
                    <Typography
                      display="flex"
                      color="#364147"
                      alignItems="center"
                      fontFamily="inter"
                      fontSize="14px"
                      fontWeight="600"
                    >
                      <BusinessCenterIcon sx={{ fontSize: "16px" }} />{" "}
                      {checkedBaggage} Checked bag {drawerData.handWeight}kg
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box
                bgcolor="#EBF8FF"
                my="2vh"
                padding="0 10px"
                borderRadius="4px"
              >
                <Typography
                  color="#364147"
                  padding="14px 0"
                  borderBottom="1px solid #364147"
                  fontWeight="700"
                >
                  Add baggage
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    padding="14px 0"
                    fontSize="14px"
                    display="flex"
                    alignItems="center"
                  >
                    <Checkbox
                      onChange={(e) =>
                        e.target.checked
                          ? setBagAddedPrice(drawerData.holdDimensionsSum)
                          : setBagAddedPrice(0)
                      }
                      sx={{ padding: "0px 10px 0 0" }}
                    />{" "}
                    Add 1 extra checked baggage
                  </Typography>
                  <Typography
                    fontFamily="Inter"
                    fontWeight="700"
                    fontSize="16px"
                  >
                    ${drawerData.holdDimensionsSum}
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                bgcolor="#F2F4F3"
                padding="16px 10px"
                borderRadius="4px"
              >
                <Typography display="flex" alignItems="center">
                  TOTAL &nbsp; <ErrorIcon sx={{ color: "#6373CF" }} />
                </Typography>
                <Typography fontFamily="Inter" fontSize="16px" fontWeight="700">
                  $ {drawerData.price + bagAddedPrice}
                </Typography>
              </Box>
            </Box>
          </Card>
        </Box>
      </Drawer>
      <Grid
        xs={12}
        sx={{
          backgroundImage: `url(assets/images/litineraryback.png)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Navbar />
      </Grid>
      <Grid container bgcolor="#F3F6FC">
        <Grid
          item
          md={3}
          display={{ xs: "none", lg: "block" }}
          padding="20px 30px"
        >
          <Button
            variant={flightType == false ? "contained" : "outlined"}
            onClick={() => {
              setOneWay(false);
              sendFlightType(false);
            }}
            sx={{
              textTransform: "none",
              padding: "20px 0",
              width: "50%",
              borderTopLeftRadius: "12px",
              borderBottomLeftRadius: "12px",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              backgroundColor: flightType == true ? "#ffffff" : "#3546AB",
            }}
          >
            Return &nbsp; {flightType == true ? "" : <DoneIcon />}
          </Button>
          <Button
            variant={flightType == false ? "outlined" : "contained"}
            onClick={() => {
              setOneWay(true);
              sendFlightType(true);
            }}
            sx={{
              textTransform: "none",
              padding: "20px 0",
              width: "50%",
              borderTopLeftRadius: "0",
              borderBottomLeftRadius: "0",
              borderTopRightRadius: "12px",
              borderBottomRightRadius: "12px",
              backgroundColor: flightType == true ? "#3546AB" : "#ffffff",
            }}
          >
            One Way &nbsp; {flightType == true ? <DoneIcon /> : ""}
          </Button>
          {/* <BottomNavigation sx={{ width: 500 }} value={value} onChange={handleChange}>
          <BottomNavigationAction
            label="Recents"
            value="recents"
          />
          <BottomNavigationAction
            label="Favorites"
            value="favorites"
          />
        </BottomNavigation> */}
          {/* <Typography
            component={Button}
            padding="0.4em 1.5em"
            fontWeight="bold"
            fontSize="0.8em"
            textTransform="none"
            color="#4C4C4C"
            bgcolor="#E3E6F7"
          >
            <HotelIcon /> &nbsp; Search results
          </Typography> */}
          {/* <Typography
            component={Button}
            padding="0.4em 1.5em"
            margin="0 1.5em"
            fontWeight="bold"
            fontSize="0.8em"
            textTransform="none"
            color="#4C4C4C"
            bgcolor="#FFFFFF"
          >
            <FavoriteIcon /> &nbsp; Favourites
          </Typography> */}
        </Grid>
        <Grid
          item
          margin={{ xs: "0 2vw", md: "0 0 2vh", lg: "0 0 2vh" }}
          justifyContent="center"
          display={{ lg: "flex" }}
          xs={12}
          md={12}
          lg={7}
        >
          <BookingModel
            oneWay={oneWay}
            handleCityFlightsSearch={handleFlightSearch}
            getDepartureCityName={handleDepartureCityName}
            getArrivalCityName={handleArrivalCityName}
            currentPage="flights-list"
            onButtonClick={() => setTriggerSearch(!triggerSearch)}
          />
        </Grid>
        <Grid item xs={0} md={0} lg={2}></Grid>
        <Grid
          item
          xs={12}
          md={5}
          lg={3}
          padding={{ xs: "0 30px 20px", md: "0 5vw 0 0", lg: "0 30px 20px" }}
        >
          <Card
            sx={{
              bgcolor: "white",
              display: { xs: "none", md: "block" },
              borderRadius: "12px",
              margin: { xs: "1.5em 0", md: "0", lg: "0" },
              padding: "1.5em",
              position: "sticky",
              top: "3vh",
              boxShadow: "none",
            }}
          >
            {/* itinerary code start */}

            <Grid item lg={12}>
              {/* <Box
                padding="1em"
                boxShadow="0px 1px 4px 0px rgba(37, 42, 49, 0.12), 0px 0px 2px 0px rgba(37, 42, 49, 0.16)"
              >
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    color="#252A31"
                    fontWeight="700"
                    fontFamily="inter"
                  >
                    Set up price alets
                  </Typography>
                  <Switch />
                </Box>
                <Typography color="#252A31">
                  Receive alerts when the prices for this route change.
                </Typography>
              </Box> */}
              {/* <Accordion expanded sx={{ border: "none", boxShadow: "none" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    color="#252A31"
                    variant="body1"
                    fontFamily="inter"
                    sx={{ fontWeight: 700, mb: 1 }}
                  >
                    Bags
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "0" }}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    padding="1em"
                  >
                    <Typography
                      color="#252A31"
                      display="flex"
                      alignItems="center"
                    >
                      <LuggageIcon /> Cabin Baggage
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Button className="minus" onClick={minusfromBaggage}>
                        -
                      </Button>
                      <Typography sx={{ width: "20px", minWidth: "20px" }}>
                        {cabinBaggage}
                      </Typography>
                      <Button className="plus" onClick={addtoBaggage}>
                        +
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    padding="1em"
                  >
                    <Typography
                      color="#252A31"
                      display="flex"
                      alignItems="center"
                    >
                      <LuggageIcon /> Checked Baggage
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Button
                        className="minus"
                        onClick={minusfromCheckedBaggage}
                      >
                        -
                      </Button>
                      <Typography sx={{ width: "20px", minWidth: "20px" }}>
                        {checkedBaggage}
                      </Typography>
                      <Button className="plus" onClick={addtoCheckedBaggage}>
                        +
                      </Button>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion> */}

              <Accordion expanded sx={{ border: "none", boxShadow: "none" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    color="#252A31"
                    variant="body1"
                    fontFamily="inter"
                    sx={{ fontWeight: 700, mb: 1 }}
                  >
                    Stops
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "0" }}>
                  <Box padding="1em">
                    <FormControl sx={{ pl: "0.5em" }}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value=""
                          control={<Radio onChange={applyMaxStopover} />}
                          label="any"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio onChange={applyMaxStopover} />}
                          label="direct"
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio onChange={applyMaxStopover} />}
                          label="Up to 1 stop"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio onChange={applyMaxStopover} />}
                          label="Up to 2 stops"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Tooltip
                      title="Stopovers of 12 hours or more will be included in the search results"
                      arrow
                    >
                      <Typography
                        color="#252A31"
                        display="flex"
                        alignItems="center"
                      >
                        <Checkbox
                          defaultChecked
                          onChange={(e) => setOvernightStops(e.target.checked)}
                        />
                        Allow overnight stops &nbsp;{" "}
                        <ErrorIcon sx={{ color: "#6373CF" }} />
                      </Typography>
                    </Tooltip>
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ border: "none", boxShadow: "none" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    color="#252A31"
                    variant="body1"
                    fontFamily="inter"
                    sx={{ fontWeight: 700, mb: 1 }}
                  >
                    Carriers
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "0" }}>
                  <Box padding="1em">
                    <Typography
                      color="#252A31"
                      fontFamily="inter"
                      sx={{ fontWeight: 700, mb: 1 }}
                    >
                      In results
                    </Typography>
                    {flightNames}
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ border: "none", boxShadow: "none" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    color="#252A31"
                    variant="body1"
                    fontFamily="inter"
                    sx={{ fontWeight: 700, mb: 1 }}
                  >
                    Times
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "0" }}>
                  <Box padding="1em">
                    {/* <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList  aria-label="lab API tabs example">
            <Tab label="Item One" value="1" />
            <Tab label="Item Two" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">Item One</TabPanel>
        <TabPanel value="2">Item Two</TabPanel>
      </TabContext> */}
                    <Typography
                      color="#252A31"
                      fontFamily="inter"
                      sx={{ fontWeight: 700, mb: 1 }}
                    >
                      Departure
                    </Typography>

                    <Typography
                      color="primary"
                      fontFamily="inter"
                      sx={{ fontWeight: 700, mb: 1 }}
                    >
                      From{" "}
                      {formatTime(
                        Math.floor(departureTime[0] / 60),
                        departureTime[0] % 60
                      )}{" "}
                      to{" "}
                      {formatTime(
                        Math.floor(departureTime[1] / 60),
                        departureTime[1] % 60
                      )}
                    </Typography>
                    <Slider
                      track={false}
                      sx={{
                        color: "#6373CF",
                        "& .MuiSlider-thumb": {
                          border: "1px solid #3546AB",
                          backgroundColor: "white",
                        },
                      }}
                      aria-label="Price"
                      value={departureTime}
                      valueLabelDisplay="auto"
                      onChangeCommitted={handleDeparturetime}
                      max={1440}
                      step={15}
                    />

                    <Box display="flex" justifyContent="space-between">
                      <Typography
                        color="#7F7C88"
                        fontFamily="inter"
                        fontSize="14px"
                        sx={{ fontWeight: 600, mb: 1 }}
                      >
                        00:00
                      </Typography>

                      <Typography
                        color="#7F7C88"
                        fontFamily="inter"
                        fontSize="14px"
                        sx={{ fontWeight: 600, mb: 1 }}
                      >
                        23:59
                      </Typography>
                    </Box>
                    <Typography
                      color="#252A31"
                      fontFamily="inter"
                      sx={{ fontWeight: 700, mb: 1 }}
                    >
                      Arrival
                    </Typography>

                    <Typography
                      color="primary"
                      fontFamily="inter"
                      sx={{ fontWeight: 700, mb: 1 }}
                    >
                      From {arrivalTime[0]}:00 to {arrivalTime[1]}:00
                    </Typography>
                    <Slider
                      track={false}
                      sx={{
                        color: "#6373CF",
                        "& .MuiSlider-thumb": {
                          border: "1px solid #3546AB",
                          backgroundColor: "white",
                        },
                      }}
                      aria-label="Price"
                      defaultValue={[0, 24]}
                      value={arrivalTime}
                      valueLabelDisplay="auto"
                      onChangeCommitted={handleArrivaltime}
                      min={0}
                      max={24}
                    />

                    <Box display="flex" justifyContent="space-between">
                      <Typography
                        color="#7F7C88"
                        fontFamily="inter"
                        fontSize="14px"
                        sx={{ fontWeight: 600, mb: 1 }}
                      >
                        00:00
                      </Typography>

                      <Typography
                        color="#7F7C88"
                        fontFamily="inter"
                        fontSize="14px"
                        sx={{ fontWeight: 600, mb: 1 }}
                      >
                        23:59
                      </Typography>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ border: "none", boxShadow: "none" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    color="#252A31"
                    variant="body1"
                    fontFamily="inter"
                    sx={{ fontWeight: 700, mb: 1 }}
                  >
                    Duration
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "0" }}>
                  <Box padding="1em">
                    <Typography
                      color="#252A31"
                      fontFamily="inter"
                      sx={{ fontWeight: 700, mb: 1 }}
                    >
                      Max travel time
                    </Typography>

                    <Typography
                      color="primary"
                      fontFamily="inter"
                      sx={{ fontWeight: 700, mb: 1 }}
                    >
                      upto {travelDuration} hours
                    </Typography>
                    <Slider
                      track={false}
                      sx={{
                        color: "#6373CF",
                        "& .MuiSlider-thumb": {
                          border: "1px solid #3546AB",
                          backgroundColor: "white",
                        },
                      }}
                      aria-label="Price"
                      value={travelDuration}
                      valueLabelDisplay="auto"
                      onChangeCommitted={handleTravelDuration}
                      min={1}
                      max={60}
                    />

                    <Box display="flex" justifyContent="space-between">
                      <Typography
                        color="#7F7C88"
                        fontFamily="inter"
                        fontSize="14px"
                        sx={{ fontWeight: 600, mb: 1 }}
                      ></Typography>

                      <Typography
                        color="#7F7C88"
                        fontFamily="inter"
                        fontSize="14px"
                        sx={{ fontWeight: 600, mb: 1 }}
                      >
                        60 hours
                      </Typography>
                    </Box>
                  </Box>
                  <Box padding="1em">
                    <Typography
                      color="#252A31"
                      fontFamily="inter"
                      sx={{ fontWeight: 700, mb: 1 }}
                    >
                      Stopover
                    </Typography>

                    <Typography
                      color="primary"
                      fontFamily="inter"
                      sx={{ fontWeight: 700, mb: 1 }}
                    >
                      {formatTime(
                        Math.floor(stopOver[0] / 60),
                        stopOver[0] % 60
                      )}{" "}
                      -{" "}
                      {formatTime(
                        Math.floor(stopOver[1] / 60),
                        stopOver[1] % 60
                      )}{" "}
                      hours
                    </Typography>
                    <Slider
                      track={false}
                      sx={{
                        color: "#6373CF",
                        "& .MuiSlider-thumb": {
                          border: "1px solid #3546AB",
                          backgroundColor: "white",
                        },
                      }}
                      aria-label="Price"
                      value={stopOver}
                      valueLabelDisplay="auto"
                      onChangeCommitted={handleStopOver}
                      min={120}
                      max={1440}
                      step={15}
                    />
                    <Box display="flex" justifyContent="space-between">
                      <Typography
                        color="#7F7C88"
                        fontFamily="inter"
                        fontSize="14px"
                        sx={{ fontWeight: 600, mb: 1 }}
                      >
                        2 hours
                      </Typography>

                      <Typography
                        color="#7F7C88"
                        fontFamily="inter"
                        fontSize="14px"
                        sx={{ fontWeight: 600, mb: 1 }}
                      >
                        24 hours
                      </Typography>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ border: "none", boxShadow: "none" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    color="#252A31"
                    variant="body1"
                    fontFamily="inter"
                    sx={{ fontWeight: 700, mb: 1 }}
                  >
                    Price
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "0" }}>
                  <Box padding="1em">
                    <Typography
                      color="primary"
                      fontFamily="inter"
                      sx={{ fontWeight: 700, mb: 1 }}
                    >
                      $ {flightPrice[1]}
                    </Typography>

                    <Slider
                      track={false}
                      sx={{
                        color: "#6373CF",
                        "& .MuiSlider-thumb": {
                          border: "1px solid #3546AB",
                          backgroundColor: "white",
                        },
                      }}
                      aria-label="Price"
                      value={flightPrice}
                      valueLabelDisplay="auto"
                      step={10}
                      marks
                      onChangeCommitted={handlePriceChange}
                      min={0}
                      max={3000}
                    />
                    <Box display="flex" justifyContent="space-between">
                      <Typography
                        color="#7F7C88"
                        fontFamily="inter"
                        fontSize="14px"
                        sx={{ fontWeight: 600, mb: 1 }}
                      >
                        $0
                      </Typography>

                      <Typography
                        color="#7F7C88"
                        fontFamily="inter"
                        fontSize="14px"
                        sx={{ fontWeight: 600, mb: 1 }}
                      >
                        $3000
                      </Typography>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Card>
        </Grid>

        <Grid
          item
          lg={7}
          md={7}
          xs={12}
          bgcolor="white"
          borderRadius="12px"
          padding="0 1.5em"
        >
          <Box
            display={{ xs: "none", md: "flex", lg: "flex" }}
            justifyContent="space-between"
            alignItems="center"
          >
            {showAdditionalFeeSign && (
              <Tooltip
                title="Catch U There's prices include all mandatory taxes and charges, but additional fees for services like baggage may apply by some airlines/agents."
                arrow
              >
                <Typography
                  color="#6373CF"
                  display="flex"
                  alignItems="center"
                  padding="2vh 0"
                >
                  <ErrorIcon sx={{ color: "#6373CF" }} /> &nbsp; Additional fees
                  may apply
                </Typography>
              </Tooltip>
            )}
          </Box>
          <Box
            padding="2vh 0"
            display={{ xs: "flex", md: "flex", lg: "none" }}
            justifyContent="space-between"
          >
            <Button
              sx={{
                textTransform: "none",
                color: "#0C1027",
                width: "30%",
                bgcolor: "#F6F7F9",
                border: "1px solid #CAC8CC",
                borderRadius: "8px",
              }}
            >
              <TuneIcon />
              &nbsp;Filters
            </Button>
            <Button
              sx={{
                textTransform: "none",
                color: "#0C1027",
                width: "30%",
                bgcolor: "#F6F7F9",
                border: "1px solid #CAC8CC",
                borderRadius: "8px",
              }}
            >
              <AccessTimeIcon />
              &nbsp;Times
            </Button>
            <Button
              sx={{
                textTransform: "none",
                color: "#0C1027",
                width: "30%",
                bgcolor: "#F6F7F9",
                border: "1px solid #CAC8CC",
                borderRadius: "8px",
              }}
            >
              <SortIcon />
              &nbsp;Sort
            </Button>
          </Box>
          {/* <FlightsCard /> */}
          {loading == true ? (
            <Box
              display="flex"
              justifyContent="center"
              paddingTop="15vh"
              height="100vh"
              width="100%"
            >
              <img
                src="./assets/images/loading-gif.gif"
                width="550px"
                height="300px"
              />
              {/* <Blocks
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                visible={true}
              /> */}
            </Box>
          ) : (
            flightCards
          )}
          {/* {displayCount < flights.length && (
            <Button onClick={loadMore}>Load More</Button>
          )} */}
        </Grid>
      </Grid>
    </>
  );
}
