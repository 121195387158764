import * as React from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import FilterListIcon from "@mui/icons-material/FilterList";
import TuneIcon from "@mui/icons-material/Tune";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import MapIcon from "@mui/icons-material/Map";
import SortIcon from "@mui/icons-material/Sort";
import CloseIcon from "@mui/icons-material/Close";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Rating,
  Drawer,
  Slider,
  useMediaQuery,
  Radio,
  IconButton,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { useState } from "react";

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));
export default function HotelFilters(props) {
  let { getFilters, getPrice, applyFilters, handleSortChange } = props;
  let isLargeScreen = useMediaQuery("(min-width:768px)");

  let addFilter = (e) => {
    getFilters(e);
    // applyFilters();
  };

  const [price, setPrice] = useState([0, 1000]);
  const handlePriceChange = (event, newValue) => {
    setPrice(newValue);
    getPrice(newValue);
    // applyFilters();
  };

  const { window } = props;
  const [open, setOpen] = React.useState(false);
  const [sortDrawerOpen, setSortDrawerOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const toggleSortDrawer = (newOpen) => () => {
    setSortDrawerOpen(!sortDrawerOpen);
  };

  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const allFilters = [
    <>
      <Box
        borderBottom="1px solid #E0E0E0"
        paddingBottom="2vh"
        marginBottom="2vh"
      >
        {isLargeScreen ? (
          ""
        ) : (
          <Typography
            color="#4D4D4D"
            variant="body1"
            fontFamily="inter"
            sx={{ fontWeight: 700, mb: 1, paddingLeft: "15px" }}
          >
            Popular filters
          </Typography>
        )}
        {/* <TextField
                  sx={{
                    border: "1px solid #E5E7EB",
                    margin: "20px 10px 0",
                    borderRadius: "0.6em",
                    width: "100%",
                  }}
                  placeholder="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                /> */}
        <Box padding="3px">
          <Typography
            fontSize="16px"
            color="#5A5B5B"
            display="flex"
            alignItems="center"
          >
            <Checkbox
              name="popularFilters"
              value={"pool"}
              // onClick={clickedCheckboxValue}
            />
            Pool
          </Typography>
          <Typography
            fontSize="16px"
            color="#5A5B5B"
            display="flex"
            alignItems="center"
          >
            <Checkbox />
            Car Park
          </Typography>
          <Typography
            fontSize="16px"
            color="#5A5B5B"
            display="flex"
            alignItems="center"
          >
            <Checkbox
              name="popularFilters"
              value={"restaurants"}
              // onClick={clickedCheckboxValue}
            />
            Restaurants
          </Typography>
          <Typography
            fontSize="16px"
            color="#5A5B5B"
            display="flex"
            alignItems="center"
          >
            <Checkbox
              name="popularFilters"
              value={"wifi"}
              // onClick={clickedCheckboxValue}
            />
            Wifi
          </Typography>
          <Typography
            fontSize="16px"
            color="#5A5B5B"
            display="flex"
            alignItems="center"
          >
            <Checkbox
              name="popularFilters"
              value={"ariConditioning"}
              // onClick={clickedCheckboxValue}
            />
            Air conditioning
          </Typography>
          <Typography
            fontSize="16px"
            color="#5A5B5B"
            display="flex"
            alignItems="center"
          >
            <Checkbox
              name="popularFilters"
              value={"gym"}
              // onClick={clickedCheckboxValue}
            />
            Gym
          </Typography>
          <Typography
            fontSize="16px"
            color="#5A5B5B"
            display="flex"
            alignItems="center"
          >
            <Checkbox
              name="popularFilters"
              value={"spa"}
              // onClick={clickedCheckboxValue}
            />
            Spa
          </Typography>
        </Box>
      </Box>
      {/* <Box
        borderBottom="1px solid #E0E0E0"
        paddingBottom="2vh"
        marginBottom="2vh"
      >
        <Typography
          color="#4D4D4D"
          variant="body1"
          fontFamily="inter"
          sx={{ fontWeight: 700, mb: 1, paddingLeft: "15px" }}
        >
          Property type
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox
            name="propertyType"
            value={"appartments"}
            // onClick={clickedCheckboxValue}
          />
          Appartments
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox
            name="propertyType"
            value={"vacationHomes"}
            // onClick={clickedCheckboxValue}
          />
          Vacation Homes
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox
            name="propertyType"
            value={"villas"}
            // onClick={clickedCheckboxValue}
          />
          Villas
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox
            name="propertyType"
            value={"guestHouses"}
            // onClick={clickedCheckboxValue}
          />
          Guest houses
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox
            name="propertyType"
            value={"hotels"}
            // onClick={clickedCheckboxValue}
          />
          Hotels
        </Typography>
      </Box>
      <Box
        borderBottom="1px solid #E0E0E0"
        paddingBottom="2vh"
        marginBottom="2vh"
      >
        <Typography
          color="#4D4D4D"
          variant="body1"
          fontFamily="inter"
          sx={{ fontWeight: 700, mb: 1, paddingLeft: "15px" }}
        >
          Facilities
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox
            name="facilities"
            value={"kitchen"}
            // onClick={clickedCheckboxValue}
          />
          Kitchen
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox
            name="facilities"
            value={"tv"}
            // onClick={clickedCheckboxValue}
          />
          TV
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox
            name="facilities"
            value={"resturant"}
            // onClick={clickedCheckboxValue}
          />
          Resturant
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox
            name="facilities"
            value={"wifi"}
            // onClick={clickedCheckboxValue}
          />
          Wifi
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox
            name="facilities"
            value={"airConditioning"}
            // onClick={clickedCheckboxValue}
          />
          Air conditioning
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox
            name="facilities"
            value={"gym"}
            // onClick={clickedCheckboxValue}
          />
          Gym
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox
            name="facilities"
            value={"spa"}
            // onClick={clickedCheckboxValue}
          />
          Spa
        </Typography>
      </Box>
      <Box
        borderBottom="1px solid #E0E0E0"
        paddingBottom="2vh"
        marginBottom="2vh"
      >
        <Typography
          color="#4D4D4D"
          variant="body1"
          fontFamily="inter"
          sx={{ fontWeight: 700, mb: 1, paddingLeft: "15px" }}
        >
          Payment options
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox
            name="paymentOptions"
            value={"freeCancellation"}
            // onClick={clickedCheckboxValue}
          />
          Free cancellaiton
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox
            name="paymentOptions"
            value={"payAtTheHotel"}
            // onClick={clickedCheckboxValue}
          />
          Pay at the hotel
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox
            name="paymentOptions"
            value={"bookNowPayLater"}
            // onClick={clickedCheckboxValue}
          />
          Book now, pay later
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox
            name="paymentOptions"
            value={"payNow"}
            // onClick={clickedCheckboxValue}
          />
          Pay now
        </Typography>
      </Box>
      <Box
        borderBottom="1px solid #E0E0E0"
        paddingBottom="2vh"
        marginBottom="2vh"
      >
        <Typography
          color="#4D4D4D"
          variant="body1"
          fontFamily="inter"
          sx={{ fontWeight: 700, mb: 1, paddingLeft: "15px" }}
        >
          Bed type
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox
            name="bedType"
            value={"double"}
            // onClick={clickedCheckboxValue}
          />
          Double
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox
            name="bedType"
            value={"singleTwin"}
            // onClick={clickedCheckboxValue}
          />
          Single/twin
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox
            name="bedType"
            value={"queen"}
            // onClick={clickedCheckboxValue}
          />
          Queen
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox
            name="bedType"
            value={"king"}
            // onClick={clickedCheckboxValue}
          />
          King
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox
            name="bedType"
            value={"bunkBed"}
            // onClick={clickedCheckboxValue}
          />
          Bunk bed
        </Typography>
      </Box>
      <Box
        borderBottom="1px solid #E0E0E0"
        paddingBottom="2vh"
        marginBottom="2vh"
      >
        <Typography
          color="#4D4D4D"
          variant="body1"
          fontFamily="inter"
          sx={{ fontWeight: 700, mb: 1, paddingLeft: "15px" }}
        >
          Wellness and Activities
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox />
          Appartments
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox />
          Vacation Homes
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox />
          Villas
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox />
          Guest houses
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox />
          Hotels
        </Typography>
      </Box>
      <Box
        borderBottom="1px solid #E0E0E0"
        paddingBottom="2vh"
        marginBottom="2vh"
      >
        <Typography
          color="#4D4D4D"
          variant="body1"
          fontFamily="inter"
          sx={{ fontWeight: 700, mb: 1, paddingLeft: "15px" }}
        >
          Popular hotel brands
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox />
          Appartments
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox />
          Vacation Homes
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox />
          Villas
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox />
          Guest houses
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox />
          Hotels
        </Typography>
      </Box>
      <Box
        borderBottom="1px solid #E0E0E0"
        paddingBottom="2vh"
        marginBottom="2vh"
      >
        <Typography
          color="#4D4D4D"
          variant="body1"
          fontFamily="inter"
          sx={{ fontWeight: 700, mb: 1, paddingLeft: "15px" }}
        >
          Room facilities
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox />
          Appartments
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox />
          Vacation Homes
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox />
          Villas
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox />
          Guest houses
        </Typography>
        <Typography
          fontSize="16px"
          color="#5A5B5B"
          display="flex"
          alignItems="center"
        >
          <Checkbox />
          Hotels
        </Typography>
      </Box> */}
    </>,
  ];
  return (
    <>
      {isLargeScreen ? (
        <>
          <Box
            padding="0 15px 2vh"
            borderBottom="1px solid #E0E0E0"
            marginBottom="2vh"
          >
            <Typography
              color="#4D4D4D"
              variant="body1"
              fontFamily="inter"
              sx={{ fontWeight: 700, mb: 1 }}
            >
              Your Budget
            </Typography>
            <Slider
              track={false}
              sx={{
                color: "#6373CF",
                "& .MuiSlider-thumb": {
                  border: "1px solid #3546AB",
                  backgroundColor: "white",
                },
              }}
              aria-label="Price"
              defaultValue={[0, 1000]}
              valueLabelDisplay="auto"
              step={10}
              marks
              onChangeCommitted={handlePriceChange}
              min={0}
              max={2000}
            />
            <Box display="flex" justifyContent="space-between">
              <Typography fontSize="12px" color="#4D4D4D">
                Price per night
              </Typography>
              <Typography fontSize="12px" color="#4D4D4D">
                ${price[0]} - ${price[1]}
              </Typography>
              {/* <Typography variant="body2" sx={{ mb: 1 }}>
                   $ {price[0]} - $ {price[1]} +
                    </Typography> 
          
                    <Slider
                    value={price}
                     onChange={handlePriceChange}
                     valueLabelDisplay="auto"
                       max={3000}
                        />*/}
            </Box>
          </Box>
          <Box paddingBottom="2vh" marginBottom="2vh">
            <Typography
              color="#4D4D4D"
              variant="body1"
              padding="15px"
              fontFamily="inter"
              sx={{ fontWeight: 700, mb: 1 }}
            >
              Hotel Class
            </Typography>
            <Box paddingLeft="3px">
              <Typography
                fontSize="12px"
                color="#5A5B5B"
                display="flex"
                alignItems="center"
                marginBottom="-10px"
              >
                <Checkbox
                  value="5.0"
                  name="Rating"
                  onChange={addFilter}
                  // onChange={clickedCheckboxValue}
                />
                <Rating
                  value={5}
                  sx={{ "& .MuiRating-iconEmpty": { display: "none" } }}
                  icon={<FiberManualRecordIcon sx={{ color: "#00AA6C" }} />}
                  emptyIcon={
                    <FiberManualRecordOutlinedIcon
                      sx={{ color: "#00AA6C" }}
                      fontSize="inherit"
                    />
                  }
                  readOnly
                />
                {/* <Rating
                      readOnly
                      value={5}
                      sx={{ "& .MuiRating-iconEmpty": { display: "none" } }}
                    />{" "}
                    &nbsp; (14) */}
              </Typography>
              <Typography
                fontSize="12px"
                color="#5A5B5B"
                display="flex"
                alignItems="center"
                marginBottom="-10px"
              >
                <Checkbox
                  name="Rating"
                  value="4.0"
                  onChange={addFilter}
                  // onChange={clickedCheckboxValue}
                />
                <Rating
                  value={4}
                  sx={{ "& .MuiRating-iconEmpty": { display: "none" } }}
                  icon={<FiberManualRecordIcon sx={{ color: "#00AA6C" }} />}
                  emptyIcon={
                    <FiberManualRecordOutlinedIcon
                      sx={{ color: "#00AA6C" }}
                      fontSize="inherit"
                    />
                  }
                  readOnly
                />
              </Typography>
              <Typography
                padding="0"
                fontSize="12px"
                color="#5A5B5B"
                display="flex"
                alignItems="center"
                marginBottom="-10px"
              >
                <Checkbox
                  value="3.0"
                  name="Rating"
                  onChange={addFilter}
                  // onChange={clickedCheckboxValue}
                />
                <Rating
                  value={3}
                  sx={{ "& .MuiRating-iconEmpty": { display: "none" } }}
                  icon={<FiberManualRecordIcon sx={{ color: "#00AA6C" }} />}
                  emptyIcon={
                    <FiberManualRecordOutlinedIcon
                      sx={{ color: "#00AA6C" }}
                      fontSize="inherit"
                    />
                  }
                  readOnly
                />
              </Typography>
              <Typography
                fontSize="12px"
                color="#5A5B5B"
                display="flex"
                alignItems="center"
                marginBottom="-10px"
              >
                <Checkbox
                  name="Rating"
                  value="2.0"
                  onChange={addFilter}
                  // onChange={clickedCheckboxValue}
                />
                <Rating
                  value={2}
                  sx={{ "& .MuiRating-iconEmpty": { display: "none" } }}
                  icon={<FiberManualRecordIcon sx={{ color: "#00AA6C" }} />}
                  emptyIcon={
                    <FiberManualRecordOutlinedIcon
                      sx={{ color: "#00AA6C" }}
                      fontSize="inherit"
                    />
                  }
                  readOnly
                />
              </Typography>
              <Typography
                fontSize="12px"
                color="#5A5B5B"
                display="flex"
                alignItems="center"
                marginBottom="-10px"
              >
                <Checkbox
                  name="Rating"
                  value="1.0"
                  onChange={addFilter}
                  // onChange={clickedCheckboxValue}
                />
                <Rating
                  value={1}
                  sx={{ "& .MuiRating-iconEmpty": { display: "none" } }}
                  icon={<FiberManualRecordIcon sx={{ color: "#00AA6C" }} />}
                  emptyIcon={
                    <FiberManualRecordOutlinedIcon
                      sx={{ color: "#00AA6C" }}
                      fontSize="inherit"
                    />
                  }
                  readOnly
                />
              </Typography>
              <Typography
                fontSize="12px"
                color="#5A5B5B"
                display="flex"
                alignItems="center"
                marginBottom="-10px"
              >
                <Checkbox
                  name="Rating"
                  value="0"
                  onChange={addFilter}
                  // onChange={clickedCheckboxValue}
                />
                No rating
              </Typography>
            </Box>
            {/* <Button onClick={applySelectedFilter}>Apply filters</Button> */}
          </Box>
          <Accordion sx={{ border: "none", boxShadow: "none" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                color="#4D4D4D"
                variant="body1"
                fontFamily="inter"
                sx={{ fontWeight: 700, mb: 1 }}
              >
                Show more
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0" }}>
              {allFilters}
            </AccordionDetails>
          </Accordion>
        </>
      ) : (
        <>
          {/* <Global
            styles={{
              ".MuiDrawer-root > .MuiPaper-root": {
                height: `calc(100% - ${drawerBleeding}px)`,
                overflow: "visible",
              },
            }}
          /> */}
          <Box width="100%" display="flex" justifyContent="space-between" marginTop={{xs:'2vh', md:'2vh', lg:'0'}}>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "#F6F7F9",
                color: "black",
                borderRadius: "8px",
                fontSize: "12px",
                fontWeight: "600",
                width:'27vw',
                fontFamily: "inter",
                textTransform: "none",
              }}
              onClick={toggleDrawer(true)}
            >
              <TuneIcon fontSize="small" />
              &nbsp; Filters
            </Button>
            <Button
              variant="outlined"
              disabled
              sx={{
                backgroundColor: "#F6F7F9",
                color: "black",
                borderRadius: "8px",
                fontSize: "12px",
                fontWeight: "600",
                width: "27vw",
                fontFamily: "inter",
                textTransform: "none",
              }}
              onClick={toggleDrawer(true)}
            >
              <MapIcon fontSize="small" />
              &nbsp; Map
            </Button>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "#F6F7F9",
                color: "black",
                borderRadius: "8px",
                fontSize: "12px",
                fontWeight: "600",
                width: "27vw",
                fontFamily: "inter",
                textTransform: "none",
              }}
              onClick={toggleSortDrawer(true)}
            >
              <SortIcon fontSize="small" />
              &nbsp; Sort
            </Button>
          </Box>
          <Drawer
            container={container}
            anchor="bottom"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <StyledBox
              sx={{
                px: 2,
                pb: 2,
                height: "100vh",
                overflow: "auto",
              }}
            >
              <Box
                padding="0 15px 2vh"
                borderBottom="1px solid #E0E0E0"
                marginBottom="2vh"
                pt="2vh"
              >
                <Typography
                  color="#4D4D4D"
                  variant="body1"
                  fontFamily="inter"
                  sx={{ fontWeight: 700, mb: 1 }}
                >
                  Your Budget
                </Typography>
                <Slider
                  track={false}
                  sx={{
                    color: "#6373CF",
                    "& .MuiSlider-thumb": {
                      border: "1px solid #3546AB",
                      backgroundColor: "white",
                    },
                  }}
                  aria-label="Price"
                  defaultValue={[0, 1000]}
                  valueLabelDisplay="auto"
                  step={10}
                  marks
                  onChangeCommitted={handlePriceChange}
                  min={0}
                  max={2000}
                />
                <Box display="flex" justifyContent="space-between">
                  <Typography fontSize="12px" color="#4D4D4D">
                    Price per night
                  </Typography>
                  <Typography fontSize="12px" color="#4D4D4D">
                    ${price[0]} - ${price[1]}
                  </Typography>
                  {/* <Typography variant="body2" sx={{ mb: 1 }}>
                   $ {price[0]} - $ {price[1]} +
                    </Typography> 
          
                    <Slider
                    value={price}
                     onChange={handlePriceChange}
                     valueLabelDisplay="auto"
                       max={3000}
                        />*/}
                </Box>
              </Box>
              <Box paddingBottom="2vh" marginBottom="2vh">
                <Typography
                  color="#4D4D4D"
                  variant="body1"
                  padding="15px"
                  fontFamily="inter"
                  sx={{ fontWeight: 700, mb: 1 }}
                >
                  Hotel Class
                </Typography>
                <Box paddingLeft="3px">
                  <Typography
                    fontSize="12px"
                    color="#5A5B5B"
                    display="flex"
                    alignItems="center"
                    marginBottom="-10px"
                  >
                    <Checkbox
                      value="5.0"
                      name="Rating"
                      onChange={addFilter}
                      // onChange={clickedCheckboxValue}
                    />
                    <Rating
                      value={5}
                      sx={{ "& .MuiRating-iconEmpty": { display: "none" } }}
                      icon={<FiberManualRecordIcon sx={{ color: "#00AA6C" }} />}
                      emptyIcon={
                        <FiberManualRecordOutlinedIcon
                          sx={{ color: "#00AA6C" }}
                          fontSize="inherit"
                        />
                      }
                      readOnly
                    />
                    {/* <Rating
                      readOnly
                      value={5}
                      sx={{ "& .MuiRating-iconEmpty": { display: "none" } }}
                    />{" "}
                    &nbsp; (14) */}
                  </Typography>
                  <Typography
                    fontSize="12px"
                    color="#5A5B5B"
                    display="flex"
                    alignItems="center"
                    marginBottom="-10px"
                  >
                    <Checkbox
                      name="Rating"
                      value="4.0"
                      onChange={addFilter}
                      // onChange={clickedCheckboxValue}
                    />
                    <Rating
                      value={4}
                      sx={{ "& .MuiRating-iconEmpty": { display: "none" } }}
                      icon={<FiberManualRecordIcon sx={{ color: "#00AA6C" }} />}
                      emptyIcon={
                        <FiberManualRecordOutlinedIcon
                          sx={{ color: "#00AA6C" }}
                          fontSize="inherit"
                        />
                      }
                      readOnly
                    />
                  </Typography>
                  <Typography
                    padding="0"
                    fontSize="12px"
                    color="#5A5B5B"
                    display="flex"
                    alignItems="center"
                    marginBottom="-10px"
                  >
                    <Checkbox
                      value="3.0"
                      name="Rating"
                      onChange={addFilter}
                      // onChange={clickedCheckboxValue}
                    />
                    <Rating
                      value={3}
                      sx={{ "& .MuiRating-iconEmpty": { display: "none" } }}
                      icon={<FiberManualRecordIcon sx={{ color: "#00AA6C" }} />}
                      emptyIcon={
                        <FiberManualRecordOutlinedIcon
                          sx={{ color: "#00AA6C" }}
                          fontSize="inherit"
                        />
                      }
                      readOnly
                    />
                  </Typography>
                  <Typography
                    fontSize="12px"
                    color="#5A5B5B"
                    display="flex"
                    alignItems="center"
                    marginBottom="-10px"
                  >
                    <Checkbox
                      name="Rating"
                      value="2.0"
                      onChange={addFilter}
                      // onChange={clickedCheckboxValue}
                    />
                    <Rating
                      value={2}
                      sx={{ "& .MuiRating-iconEmpty": { display: "none" } }}
                      icon={<FiberManualRecordIcon sx={{ color: "#00AA6C" }} />}
                      emptyIcon={
                        <FiberManualRecordOutlinedIcon
                          sx={{ color: "#00AA6C" }}
                          fontSize="inherit"
                        />
                      }
                      readOnly
                    />
                  </Typography>
                  <Typography
                    fontSize="12px"
                    color="#5A5B5B"
                    display="flex"
                    alignItems="center"
                    marginBottom="-10px"
                  >
                    <Checkbox
                      name="Rating"
                      value="1.0"
                      onChange={addFilter}
                      // onChange={clickedCheckboxValue}
                    />
                    <Rating
                      value={1}
                      sx={{ "& .MuiRating-iconEmpty": { display: "none" } }}
                      icon={<FiberManualRecordIcon sx={{ color: "#00AA6C" }} />}
                      emptyIcon={
                        <FiberManualRecordOutlinedIcon
                          sx={{ color: "#00AA6C" }}
                          fontSize="inherit"
                        />
                      }
                      readOnly
                    />
                  </Typography>
                  <Typography
                    fontSize="12px"
                    color="#5A5B5B"
                    display="flex"
                    alignItems="center"
                    marginBottom="-10px"
                  >
                    <Checkbox
                      name="Rating"
                      value="0"
                      onChange={addFilter}
                      // onChange={clickedCheckboxValue}
                    />
                    No rating
                  </Typography>
                </Box>
                {/* <Button onClick={applySelectedFilter}>Apply filters</Button> */}
              </Box>
              {allFilters}
              
            </StyledBox>
            <Box display='flex' justifyContent='center' position="fixed" width="100%" bottom='3vh'>
              <Button
                sx={{
                  position:'relative',
                  backgroundColor: "#3C50C3",
                  textTransform: "none",
                  width:'80%',
                }}
                onClick={toggleDrawer(false)}
                variant="contained"
              >
                Apply
              </Button>
              </Box>
          </Drawer>

          <SwipeableDrawer
            anchor="bottom"
            open={sortDrawerOpen}
            onClose={toggleSortDrawer(false)}
            sx={{"& .MuiPaper-root":{borderTopLeftRadius:'15px', borderTopRightRadius:'15px'}}}
          >
            <Box padding="2vh">
              <Typography
                color="#121416"
                fontSize="22px"
                fontFamily='inter'
                fontWeight="600"
              >
                Sort by
              </Typography>
              <IconButton
                sx={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                }}
                onClick={toggleSortDrawer(false)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box padding="0 5vw 2vh">
              {/* <Box padding="2vh 2vw">
              <Typography display="flex" alignItems="center" fontSize="14px" fontWeight='600'>
                <Radio name="sort" value="best" onChange={sortChange} />
                Best
              </Typography>
              <Typography display="flex" alignItems="center" fontSize="14px" fontWeight='600'>
                <Radio name="sort" value="price" onChange={sortChange}/>
                Price
              </Typography>
              <Typography display="flex" alignItems="center" fontSize="14px" fontWeight='600'>
                <Radio name="sort" value="rating" onChange={sortChange} />
                Rating
              </Typography> */}
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                onChange={handleSortChange}
              >
                <Typography
                  display="flex"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="600"
                  color="#333"
                >
                  <FormControlLabel value="best" control={<Radio />} />
                  Best
                </Typography>
                <Typography
                  display="flex"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="600"
                  color="#333"
                >
                  <FormControlLabel value="price" control={<Radio />} />
                  Price (lowest first)
                </Typography>
                <Typography
                  display="flex"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="600"
                  color="#333"
                >
                  <FormControlLabel value="top-reviewed" control={<Radio />} />
                  Top reviewed
                </Typography>
              </RadioGroup>
            </Box>
          </SwipeableDrawer>
        </>
      )}
    </>
  );
}
