import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Stack } from "@mui/system";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import FavoriteIcon from "@mui/icons-material/Favorite";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ExperienceItem from "./ExperienceItem";
import Slider from "@mui/material/Slider";
import Resturantcard from "./Resturantcard";
import StarIcon from "@mui/icons-material/Star";
import { iitineraryContext } from "../../pages/IitineraryContext";
import Flightcard from "./Flightcard";

import records from "../../Sample data/flights.json";
import Skeleton from "@mui/material/Skeleton";

import axios from "axios";

const flightsPerPage = 5;

const api = axios.create({
  baseURL:process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: "Bearer " + window.sessionStorage.getItem("token"),
  },
});

export default function SwapFlight(props) {
  const { addItemopen, handleaddItemClick, travellerDetail, planId } =
    useContext(iitineraryContext);
  const [price, setPrice] = React.useState([1, 3000]);
  const [allFlights, setAllFlights] = useState(null);
  const [updatedFlights, setupdatedFlights] = useState(null);
  const [sortingType, setSortingType] = useState("");
  const [collectionId, setCollectionId] = useState("");
  const [flightCarriers, setFlightCarriers] = useState([]);
  const [carrierFilters, setCarrierFilters] = useState([]);
  const [addedFilter, setAddedFilter] = useState(false);

  const [guestRating, setRating] = React.useState([1, 100]);
  const [next, setNext] = useState(5);
  const [postsToShow, setPostsToShow] = useState([]);
  let arrayForHoldingPosts = [];

  useEffect(() => {
    
    api
      .get(
        "/api/plans/" +
          planId +
          "/getflights?originCity=" +
          travellerDetail.startLocation.city +
          "&destinationCity=" +
          travellerDetail.destinations[0].city
      )
      .then((res) => {
        console.log("calling");
        setAllFlights(res.data.data.flightOffers.flightOffers);
        setupdatedFlights(res.data.data.flightOffers.flightOffers);
        setCollectionId(res.data.data.collectionId);
        console.log(res.data.data.flightOffers.flightOffers);
        setSortingType("");
        let arr = [];
        res.data.data.flightOffers.flightOffers.forEach((element) => {
          arr.push(element.summary.carrierName);
        });

        arrayForHoldingPosts = res.data.data.flightOffers.flightOffers.slice(
          0,
          flightsPerPage
        );
        setPostsToShow(arrayForHoldingPosts);

        console.log(res.data.data.flightOffers.flightOffers);
        arr = [];
        res.data.data.flightOffers.flightOffers.forEach((element) => {
          arr.push(element.summary.carrierName);
        });

        setFlightCarriers(Array.from(new Set(arr)));

        if (allFlights != null) {
          console.log("allflight", allFlights);
          console.log("sorted flight", updatedFlights);
        } else {
          console.log("loading");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      console.log("closing");
      setAllFlights([]);
      setupdatedFlights([]);
      setSortingType("");
      setPrice([1, 3000]);
      setCarrierFilters([]);
    };
  }, [addItemopen]);

  useEffect(() => {
    console.log("filteradd");
  }, [addedFilter]);

  useEffect(() => {
    console.log("filteradd");
  }, [addedFilter]);

  useEffect(() => {
    console.log("called");
    applyCarrierFilter();

    if (sortingType != "") {
      changeSorting(sortingType);
    }
  }, [carrierFilters, sortingType]);

  const loopWithSlice = (start, end) => {
    const slicedPosts = updatedFlights.slice(start, end);
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
  };

  const handleShowMorePosts = () => {
    loopWithSlice(0, next + flightsPerPage);
    setNext(next + flightsPerPage);
  };

  const Back = () => {
    props.page("home");
  };

  const handlePriceChange = (event, newValue) => {
    console.log(newValue);
    setPrice(newValue);
    setupdatedFlights(
      allFlights.filter(
        (flight) =>
          flight.summary.grandTotalPrice >= newValue[0] &&
          flight.summary.grandTotalPrice <= newValue[1]
      )
    );
    changeSorting(sortingType);
  };

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
  };

  const changeSorting = (type) => {
    if (type == "HTL") {
      setSortingType("HTL");
      setupdatedFlights(
        updatedFlights.sort(
          (a, b) => b.summary.grandTotalPrice - a.summary.grandTotalPrice
        )
      );
    } else if (type == "LTH") {
      setSortingType("LTH");
      setupdatedFlights(
        updatedFlights.sort(
          (a, b) => a.summary.grandTotalPrice - b.summary.grandTotalPrice
        )
      );
    }
  };

  function checkflight(flight) {
    if (
      carrierFilters.includes(flight.summary.carrierName.toString()) == true
    ) {
      return true;
    } else {
      return false;
    }
  }

  const applyCarrierFilter = () => {
    if (carrierFilters.length > 0) {
      console.log(carrierFilters);
      setupdatedFlights(allFlights.filter(checkflight));
    } else {
      console.log("no carrier selected");
      setupdatedFlights(allFlights);
    }
    console.log(updatedFlights);
  };

  const addcarrierFilter = (value) => {
    let arr = [...carrierFilters];
    if (!arr.includes(value.carrier)) {
      arr.push(value.carrier);
    } else {
      const index = arr.indexOf(value.carrier);
      if (index > -1) {
        // only splice array when item is found
        arr.splice(index, 1); // 2nd parameter means remove one item only
        console.log(arr);
      }
    }

    console.log("all carrier", arr);
    setCarrierFilters(arr);
  };

  const handleMoreFlights = () => {
    setNext(next + flightsPerPage);
  };

  return (
    <>
      <Grid container className="cardHeader swapAccomodation">
        <Grid item lg={12} xs={12} className="cardTop">
          <div className="headingBack">
  
  <Typography variant='h5'>Swap Flight</Typography>
  </div>
  <IconButton aria-label="delete" size='large' onClick={handleaddItemClick}>
<CloseIcon/>
</IconButton>
            </Grid>
    

            <Grid container>
              <Grid item lg={12} xs={12} className="filters">
                <Stack direction={"row"} className="sort">
                  <Typography variant="body1" sx={{ color: "#3546AB" }}>
                    Sort:
                  </Typography>
                  <Select
                    sx={{ m: 1, minWidth: 150 }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="" selected>
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10} onClick={() => changeSorting("HTL")}>
                      High to Low (Price)
                    </MenuItem>
                    <MenuItem value={20} onClick={() => changeSorting("LTH")}>
                      Low to High (Price)
                    </MenuItem>
                  </Select>
                </Stack>
              </Grid>
            </Grid>

            <Grid container sx={{ mt: 0 }}>
              <Grid item lg={3} xs={12} className="categorySection">
                <Typography variant="body1" sx={{ fontWeight: 700, mb: 1 }}>
                  Price Range
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  $ {price[0]} - $ {price[1]} +
                </Typography>
                <Slider
                  value={price}
                  onChange={handlePriceChange}
                  valueLabelDisplay="auto"
                  max={3000}
                />

                <Typography variant="body1" sx={{ fontWeight: 700, mt: 2 }}>
                  Carriers
                </Typography>

                <Stack>
                  {allFlights ? (
                    flightCarriers.map((carrier) => (
                      <FormControlLabel
                        control={<Checkbox />}
                        onChange={() => addcarrierFilter({ carrier })}
                        label={carrier}
                      />
                    ))
                  ) : (
                    <>
                      <Stack sx={{ width: "95%" }}>
                        <Stack direction={"row"} sx={{ width: "95%" }}>
                          <Skeleton
                            variant="rectangular"
                            width={20}
                            height={20}
                          />
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1.5rem", width: "90%", ml: 1 }}
                          />
                        </Stack>
                        <Stack direction={"row"} sx={{ width: "95%" }}>
                          <Skeleton
                            variant="rectangular"
                            width={20}
                            height={20}
                          />
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1.5rem", width: "90%", ml: 1 }}
                          />
                        </Stack>
                        <Stack direction={"row"} sx={{ width: "95%" }}>
                          <Skeleton
                            variant="rectangular"
                            width={20}
                            height={20}
                          />
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1.5rem", width: "90%", ml: 1 }}
                          />
                        </Stack>
                      </Stack>
                    </>
                  )}
                </Stack>
              </Grid>
              <Grid item lg={9} xs={12} spacing={3}>
                <Grid container className="swapFlightCards">
                  {allFlights!=null && allFlights.length>0 ?
                   <>
                  
                  
                    {allFlights.map((flight) => (
                      <Flightcard
                        purpose="swap"
                        id={flight.id}
                        name={flight.summary.carrierName}
                        flightNumber={
                          flight.details.segments[0].carrierCode +
                          flight.details.segments[0].number
                        }
                        takeOffTime={flight.summary.arrivaleDate
                          .substring(11, flight.summary.arrivaleDate.length)
                          .substring(0, 5)}
                        landingTime={flight.summary.departureDate
                          .substring(11, flight.summary.departureDate.length)
                          .substring(0, 5)}
                        flightDuration="23h20m"
                        class={flight.summary.cabin}
                        price={flight.summary.grandTotalPrice}
                        departure={flight.summary.departureCity}
                        arrival={flight.summary.arrivalCity}
                        numberOfStops={flight.summary.numberOfStops - 2}
                        collectionId={collectionId}
                      />
                    ))}
                     <Grid lg={6} xs={12}>
                    <Typography sx={{ml:1}}>
                      Showing 1-{next} of {updatedFlights?.length} flights
                    </Typography>
                  </Grid>
                    
                  {next < updatedFlights?.length && (
                    <Grid lg={12} xs={12} sx={{display:"flex",justifyContent:"center",mt:2,mb:2}}>
                      <Button
                        className="loadMoreBtn"
                        onClick={handleShowMorePosts}
                      >
                        Load more
                      </Button>
                    </Grid>
                  )}
                    </>
                     : 
                      <Stack sx={{ width: "100%" }}>
                        <Skeleton
                          variant="rounded"
                          width={"95%"}
                          height={150}
                          sx={{ mt: 4 }}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"95%"}
                          height={150}
                          sx={{ mt: 4 }}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"95%"}
                          height={150}
                          sx={{ mt: 4 }}
                        />
                      </Stack>
                  }
                  
                </Grid>
              </Grid>
            </Grid>
      </Grid>
    </>
  );
}
