import { React, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Grid } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import FlightIcon from '@mui/icons-material/Flight';
import Button from '@mui/material/Button';
import "./scss/Navdrawer.css";
import SecurityIcon from '@mui/icons-material/Security';
import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import LuggageIcon from '@mui/icons-material/Luggage';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LanguageIcon from '@mui/icons-material/Language';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import HotelIcon from '@mui/icons-material/Hotel';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';

const drawerWidth = "100%";

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function ProfileDrawer(props) {
  const { currentPage } = props;
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState('Language');
  const [currency, setCurrency] = useState('AUD');
  const handleLanguage = (event) => {
    setLanguage(event.target.value);
  };
  const handleCurrency = (event) => {
    setCurrency(event.target.value);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  return (
    <Box sx={{ display: 'flex' }}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{ mr: 2, ...(open && { display: 'none' }) }}
      >
        <MenuIcon sx={currentPage === "landing-page" ? {color:'#333333'} : {}} />
      </IconButton>

      <Drawer
        sx={{
          width: "auto",
          backgroundColor:'red',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: {xs:'100vw', md:'50vw'},
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader sx={{ padding: 3, paddingBottom: 1 }}>
          <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
            <Grid item xs={8}>
              <img src='./assets/images/logofiled.svg' width="100%"/>
            </Grid>
            {/* <Grid item xs={3}>
              <Avatar alt="Remy Sharp" src="./assets/images/userimg.png" sx={{ width: 46, height: 46 }} />

            </Grid>
            <Grid item xs={5}>
              <Typography sx={{ fontSize: 17, fontWeight: 700, color: "#293854" }}>Wade Warren</Typography>

              <Link sx={{ fontSize: 14 }}> Wade12345@gmail.com</Link>
            </Grid> */}
            <Grid item xs={4} sx={{ textAlign: "right" }}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ClearIcon /> : <ClearIcon />}
              </IconButton>
            </Grid>
          </Grid>

        </DrawerHeader>
        <Grid container >
          <Grid item xs={12} padding="22px" className="menu-items">
            <Button href='/explore' startIcon={<CardTravelIcon />} className="item">Explore</Button>
            <Button href='/about-us' startIcon={<AirplanemodeActiveIcon />} className="item">About us</Button>

            {/* <Divider sx={{ mt: 1, mb: 1 }} />

            <Typography className="sub-heading">Settings</Typography>

            <TextField

              select
              value={currency}
              sx={{ display: "block" }}
              onChange={handleCurrency}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon sx={{ mr: 1 }} />
                  </InputAdornment>
                ),
              }}

            >
              <MenuItem value={"AUD"}>
                AUD
              </MenuItem>
              <MenuItem value={"USD"}>
                USD
              </MenuItem>
              <MenuItem value={"PKR"}>
                PKR
              </MenuItem>

            </TextField>

            <TextField

              select
              value={language}

              onChange={handleLanguage}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LanguageIcon sx={{ mr: 1 }} />
                  </InputAdornment>
                ),
              }}

            >
              <MenuItem value={"Language"}>
                Language
              </MenuItem>
              <MenuItem value={"BRT"}>
                Britsh
              </MenuItem>
              <MenuItem value={"ENG"}>
                English
              </MenuItem>


            </TextField>

            <Button startIcon={<SecurityIcon />} className="item">Privacy and security</Button>

            <Divider sx={{ mt: 1, mb: 1 }} />
            <Button startIcon={<LogoutIcon />} className="item">Logout</Button> */}

          </Grid>
        </Grid>
      </Drawer>

    </Box>
  );
}