import React, { useEffect } from "react";
import Navbar from "../components/ui-components/Navbar";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Footer from "../components/ui-components/Footer";
export default function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Grid
        container
        sx={{
          backgroundImage: `url(/assets/images/litineraryback.png)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Navbar currentPage="contact-us" />
      </Grid>
      <Box
        padding={{ xs: "10vh 10vw", md: "10vh 20vw", lg: "20vh 10vw" }}
        bgcolor="#F3F6FC"
      >
        <Grid container bgcolor='#F3F6FC'>
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            display="flex"
            gap={2}
            flexDirection="column"
            marginBottom={{ xs: "5vh", md: "5vh" }}
          >
            <Typography color="#14183E" fontSize="45px" fontWeight="700">
              Get in touch with us
            </Typography>
            <Typography
              display="flex"
              alignItems="center"
              fontSize="16px"
              fontFamily='inter'
              fontWeight='600'
              gap={1}
            >
              <EmailIcon sx={{color:"#14183E"}} fontSize="large" /> hello@catchuthere.com
            </Typography>
            <Typography
              display="flex"
              alignItems="center"
              fontSize="16px"
              fontFamily="inter"
              fontWeight='600'
              gap={1}
            >
              <LocationOnIcon sx={{color:'#14183E'}}fontSize="large" /> 16 of 21 Ijong Street,
              Braddon, ACT 2612
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={12}
            lg={6}
            bgcolor="white"
            borderRadius="36px"
            padding="50px 40px"
          >
            <Grid item xs={12} md={12} lg={6} margin="1vh 0">
              <TextField
                sx={{
                  borderRadius: "10px",
                  border: "1px solid #f7f7f7",
                  boxShadow: "0 1px 3px #e9e9e9",
                }}
                label="First Name"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6} margin="1vh 0">
              <TextField
                sx={{
                  borderRadius: "10px",
                  border: "1px solid #f7f7f7",
                  boxShadow: "0 1px 3px #e9e9e9",
                }}
                label="Last Name"
              />
            </Grid>
            <Grid item xs={12} margin="1vh 0">
              <TextField
                sx={{
                  width: "100%",
                  borderRadius: "10px",
                  border: "1px solid #f7f7f7",
                  boxShadow: "0 1px 3px #e9e9e9",
                }}
                label="Email"
              />
            </Grid>
            <Grid item xs={12} margin="1vh 0">
              <TextField
                sx={{
                  width: "100%",
                  borderRadius: "10px",
                  border: "1px solid #f7f7f7",
                  boxShadow: "0 1px 3px #e9e9e9",
                }}
                label="Message"
                multiline
                rows={4}
              />
            </Grid>
            <Grid item margin="1vh 0">
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  borderRadius: "10px",
                  padding: "10px 20px",
                }}
              >
                Send Message
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Footer currentPage = "contact-us"/>
    </>
  );
}
